import { useNavigate, useParams } from 'react-router-dom';
import PageHead from '@/components/PageHead';
import prepareTranslate from '@/helpers/dictionary';
import {
  Button,
  Grid,
  Select,
  TextInput,
  NumberInput,
  ActionIcon,
  Text,
  Card,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { MonthPickerInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import Comments from '@/components/Comments';
import Files from '@/components/Files';
import agentMutations from '@/api/mutitations/agent';
import uploadMutations from '@/api/mutitations/upload';
import {
  AGENT_STATUS,
  AGENT_TYPE,
  EMPLOYEE_TYPE,
  FAMILY_STATUS,
  GENDER,
} from '@/helpers/enums';
import routes from '@/routes';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import PageTemplate from '@/components/PageTemplate';
import Section from '@/components/Section';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import { useEffect, useState } from 'react';
import validations from '@/helpers/validation';
import agentQuery from '@/api/queries/agent';
import uploadQuery from '@/api/queries/upload';
import { routeWithParams } from '@/helpers/route';
import { formatDateToString } from '@/helpers/string';

// TODO: remove eslint disable line

const Agentedit = () => {
  const [agent, setAgent] = useState({
    personalDetails: {},
  });
  const [files, setFiles] = useState([]);
  const [filesOrigin, setFilesOrigin] = useState([]);

  // TO DO REMOVE THAT
  const today = new Date();
  const twentyYearsAgo = new Date(
    today.getFullYear() - 20,
    today.getMonth(),
    today.getDate(),
  );

  const t = prepareTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, { open, close }] = useDisclosure(false);

  const { data: agentData } = agentQuery.useGetAgentById({ id });
  const { data: uploadsData } = uploadQuery.useGetFilesById({ id });

  const { useUpdateAgent } = agentMutations;
  const { mutateAsync: updateAgentMutate } = useUpdateAgent();
  const { useUpload } = uploadMutations;
  const { mutateAsync: uploadMutate } = useUpload();

  const { data: companies } = companyQuery.useGetAll();
  const { data: products } = productQuery.useGetAll();

  const companiesOptions = companies?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const productsOptions = products?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  const handleReject = () => {
    open();
    notifications.show({
      title: t('pages.agentedit.notification.files.title'),
      message: t('pages.agentedit.notification.files.message'),
    });
    close();
  };

  const handleDelete = (fileName) => {
    const fillterArray = files.filter((item) => (
      item.name !== fileName && item.fileName !== fileName
    ));
    setFiles(fillterArray);
  };

  const form = useForm({
    initialValues: {
      personalDetails: {
        childrens: [],
      },
      agreements: [
        {
          agentNumber: '',
          from: '',
          till: '',
          agreementCompany: '',
          agreementBranch: '',
          agreementCommisionscope: '',
          agreementCommisionpaidoff: '',
        },
      ],
      entityNumbers: [{ entityNumber: '' }],
    },
    validate: {
      status: (value) => validations.required(value),
      agentType: (value) => validations.required(value),
      employeeType: (value) => validations.required(value),
      personalDetails: {
        firstName: (value) => validations.required(value),
        lastName: (value) => validations.required(value),
        uid: (value) => validations.required(value) || validations.uid(value),
        gender: (value) => validations.required(value) || validations.required(value),
        dob: (value) => validations.required(value),
        email: (value) => value && validations.email(value),
        phone: (value) => value && validations.phone(value),
        extraPhone: (value) => value && validations.phone(value),
        homeZipCode: (value) => value && validations.number(Number(value)),
        homePhone: (value) => value && validations.phone(value),
        homePostalCode: (value) => value && validations.number(Number(value)),
        officeZipCode: (value) => value && validations.number(Number(value)),
        officePostalCode: (value) => value && validations.number(Number(value)),
        childrens: {
          childrenName: (value) => validations.required(value),
        },
      },
      agreements: {
        agentNumber: (value) => validations.required(value),
        from: (value) => validations.required(value),
        companyId: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
        // agencyFeeMonthlyDeposit: (value) => validations.required(value),
        // agencyFeeMonthlyAccumulation: (value) => validations.required(value),
        // agentFeeMonthlyAccumulation: (value) => validations.required(value),
        // agentFeeMonthlyDeposit: (value) => validations.required(value),
        // agentFeeAnnualAccumulation: (value) => validations.required(value),
        // agentFeeAnnualDeposit: (value) => validations.required(value),
        // agencyFeeAnnualDeposit: (value) => validations.required(value),
        // agencyFeeAnnualAccumulation: (value) => validations.required(value),
      },
      entityNumbers: {
        clientName: (value) => validations.required(value),
        entityNumber: (value) => validations.required(value),
      },
    },
  });

  useEffect(() => {
    const fetchaAgentData = async () => {
      try {
        if (!agentData) {
          navigate(routes.agents.path);
        } else {
          // TO DO

          setFiles(uploadsData);
          setFilesOrigin(uploadsData);
          setAgent(agentData);
          const sanitizedData = agentData.agreements.map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.from = item.from ? new Date(item.from) : null;
            // eslint-disable-next-line no-param-reassign
            item.till = item.till ? new Date(item.till) : null;

            return item;
          });
          form.setValues({
            id,
            personalDetails: agentData.personalDetails,
            agreements: sanitizedData,
            status: agentData.status,
            entityNumbers: agentData.entityNumbers,
            agentType: agentData.agentType,
            employeeType: agentData.employeeType,
          });
        }
      } catch (error) {
        navigate(routes.agents.path);
      }
    };
    if (agentData) {
      fetchaAgentData();
    } else {
      // navigate(routes.agents.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData, navigate, uploadsData]);

  const onError = () => {
    open();
    notifications.show({
      title: t('pages.agentedit.notification.validtion.title'),
      message: t('pages.agentedit.notification.validtion.message'),
    });
    close();
  };
  // TODO
  // eslint-disable-next-line consistent-return
  const handleAgentUpdateClick = async (agentValues) => {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const userCookie = cookies.find((cookie) => cookie.startsWith('userInfo=')) || '';
    const userCookieValue = { entity: 'agent', entityId: id, ...JSON.parse(decodeURIComponent(userCookie.replace('userInfo=', ''))) };

    try {
      open();
      if (files.length > 0 && filesOrigin.length !== files.length) {
        try {
          const formData = new FormData();

          const fillterdFiles = files.filter((file) => !file._id);

          fillterdFiles.forEach((file) => {
            formData.append('files', file, file?.name);
          });

          formData.append('user', JSON.stringify(userCookieValue));
          await uploadMutate(formData);
        } catch (error) {
          notifications.show({
            title: t('pages.agentedit.notification.failure.title'),
            message: t('pages.agentedit.notification.failure.message'),
          });
          close();
        }
      }
      const formData = agentValues;
      const { agreements, entityNumbers } = formData;

      delete formData.personalDetails.createdAt;
      delete formData.personalDetails.updatedAt;
      delete formData.personalDetails.homeFullAddress;
      delete formData.personalDetails.officeFullAddress;
      delete formData.personalDetails.fullName;

      // // Remove the createdAt field from each item
      const sanitizedData = agreements.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.createdAt;
        // eslint-disable-next-line no-param-reassign
        delete item.updatedAt;
        // eslint-disable-next-line no-param-reassign
        delete item.datesArray;

        return item;
      });
      // // Remove the createdAt field from each item
      const entityNumbersSanitizedData = entityNumbers.map((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item.createdAt;
        // eslint-disable-next-line no-param-reassign
        delete item.updatedAt;

        return item;
      });

      formData.agreements = sanitizedData;
      formData.entityNumbers = entityNumbersSanitizedData;
      const newAgent = await updateAgentMutate(formData);
      if (!newAgent) {
        notifications.show({
          title: t('pages.agentedit.notification.failure.title'),
          message: t('pages.agentedit.notification.failure.message'),
        });
        close();
        return null;
      }

      notifications.show({
        title: t('pages.agentedit.notification.success.title'),
        message: t('pages.agentedit.notification.success.message'),
      });
      navigate(routeWithParams(routes.agentView.path, { id }));
      close();
    } catch (error) {
      close();
    }
  };

  const handleAgentCancelClick = () => {
    navigate(routes.agents.path);
  };

  // TODO: move to agentedit functions
  const menuGroups = [
    {
      links: [
        {
          id: 'details',
          title: t('pages.agentedit.menu.details'),
        },
        {
          id: 'contracts',
          title: t('pages.agentedit.menu.contracts'),
        },
        {
          id: 'documents',
          title: t('pages.agentedit.menu.documents'),
        },
        {
          id: 'clients',
          title: t('pages.agentedit.menu.clients'),
        },
      ],
    },
  ];

  // TODO: move to agentedit functions
  const tabs = [
    {
      title: t('pages.agentedit.side.comments'),
      content: <Comments type='agent' id={id} showForm />,
      isDefault: true,
    },
  ];

  // TODO: move to agentedit functions
  const actions = [
    <Button
      key='primary'
      loading={loading}
      leftSection={<IconCheck />}
      onClick={form.onSubmit(handleAgentUpdateClick, onError)}
    >
      {t('global.update')}
    </Button>,
    <Button key='cancel' variant='outline' onClick={handleAgentCancelClick}>
      {t('global.cancel')}
    </Button>,
  ];

  return (
    <>
      <PageHead
        title={t('pages.agentedit.title', [
          agent?.personalDetails?.fullName || '',
        ])}
        subtitle={t('pages.agentedit.subtitle', [formatDateToString(agent.updatedAt)])}
      />

      <PageTemplate
        menuGroups={menuGroups}
        tabs={tabs}
        tabsTitle={t('pages.agentedit.side.tabs')}
        actions={actions}
        actionsTitle={t('pages.agentedit.side.actions')}
      >
        <form>
          <PageTemplate.Panel id='details'>
            <Section title={t('pages.agentedit.headers.private')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentedit.form.status')}
                    data={AGENT_STATUS}
                    required
                    key={form.key('status')}
                    {...form.getInputProps('status')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentedit.form.agentType')}
                    data={AGENT_TYPE}
                    required
                    key={form.key('agentType')}
                    {...form.getInputProps('agentType')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentedit.form.employeeType')}
                    data={EMPLOYEE_TYPE}
                    required
                    key={form.key('employeeType')}
                    {...form.getInputProps('employeeType')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.uid')}
                    required
                    key={form.key('uid')}
                    {...form.getInputProps('personalDetails.uid')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.firstname')}
                    required
                    key={form.key('firstName')}
                    {...form.getInputProps('personalDetails.firstName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.lastname')}
                    required
                    key={form.key('lastName')}
                    {...form.getInputProps('personalDetails.lastName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    maxDate={twentyYearsAgo}
                    autoComplete='off'
                    label={t('pages.agentedit.form.dob')}
                    key={form.key('personalDetails.dob')}
                    {...form.getInputProps('personalDetails.dob')}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue('personalDetails.dob', v);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentedit.form.gender')}
                    data={GENDER}
                    key={form.key('gender')}
                    required
                    {...form.getInputProps('personalDetails.gender')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentedit.headers.contact')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.email')}
                    key={form.key('email')}
                    {...form.getInputProps('personalDetails.email')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.cellphone')}
                    key={form.key('phone')}
                    {...form.getInputProps('personalDetails.phone')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.extracellphone')}
                    maxLength={10}
                    key={form.key('personalDetails.extraPhone')}
                    {...form.getInputProps('personalDetails.extraPhone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentedit.headers.home')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.city')}
                    key={form.key('homeCity')}
                    {...form.getInputProps('personalDetails.homeCity')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.street')}
                    key={form.key('street')}
                    {...form.getInputProps('personalDetails.homeStreet')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.houseNumber')}
                    key={form.key('houseNumber')}
                    {...form.getInputProps('personalDetails.homeHouseNumber')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.zipCode')}
                    key={form.key('homeZipCode')}
                    {...form.getInputProps('personalDetails.homeZipCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.postalCode')}
                    key={form.key('postalCode')}
                    {...form.getInputProps('personalDetails.postalCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.phone')}
                    key={form.key('homePhone')}
                    {...form.getInputProps('personalDetails.homePhone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentedit.headers.office')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.city')}
                    key={form.key('officeCity')}
                    {...form.getInputProps('personalDetails.officeCity')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.street')}
                    key={form.key('officeStreet')}
                    {...form.getInputProps('personalDetails.officeStreet')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.houseNumber')}
                    key={form.key('officeHouseNumber')}
                    {...form.getInputProps('personalDetails.officeHouseNumber')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.zipCode')}
                    key={form.key('officeZipCode')}
                    {...form.getInputProps('personalDetails.officeZipCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.postalCode')}
                    key={form.key('officePostalCode')}
                    {...form.getInputProps('personalDetails.officePostalCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.phone')}
                    key={form.key('officePhone')}
                    {...form.getInputProps('personalDetails.officePhone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentedit.headers.family')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentedit.form.familystatus')}
                    data={FAMILY_STATUS}
                    key={form.key('familyStatus')}
                    {...form.getInputProps('personalDetails.familyStatus')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.spouse')}
                    key={form.key('spouse')}
                    {...form.getInputProps('personalDetails.spouse')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  {form.getValues().personalDetails.childrens.map((item, index) => (
                    <Grid align='center' key={index + 1}>
                      <Grid.Col span={{ base: 12, md: 3 }}>
                        <TextInput
                          autoComplete='off'
                          maxLength={25}
                          required
                          label={t('pages.agentcreate.form.fullName')}
                          key={form.key(`personalDetails.childrens.${index}.childrenName`)}
                          {...form.getInputProps(`personalDetails.childrens.${index}.childrenName`)}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 3 }}>
                        <TextInput
                          clearable
                          autoComplete='off'
                          maxLength={10}
                          label={t('pages.agentcreate.form.dob')}
                          key={form.key(`personalDetails.childrens.${index}.childrenDob`)}
                          {...form.getInputProps(`personalDetails.childrens.${index}.childrenDob`)}
                          onChange={(e) => {
                            const v = e.target.value
                              .replace(/^(\d\d)(\d)$/g, '$1/$2')
                              .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                              .replace(/[^\d/]/g, '');
                            form.setFieldValue(`personalDetails.childrens.${index}.childrenDob`, v);
                          }}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 1 }}>
                        <ActionIcon
                          mt={25}
                          onClick={() => {
                            form.removeListItem('personalDetails.childrens', index);
                          }}
                          color={'red'}
                          variant={'transparent'}
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Grid.Col>
                    </Grid>
                  ))}
                  <Button
                    mt={15}
                    mb={15}
                    onClick={() => {
                      form.insertListItem('personalDetails.childrens', {
                        childrenName: '',
                        childrenDob: null,
                      });
                    }}
                  >
                    {t('pages.agentcreate.form.addChildren')}
                  </Button>
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentedit.headers.dates')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    clearable
                    autoComplete='off'
                    maxLength={10}
                    label={t('pages.agentedit.form.policyend')}
                    key={form.key('personalDetails.policyEndDate')}
                    {...form.getInputProps('personalDetails.policyEndDate')}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue('personalDetails.policyEndDate', v);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    clearable
                    autoComplete='off'
                    maxLength={10}
                    label={t('pages.agentedit.form.tax')}
                    key={form.key('personalDetails.taxDeductionsDate')}
                    {...form.getInputProps('personalDetails.taxDeductionsDate')}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue('personalDetails.taxDeductionsDate', v);
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Section>
          </PageTemplate.Panel>
        </form>

        <PageTemplate.Panel id='contracts'>
          <Section title={t('pages.agentedit.headers.contracts')}>
            {form.getValues().agreements.map((item, index) => (
              <Card withBorder mt={10} shadow='sm' radius='md' key={index + 1}>
                <Card.Section mb={10} withBorder inheritPadding py='xs'>
                  <Grid align='center'>
                    <Grid.Col span={{ base: 12, md: 11 }}>
                      <Text fw={700}>{`פרטי הסכם ${item.id || ''}:`}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 1 }}>
                      <ActionIcon
                        mt={15}
                        mb={15}
                        onClick={() => {
                          form.removeListItem('agreements', index);
                        }}
                        color={'red'}
                        variant={'transparent'}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                </Card.Section>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <Select
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.company')}
                      data={companiesOptions}
                      required
                      key={form.key(`agreements.${index}.companyId`)}
                      maxLength={20}
                      {...form.getInputProps(`agreements.${index}.companyId`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <TextInput
                      autoComplete='off'
                      required
                      label={t('pages.agentedit.form.agreement.agent')}
                      key={form.key(`agreements.${index}.agentNumber`)}
                      maxLength={10}
                      {...form.getInputProps(`agreements.${index}.agentNumber`)}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 6 }}>
                    <MonthPickerInput
                    required
                      maxDate={new Date()}
                      label={t('pages.agentedit.form.agreement.from')}
                      key={form.key(`agreements.${index}.from`)}
                      {...form.getInputProps(`agreements.${index}.from`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 6 }}>
                    <MonthPickerInput
                      label={t('pages.agentedit.form.agreement.till')}
                      key={form.key(`agreements.${index}.till`)}
                      {...form.getInputProps(`agreements.${index}.till`)}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <Select
                      autoComplete='off'
                      required
                      label={t('pages.agentedit.form.agreement.branch')}
                      data={productsOptions}
                      key={form.key(`agreements.${index}.branchId`)}
                      maxLength={20}
                      {...form.getInputProps(`agreements.${index}.branchId`)}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agencyFeeMonthlyDeposit')}
                      key={form.key(`agreements.${index}.agencyFeeMonthlyDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeMonthlyDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agentFeeMonthlyAccumulation')}
                      key={form.key(`agreements.${index}.agentFeeMonthlyAccumulation`)}
                      maxLength={5}
                      min={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeMonthlyAccumulation`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agencyFeeMonthlyAccumulation')}
                      key={form.key(`agreements.${index}.agencyFeeMonthlyAccumulation`)}
                      maxLength={5}
                      min={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeMonthlyAccumulation`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agentFeeMonthlyDeposit')}
                      key={form.key(`agreements.${index}.agentFeeMonthlyDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeMonthlyDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agencyFeeAnnualDeposit')}
                      key={form.key(`agreements.${index}.agencyFeeAnnualDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeAnnualDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agentFeeAnnualDeposit')}
                      key={form.key(`agreements.${index}.agentFeeAnnualDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeAnnualDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agencyFeeAnnualAccumulation')}
                      key={form.key(`agreements.${index}.agencyFeeAnnualAccumulation`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeAnnualAccumulation`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentedit.form.agreement.commision.agentFeeAnnualAccumulation')}
                      key={form.key(`agreements.${index}.agentFeeAnnualAccumulation`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeAnnualAccumulation`)}
                    />
                  </Grid.Col>
                </Grid>
              </Card>
            ))}
            <Button
              mt={15}
              mb={15}
              onClick={() => {
                form.insertListItem('agreements', {
                  agentNumber: '',
                  from: null,
                  till: null,
                  companyId: '',
                  branchId: '',
                  agencyFeeMonthlyDeposit: '',
                  agencyFeeMonthlyAccumulation: '',
                  agentFeeMonthlyAccumulation: '',
                  agentFeeMonthlyDeposit: '',
                  agentFeeAnnualAccumulation: '',
                  agentFeeAnnualDeposit: '',
                  agencyFeeAnnualDeposit: '',
                  agencyFeeAnnualAccumulation: '',
                });
              }}
            >
              {t('pages.agentedit.form.agreement.addAgreement')}
            </Button>
          </Section>
        </PageTemplate.Panel>

        <PageTemplate.Panel id='documents'>
          <Section title={t('pages.agentedit.headers.documents')}>
            <Files
              setFiles={setFiles}
              files={files}
              showActions={false}
              handleReject={handleReject}
              handleDelete={handleDelete}
            />
          </Section>
        </PageTemplate.Panel>

        <PageTemplate.Panel id='clients'>
          <Section title={t('pages.agentedit.headers.clients')}>
            {form.getValues().entityNumbers.map((item, index) => (
              <Grid align='center' key={index + 1}>
                <Grid.Col span={{ base: 2, md: 6, lg: 3 }}>
                  <TextInput
                    autoComplete='off'
                    maxLength={25}
                    required
                    label={t('pages.agentedit.form.entityNumbers.hp')}
                    key={form.key(`entityNumbers.${index}.hp`)}
                    {...form.getInputProps(`entityNumbers.${index}.hp`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 2, md: 6, lg: 3 }}>
                  <TextInput
                    autoComplete='off'
                    maxLength={25}
                    required
                    label={t('pages.agentedit.form.entityNumbers.clientName')}
                    key={form.key(`entityNumbers.${index}.clientName`)}
                    {...form.getInputProps(`entityNumbers.${index}.clientName`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 2, md: 6, lg: 3 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.entityNumbers.entityNumber')}
                    key={form.key(`entityNumbers.${index}.entityNumber`)}
                    maxLength={7}
                    required
                    {...form.getInputProps(`entityNumbers.${index}.entityNumber`)}
                  />
                </Grid.Col>
                <Grid.Col flex span={{ base: 1, md: 1 }}>
                  <ActionIcon
                    mt={25}
                    onClick={() => {
                      form.removeListItem('entityNumbers', index);
                    }}
                    color={'red'}
                    variant={'transparent'}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            ))}
            <Button
              mt={15}
              mb={15}
              onClick={() => {
                form.insertListItem('entityNumbers', {
                  entityNumber: '',
                  clientName: '',
                });
              }}
            >
              {t('pages.agentedit.form.entityNumbers.addNewEntityNumber')}
            </Button>
          </Section>
        </PageTemplate.Panel>
      </PageTemplate>
    </>
  );
};

export default Agentedit;
