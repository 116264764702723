import prepareTranslate from '@/helpers/dictionary';
import { Card, Text } from '@mantine/core';
import { IconMoodConfuzedFilled } from '@tabler/icons-react';
import styles from './style.module.css';

const NoData = () => {
  const t = prepareTranslate();

  return (
    <Card shadow="sm" withBorder radius="md" padding="xl" className={styles.empty}>
      <IconMoodConfuzedFilled className={styles.avatar} />
      <Text fw="bold">{t('components.chart.noRows.title')}</Text>
      <Text size="xs" c="dimmed">{t('components.chart.noRows.subtitle')}</Text>
    </Card>
  );
};

export default NoData;
