import { useMutation } from '@tanstack/react-query';
import { createComment } from '../requests/user';

const KEY = 'comment';

const useCreateComment = () => useMutation({
  mutationKey: [KEY, 'create'],
  mutationFn: (data) => createComment(data),
});
const commentMutations = {
  useCreateComment,
};

export default commentMutations;
