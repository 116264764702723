import PropTypes from 'prop-types';
import { notifications } from '@mantine/notifications';
import productMutation from '@/api/mutitations/product';
import companyMutation from '@/api/mutitations/company';
import ProcessNotification from '@/components/processNotification';
import { useEffect } from 'react';
import prepareTranslate from '@/helpers/dictionary';
import { SSE_TYPES } from '../../helpers/enums';

const processSSEData = async (obj) => {
  const {
    t,
    e,
    companyMutate,
    productMutate,
  } = obj;

  let title;
  let message;
  let color = 'blue';
  let autoClose = true;

  const { success, data, error } = JSON.parse(e.data);

  if (!success) {
    color = 'red';
    const { code } = error;
    const {
      identifiers,
      collection,
      type,
      rule: ruleFile,
    } = data;

    if (code === 501 && collection === 'agents' && type === SSE_TYPES.process) {
      const unAttributedIdentifiers = identifiers;
      const identifiersData = [];
      try {
        for (let i = 0; i < unAttributedIdentifiers.length; i += 1) {
          const {
            identifierNumber,
            year,
            month,
            additionalData: { companyId, productId },
          } = unAttributedIdentifiers[i];

          // eslint-disable-next-line no-await-in-loop
          const { hebrew: companyHebrew } = await companyMutate({ id: companyId });
          // eslint-disable-next-line no-await-in-loop
          const { hebrew: productHebrew } = await productMutate({ id: productId });

          identifiersData.push({
            company: companyHebrew,
            product: productHebrew,
            identifierNumber,
            year,
            month,
          });
        }
        autoClose = false;
        title = t('components.processNotification.notification.title');
        message = <ProcessNotification identifiers={identifiersData} ruleFile={ruleFile} />;
      } catch {
        color = 'red';
        title = t('global.process.failure.title');
        message = t('global.process.failure.message', [ruleFile]);
      }
    } else {
      color = 'red';
      title = t('global.process.failure.title');
      message = t('global.process.failure.message', [ruleFile]);
    }
  } else {
    const { type, rule: ruleFile } = data;

    if (type === SSE_TYPES.process) {
      title = t('global.process.success.title');
      message = t('global.process.success.message', [ruleFile]);
    }
  }

  notifications.show({
    color,
    title,
    message,
    autoClose,
  });
};

const SSEHandler = (props) => {
  const { event } = props;
  const t = prepareTranslate();

  const { mutateAsync: companyMutate } = companyMutation.useFindById();
  const { mutateAsync: productMutate } = productMutation.useFindById();

  useEffect(() => {
    const processObj = {
      t,
      companyMutate,
      productMutate,
      e: event,
    };

    if (event) {
      processSSEData(processObj);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return null;
};

SSEHandler.propTypes = {
  event: PropTypes.object,
  t: PropTypes.func,
};

export default SSEHandler;
