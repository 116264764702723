import vars from '@/config/vars';

const {
  baseURL,
  timeout,
} = vars.request;

const headers = {
  'Content-Type': 'application/json',
};

const axiosConfig = {
  baseURL,
  timeout,
  headers,
  withCredentials: true,
};

export default axiosConfig;
