import PropTypes from 'prop-types';
import styles from './style.module.css';

const ContentPanel = (props) => {
  const {
    id,
    active,
    children,
  } = props;

  const display = (active === id || !id) ? 'block' : 'none';

  return (
    <div
      data-panel={id}
      className={styles.panel}
      style={{ display }}
    >
      {children}
    </div>
  );
};

ContentPanel.propTypes = {
  id: PropTypes.string,
  active: PropTypes.string,
  children: PropTypes.any,
};

export default ContentPanel;
