import { Text } from '@mantine/core';

/**
 * transform to text
 * @param {string} d
 * @returns {string}
 */
const textTransform = (d = '') => <Text>{String(d)}</Text>;

export default textTransform;
