import axiosRequest from '.';

const upload = async (data) => (
  axiosRequest({
    data,
    url: '/v1/upload',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
  })
);

const getUploadsById = async (data) => (
  axiosRequest({
    url: '/v1/upload/userUploads',
    method: 'POST',
    data,
  })
);

const getAllUploads = async (data) => (
  axiosRequest({
    url: '/v1/upload/getall',
    method: 'POST',
    data,
  })
);

const download = async (data) => (
  axiosRequest({
    url: '/v1/upload/download',
    method: 'POST',
    data,
  })
);

const deleteUpload = async (data) => (
  axiosRequest({
    url: '/v1/upload/delete',
    method: 'POST',
    data,
  })
);

export {
  upload,
  getUploadsById,
  download,
  getAllUploads,
  deleteUpload,
};
