import { formatDateToString } from '@/helpers/string';

/**
 * transform to formatted date text
 * @param {date} d
 * @param {object} params
 * @returns {string}
 */
const dateTransform = (d = '', params = {}) => {
  const {
    withTime = true,
  } = params;

  return formatDateToString(d, withTime);
};

export default dateTransform;
