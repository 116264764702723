import PropTypes from 'prop-types';
import { Title } from '@mantine/core';
import styles from './style.module.css';

const Section = (props) => {
  const {
    title,
    prefix,
    suffix,
    children,
  } = props;

  const prefixElement = (prefix) ? <div className={styles.prefix}>{prefix}</div> : null;
  const suffixElement = (suffix) ? <div className={styles.suffix}>{suffix}</div> : null;

  return (
    <section className={styles.section}>
      {title && <header>{prefixElement}<Title order={4}>{title}</Title>{suffixElement}</header>}
      {children}
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  children: PropTypes.any,
};

export default Section;
