import vars from '@/config/vars';
import worker from '@/mocks/browser';

const enableMocking = async () => {
  if (!vars.mocks) {
    return null;
  }

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
};

export default enableMocking;
