import {
  isString,
  isEmpty,
  isArray,
  isFunction,
} from '@/helpers/is';
import { Badge } from '@mantine/core';

/**
 * transform to badge according to true or false
 * @param {string} d
 * @param {object} params
 * @returns {react}
 */
const badgeTransform = (d, params = {}) => {
  if (!isString(d) || isEmpty(d)) { return ''; }

  const {
    color,
    variant = 'light',
    rules = [], // { byValue: {string}, byFunc: {func}, color: {string} }
  } = params;

  let c = 'gray';
  if (color) {
    c = color;
  } else if (isArray(rules)) {
    rules.forEach((rule) => {
      const {
        byValue,
        byFunc,
        color: ruleColor,
      } = rule;

      if (ruleColor) {
        if (isString(byValue) && !isEmpty(byValue) && byValue === d) {
          c = ruleColor;
        }

        if (isFunction(byFunc) && byFunc(d)) {
          c = ruleColor;
        }
      }
    });
  }

  return <Badge color={c} variant={variant}>{d}</Badge>;
};

export default badgeTransform;
