export default {
  required: 'שדה חובה',
  email: 'יש להזין מייל תקין',
  hebrew: 'יש להזין אותיות בעברית בלבד',
  uid: 'יש להזין תעודת זהות תקינה',
  date: 'יש להזין תאריך תקין',
  number: 'ניתן להזין ספרות בלבד',
  cellphone: 'יש להזין נייד תקין',
  phone: 'יש להזין טלפון תקין',
  autocomplete: 'חובה לבחור פריט מהרשימה',
};
