import PropTypes from 'prop-types';
import { Flex, Paper, Text } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';
import styles from './style.module.css';

const LineChartToolTipComponent = (props) => {
  const {
    label,
    payload,
    precision = 2,
    primaryValue,
  } = props;

  if (!payload) return null;

  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md" dir='rtl'>
      <Text fw={500} mb={5}>
        {label} - {primaryValue}
      </Text>
      {payload.map((item) => {
        const { name, value, color } = item;
        const formattedValue = Number(value).toFixed(precision).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return (
          <Flex className={styles.identifier_key} key={name} align='center' gap='sm' >
            <IconCircleFilled className={styles.icon} color={color} />
            <Text fz="sm">
              {name}: {formattedValue}
            </Text>
          </Flex>
        );
      })}
    </Paper>
  );
};

LineChartToolTipComponent.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.array,
  precision: PropTypes.number,
  primaryValue: PropTypes.string,
};

export default LineChartToolTipComponent;
