import prepareTranslate from '@/helpers/dictionary';
import { isBoolean } from '@/helpers/is';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

/**
 * transform to v or x icons
 * @param {string} d
 * @param {object} params
 * @returns {string}
 */
const booleanTransform = (d = '', params = {}) => {
  const {
    trueLabel,
    falseLabel,
  } = params;

  const t = prepareTranslate();

  if (!isBoolean(d)) {
    return '';
  }

  const withLabel = !!(trueLabel || falseLabel);

  const label = (d) ? trueLabel || t('global.yes') : falseLabel || t('global.no');

  const content = (d)
    ? <ThemeIcon variant="outline" color="blue" size="xs"><IconCheck stroke={1.5} /></ThemeIcon>
    : <ThemeIcon variant="outline" color="blue" size="xs"><IconX stroke={1.5} /></ThemeIcon>;

  return (withLabel)
    ? <Tooltip label={label}>{content}</Tooltip>
    : content;
};

export default booleanTransform;
