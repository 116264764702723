import ContentPanel from '@/components/ContentPanel';
import PropTypes from 'prop-types';
// import styles from './style.module.css';

const Panel = (props) => {
  const {
    id,
    children,
  } = props;

  return (
    <ContentPanel id={id}>
      {children}
    </ContentPanel>
  );
};

Panel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
};

export default Panel;
