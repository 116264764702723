import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const FIND_BY_COMPANY_ID = {
  default: {
    response: {
      createdAt: 1720349797019,
      error: {
        code: 0,
        error: '',
      },
      object: 'findbycompanyid',
      success: true,
      data: [
        {
          _id: '65fc185ec676340ed68694fd',
          companyName: 'Meitav',
          files: [
            {
              title: 'מיטב מקור',
              sheets: [
                {
                  title: 'עמלות מפורט',
                  rules: {
                    startRow: 3,
                    columnNames: [
                      {
                        column: 'Q',
                        id: '66139d8037d01fce5764537a',
                        validate: 'agentNumber',
                      },
                      {
                        column: 'K',
                        id: '66139dea37d01fce57645388',
                        validate: 'number',
                      },
                      {
                        column: 'Z',
                        id: '6613d87e37d01fce57645396',
                        validate: 'number',
                      },
                      {
                        column: 'T',
                        id: '666fe63a8b5154bf71df853c',
                        validate: 'number',
                      },
                    ],
                    requiredColumns: [
                      'Q',
                      'K',
                      'Z',
                      'T',
                    ],
                  },
                },
              ],
              format: 'xlsx',
            },
          ],
          companyId: '66045d75e376171abbb826a5',
          ruleName: 'Meitav 1',
          product: [
            '6617d5b437d01fce576453cb',
          ],
        },
      ],
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720350112441,
      error: {
        code: 501,
        error: 'No documnets were found',
        message: 'אירעה שגיאה',
      },
      object: 'findbycompanyid',
      success: false,
      data: 'import_rules',
    },
    status: 500,
  },
};

const findByCompanyIdHandler = async () => {
  const { response, status } = getResponseData(FIND_BY_COMPANY_ID);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  findByCompanyIdHandler,
};
