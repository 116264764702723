import {
  Autocomplete,
  Container,
  MultiSelect,
  NumberInput,
  PasswordInput,
  Select,
  TextInput,
  createTheme,
} from '@mantine/core';
import { DatePickerInput, MonthPickerInput } from '@mantine/dates';

const style = { backgroundColor: 'var(--mantine-color-gray-0)' };

const theme = createTheme({
  fontFamily: 'Rubik, Arial',
  black: '#333',
  cursorType: 'pointer',
  primaryColor: 'brand',
  colors: {
    brand: ['#EEF2FF', '#E0E7FF', '#C7D2FE', '#A5B4FC', '#818CF8', '#6366F1', '#4F46E5', '#4338CA', '#3730A3', '#312E81'],
    gray: ['#F8FAFC', '#F1F5F9', '#E2E8F0', '#CBD5E1', '#CBD5E1', '#64748B', '#475569', '#334155', '#1E293B', '#0F172A'],
  },
  components: {
    Autocomplete: Autocomplete.extend({
      styles: {
        input: style,
      },
    }),
    NumberInput: NumberInput.extend({
      styles: {
        input: style,
      },
    }),
    MultiSelect: MultiSelect.extend({
      styles: {
        input: style,
      },
    }),
    TextInput: TextInput.extend({
      styles: {
        input: style,
      },
    }),
    PasswordInput: PasswordInput.extend({
      styles: {
        input: style,
      },
    }),
    Select: Select.extend({
      styles: {
        input: style,
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      styles: {
        input: style,
      },
    }),
    MonthPickerInput: MonthPickerInput.extend({
      styles: {
        input: style,
      },
    }),
    Container: Container.extend({
      defaultProps: {
        px: 'lg',
      },
    }),
  },
  other: {
    menuWidth: '14rem',
    sideWidth: '24rem',
    headerHeight: '4rem',
    navbarWidth: '5rem',
  },
});

/**
 * add custom variables to mantine css
 * @param {object} t - mantine theme
 * @returns {object}
 */
const resolver = (t) => ({
  variables: {
    '--header-height': t.other.headerHeight,
    '--navbar-width': t.other.navbarWidth,
    '--page-menu-width': t.other.menuWidth,
    '--page-side-width': t.other.sideWidth,
  },
});

const dates = {
  locale: 'he',
  firstDayOfWeek: 0, // sunday
  timezone: 'Asia/Jerusalem',
};

export {
  theme,
  dates,
  resolver,
};
