import { useState } from 'react';
import prepareTranslate from '@/helpers/dictionary';
import {
  Alert,
  Button,
  Container,
  Group,
  Paper,
  PasswordInput,
  Progress,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '@/assets/images/logo.png';
import routes from '@/routes';
import authApi from '@/api/services/auth';
import styles from './style.module.css';
import { MINIMUM_PASSWORD_LENGTH } from './consts';
import { getStrength } from './funcs';

const ResetPassword = () => {
  const t = prepareTranslate();
  const { uuid: resetPasswordKey } = useParams();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const [notExists, setNotExists] = useState(false);

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => (value.length < MINIMUM_PASSWORD_LENGTH ? t('pages.reset.validations.password') : null),
      confirmPassword: (value, values) => (value !== values.password ? t('pages.reset.validations.confirm') : null),
    },
  });

  const handleBackButtonClick = () => {
    navigate(routes.login.path);
  };

  const strength = getStrength(form.values.password);

  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: '0ms' } }}
        // eslint-disable-next-line max-len, no-nested-ternary
        value={form.values.password > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0}
        // eslint-disable-next-line no-nested-ternary
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size="4"
      />
    ));

  const onAlertClose = () => {
    setNotExists(false);
  };

  const onFormSubmit = async (values) => {
    const { password } = values;
    if (password.length >= MINIMUM_PASSWORD_LENGTH) {
      const response = await authApi.resetPassword({ password, resetPasswordKey });
      if (response.success) {
        setConfirm(true);
      } else {
        setConfirm(false);
        setNotExists(true);
      }
    }
  };

  return (
    <>
    <Container size={500} py={40}>
      <div className={styles.logo}>
        <img src={logo} aria-label="logo" />
      </div>
      <Title ta="center">{t('pages.reset.title')}</Title>

      <Text
        c="dimmed"
        size="sm"
        ta="center"
        mt={5}
      >
        {t('pages.reset.subtitle', [MINIMUM_PASSWORD_LENGTH])}
      </Text>

      {
        confirm
          ? (
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
              <Group>
                <Text>
                  <span dangerouslySetInnerHTML={{ __html: t('pages.reset.message') }} />
                </Text>
                <Button type="button" fullWidth onClick={handleBackButtonClick}>{t('pages.reset.actions.back')}</Button>
              </Group>
            </Paper>
          )
          : (
            <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
              <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                  <PasswordInput
                    autoComplete="off"
                    label={t('pages.reset.labels.password')}
                    required
                    key={form.key('password')}
                    {...form.getInputProps('password')}
                  />

                  <Group gap={5} grow mt="xs" mb="md">
                    {bars}
                  </Group>

                  <PasswordInput
                    autoComplete="off"
                    label={t('pages.reset.labels.confirm')}
                    required
                    key={form.key('confirmPassword')}
                    {...form.getInputProps('confirmPassword')}
                  />

                  <Button type="submit" fullWidth mt="xl">{t('pages.reset.actions.submit')}</Button>
                </Paper>
            </form>
          )
      }
      {notExists && (
       <Alert
        variant="light"
        color="red"
        withCloseButton
        mt="md"
        onClose={onAlertClose}
       >
       <span dangerouslySetInnerHTML={{ __html: t('pages.reset.alert.content') }} />
       </Alert>
      )}
    </Container>

    </>
  );
};

export default ResetPassword;
