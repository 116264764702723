export default {
  action: 'הוסף משתמש',
  notification: {
    title: 'המשתמש הופעל בהצלחה',
    message: 'פרטי המשתמש עודכנו במערכת',
    errortitle: 'שגיאה',
    error: 'לא בוצעה עריכת משתמש אנא בדוק את הפרטים שהוזנו',
    errormessage: 'לא ניתן למצוא משתמשים אנא נסה שנית',
  },
};
