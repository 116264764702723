import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  Alert,
} from '@mantine/core';
import prepareTranslate from '@/helpers/dictionary';
import logo from '@/assets/images/logo.png';
import routes from '@/routes';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import authApi from '@/api/services/auth';
import { useEffect, useState } from 'react';
import { isObject } from '@/helpers/is';
import styles from './style.module.css';

const DEFAULT_CHECKED = true;

const Login = () => {
  const navigate = useNavigate();
  const t = prepareTranslate();
  const [notExists, setNotExists] = useState(false);

  useEffect(() => {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const userCookie = cookies.find((cookie) => cookie.startsWith('userInfo=')) || '';

    try {
      const userCookieValue = JSON.parse(decodeURIComponent(userCookie));
      if (isObject(userCookieValue) && userCookieValue._id) {
        navigate(routes.main.path);
      }
    } catch { /* empty */ }
  }, [navigate]);

  const form = useForm({
    initialValues: {
      userName: '',
      password: '',
      remember: true,
    },
  });

  const onAlertClose = () => {
    setNotExists(false);
  };

  const onFormSubmit = async (values) => {
    const response = await authApi.login(values);
    if (response.success) {
      setNotExists(false);
      navigate(routes.main.path);
    } else {
      setNotExists(true);
    }
  };

  return (
    <Container size={420} py={40}>
      <div className={styles.logo}>
        <img src={logo} aria-label="logo" />
      </div>
      <Title ta="center">{t('pages.login.title')}</Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>{t('pages.login.subtitle')}</Text>

      <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <TextInput
              autoComplete="off"
              label={t('pages.login.form.username')}
              required
              key={form.key('userName')}
              {...form.getInputProps('userName')}
            />
            <PasswordInput
              autoComplete="off"
              label={t('pages.login.form.password')}
              required
              mt="md"
              key={form.key('password')}
              {...form.getInputProps('password')}
            />
            <Group justify="space-between" mt="lg">
              <Checkbox
                label={t('pages.login.form.remember')}
                key={form.key('remember')}
                {...form.getInputProps('remember')}
                defaultChecked={DEFAULT_CHECKED}
              />
              <Anchor href={routes.forgot.path} size="sm">{t('pages.login.form.forgot')}</Anchor>
            </Group>
            <Button type="submit" fullWidth mt="xl">{t('pages.login.form.action')}</Button>
          </Paper>
      </form>
      {notExists && (
        <Alert
          variant="light"
          color="red"
          withCloseButton
          mt="md"
          onClose={onAlertClose}
        >
          <span dangerouslySetInnerHTML={{ __html: t('pages.login.alert.content') }} />
        </Alert>
      )}
    </Container>
  );
};

export default Login;
