import { useRouteError } from 'react-router-dom';
import prepareTranslate from '@/helpers/dictionary';
import { Button, Group, Text } from '@mantine/core';
import routes from '@/routes';
import styles from './style.module.css';

const NoMatch = () => {
  const error = useRouteError();
  const t = prepareTranslate();

  const {
    statusText,
    message,
    status,
  } = error || {};

  const content = statusText || message;
  const errorStatus = (status === 404) ? '404' : '500';
  const errorMessage = (status === 404) ? t('pages.error.notFound') : t('pages.error.error');

  return (
    <section className="content">
      <div className={styles.main}>
        <div className={styles.info}>
          <h1>{errorStatus}</h1>
          <h2>{t('pages.error.title')}</h2>

          <Text c="dimmed" size="lg" ta="center" className={styles.description}>
            {errorMessage}
          </Text>
          <Group justify="center">
            <Button
              component="a"
              variant="subtle"
              size="md"
              href={routes.main.path}
            >
              {t('pages.error.link.title')}
            </Button>
          </Group>

          <p className={styles.hidden}>{content}</p>
        </div>
      </div>
    </section>
  );
};

export default NoMatch;
