import { useQuery } from '@tanstack/react-query';
import { getAll } from '../requests/product';

const KEY = 'product';

const productQuery = {
  useGetAll: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY],
    queryFn: () => getAll(data, headers),
  }),
};

export default productQuery;
