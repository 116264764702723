import { isObject } from '@/helpers/is';
import routes from '@/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAccessToken = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAccessToken = () => {
      const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
      const userCookie = cookies.find((cookie) => cookie.startsWith('userInfo=')) || '';

      try {
        const userCookieValue = JSON.parse(decodeURIComponent(userCookie.replace('userInfo=', '')));
        if (!userCookie || !isObject(userCookieValue) || !userCookieValue._id) {
          navigate(routes.login.path);
        } else {
          setIsLoading(false);
        }
      } catch {
        navigate(routes.login.path);
      }
    };

    checkAccessToken();
  }, [navigate]);

  return { isLoading };
};

export default useAccessToken;
