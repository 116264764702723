import {
  isArray,
  isObject,
  isString,
  isFunction,
} from '@/helpers/is';
import PropTypes from 'prop-types';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/he';
import dayjs from 'dayjs';
import dateTransform from './transform/date';
import timeagoTransform from './transform/timeago';
import emailTransform from './transform/email';
import yesnoTransform from './transform/yesno';
import phoneTransform from './transform/phone';
import badgeTransform from './transform/badge';
import textTransform from './transform/text';
import booleanTransform from './transform/boolean';
import edgesTransform from './transform/edges';
import numberFormatTransform from './transform/numberFormat';

dayjs.locale('he');
dayjs.extend(relativeTime);

const TRANSFORMS = {
  badge: badgeTransform,
  boolean: booleanTransform,
  date: dateTransform,
  edges: edgesTransform,
  email: emailTransform,
  phone: phoneTransform,
  text: textTransform,
  timeago: timeagoTransform,
  yesno: yesnoTransform,
  numberformat: numberFormatTransform,
};

const prepareTransforms = (data = []) => {
  let output = [];

  // prepare transform array by making all items { func: 'name', params: { param1, ... }}
  // params is not mandatory
  output = data.map((e) => {
    if (isObject(e) && e.func) {
      return e;
    }

    if (isString(e)) {
      return { func: e, params: [] };
    }

    return null;
  });

  return output;
};

const Transforms = (props) => {
  const {
    source = '',
    transforms = [],
  } = props;

  let output = source;

  if (!isArray(transforms)) {
    return output;
  }

  const transformsArray = prepareTransforms(transforms);

  transformsArray.forEach((item) => {
    const {
      func = '',
      params = {},
    } = item;

    const formattedFunc = String(func).toLowerCase();
    const transformFunction = TRANSFORMS[formattedFunc] || null;

    if (isFunction(transformFunction)) {
      output = transformFunction(output, params);
    }
  });

  return output;
};

Transforms.propTypes = {
  source: PropTypes.any,
  transforms: PropTypes.array,
};

export default Transforms;
