import { useQuery } from '@tanstack/react-query';
import { getAll } from '../requests/company';

const KEY = 'company';

const companyQuery = {
  useGetAll: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY],
    queryFn: () => getAll(data, headers),
  }),
};

export default companyQuery;
