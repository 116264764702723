import dayjs from 'dayjs';
import localData from 'dayjs/plugin/localeData';
import prepareTranslate from './dictionary';
import { isObjectEmpty } from './is';

const WHITE_LIST = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const SPLIT_CHAR = '.';

/**
 * create random string
 * @param {number} length result string length
 * @returns {string} random string
 */
const generateRandomString = (length = 10) => {
  let result = '';
  const characters = WHITE_LIST;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

/**
 * replace string inside another string in specific position
 * @param {object} parameters
 * @returns {string}
 */
const replaceLetters = (parameters) => {
  const {
    string = '',
    from = 0,
    to = 0,
    replacement = '',
  } = parameters;

  return string.substring(0, from) + replacement + string.substring(to);
};

/**
 * add commas to number
 * @param {number} number
 * @returns {string} number with commas
 */
const addCommas = (number) => {
  const num = (typeof number === 'number') ? number : convertStringToNumber(number);
  return num.toLocaleString('en-US');
};

/**
 * limit number digits
 * @param {number} number
 * @param {number} digits
 * @returns {number}
 */
const limitNumberDigits = (number, digits = 3) => {
  const num = (typeof number === 'number') ? number : convertStringToFloat(number);
  return num.toPrecision(digits + 1);
};

/**
 * remove all special chars from string and leave only letters and numbers
 * @param {string} path
 * @returns {string}
 */
const getCleanString = (path = '') => String(path).replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

/**
 * add slash to path if not exists as first char
 * @param {string} path
 * @returns {string}
 */
const addSlashToPath = (path = '') => ((String(path).charAt(0) === '/') ? path : `/${path}`);

/**
 * convert string to number if can, returns back val if cant convert
 * @param {mixed} val
 * @returns {mixed}
 */
const convertStringToNumber = (val = '') => (
  (typeof val === 'string' && !Number.isNaN(parseInt(val, 10)))
    ? parseInt(val, 10)
    : val
);

/**
 * convert string to float if can, returns back val if cant convert
 * @param {mixed} val
 * @returns {mixed}
 */
const convertStringToFloat = (val = '') => (
  (typeof val === 'string' && !Number.isNaN(parseInt(val, 10)))
    ? parseFloat(val, 10)
    : val
);

/**
 * slugify text
 * @param {string} str
 * @returns {string}
 */
const slugify = (str = '') => String(str)
  .normalize('NFKD') // split accented characters into their base characters and diacritical marks
  .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
  .trim() // trim leading or trailing whitespace
  .toLowerCase() // convert to lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
  .replace(/\s+/g, '-') // replace spaces with hyphens
  .replace(/-+/g, '-'); // remove consecutive hyphens

/**
 * format date into string
 * @param {mixed} d date value. object or string
 * @param {boolean} withTime includes time in result
 * @returns {string}
 */
const formatDateToString = (d = '', withTime = false) => {
  if (!d) return '';

  dayjs.extend(localData);

  const t = prepareTranslate();
  const months = dayjs.months();
  const monthName = String(months[dayjs(d).month()]).toLocaleLowerCase();
  const monthNameTranslated = t(`global.months.${monthName}`) || monthName;
  let format = `DD ${t('global.in')}${monthNameTranslated} YYYY`;

  if (withTime) {
    format += `, ${t('global.in')}${t('global.hour')} HH:mm`;
  }

  const formatted = dayjs(d, 'D/M/YYYY').format(format);

  return formatted;
};

/**
 * format dd/mm/yyyy date into string
 * @param {mixed} d string date value
 * @param {boolean} withTime includes time in result
 * @returns {string}
 */
const formatDDMMYYYYToString = (d = '', withTime = false) => {
  if (!d) return '';

  dayjs.extend(localData);

  const t = prepareTranslate();
  const months = dayjs.months();

  // Split the date string and create a new Date object
  const [day, month, year] = d.split('/');
  const parsedDate = dayjs(new Date(year, month - 1, day));

  if (!parsedDate.isValid()) {
    return 'Invalid Date';
  }

  const monthName = String(months[parsedDate.month()]).toLowerCase();

  const monthNameTranslated = t(`global.months.${monthName}`) || monthName;
  let format = `DD ${t('global.in')}${monthNameTranslated} YYYY`;

  if (withTime) {
    format += `, ${t('global.in')}${t('global.hour')} HH:mm`;
  }

  const formatted = parsedDate.format(format);

  return formatted;
};

const FAMILY_STATUS = Object.freeze({
  single: 'רווק/ה',
  married: 'נשוי/אה',
  divorced: 'גרוש/ה',
  widowed: 'אלמן/ה',
});

const GENDER = Object.freeze({
  male: 'זכר',
  female: 'נקבה',
});

const AGENT_TYPE = Object.freeze({
  CTO: 'מנכ"ל',
  houseAgent: 'סוכן בית',
  integratedAgent: 'סוכן משולב',
  foreignAgent: 'סוכן חוץ',
});

/**
 * truncate string and put dots in the middle (ellipsis)
 * @param {string} str source string
 * @param {number} firstCharCount number of characters to be shown in the first portion
 * @param {*} endCharCount number of characters to be shown in the end portion
 * @param {*} dotCount count of dots to be placed between the first and end portions
 * @param {string} replaceChar character to replace with
 * @returns {string}
 */
const truncateString = (str = '', firstCharCount = str.length, endCharCount = 0, dotCount = 3, replaceChar = '.') => {
  // No truncation needed
  if (str.length <= firstCharCount + endCharCount) {
    return str;
  }

  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCharCount);
  const dots = replaceChar.repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
};

/**
 * check if date value is after now
 * @param {mixed} d date value. object or string
 * @returns {boolean}
 */
const isDateAfterNow = (d) => ((d) ? dayjs(d).isAfter(dayjs()) : false);

/**
 * get value from object using string dot notation. example: 'details.name.first'
 * @param {object} data
 * @param {string} str - dot notation string
 * @returns {mixed}
 */
const getItemValue = (data, str) => {
  let output = null;
  const source = String(str).trim();

  try {
    output = source.split(SPLIT_CHAR).reduce((a, b) => a[b], data);
  } catch (error) {
    // nothing to do here...
  }

  return output;
};

/**
 * helper function for queryStringToObject
 * @private
 * @param {mixed} value
 * @returns {mixed}
 */
// const convertValue = (value) => {
//   if (value === null || value === '') return null;
//   if (value === 'true') return true;
//   if (value === 'false') return false;
//   if (value === 'undefined') return undefined;
//   if (isNumber(value)) return Number(value);
//   return value;
// };

/**
 * helper function for queryStringToObject
 * @private
 * @param {object} obj
 * @param {string} path
 * @param {*} value
 */
// const setNestedProperty = (obj, path, value) => {
//   const keys = path.replace(/\[([^\]]+)?\]/g, '.$1').split('.');
//   let current = obj;

//   for (let i = 0; i < keys.length - 1; i += 1) {
//     const key = keys[i];
//     if (key === '') {
//       // Handle array notation
//       let j = 0;
//       while (current[j] !== undefined) j += 1;
//       current[j] = {};
//       current = current[j];
//     } else {
//       if (!(key in current)) {
//         if (keys[i + 1] === '' || !Number.isNaN(keys[i + 1])) {
//           current[key] = [];
//         } else {
//           current[key] = {};
//         }
//       }
//       current = current[key];
//     }
//   }

//   const lastKey = keys[keys.length - 1];
//   if (lastKey === '') {
//     current.push(value);
//   } else {
//     current[lastKey] = value;
//   }
// };

/**
 * change query string into object
 * @param {string} queryString
 * @returns {object}
 */
// const queryStringToObject = (queryString = '') => {
//   const q = (queryString.startsWith('?')) ? queryString.slice(1) : queryString;
//   const pairs = q.split('&');
//   const result = {};

//   pairs.forEach((pair) => {
//     const [key, value] = pair.split('=');
//     if (!key) return;

//     const decodedKey = decodeURIComponent(key);
//     const decodedValue = value ? decodeURIComponent(value) : null;

//     setNestedProperty(result, decodedKey, convertValue(decodedValue));
//   });

//   return result;
// };

const queryStringToObject = (queryString = '') => JSON.parse(queryString);

/**
 * change object into query string
 * @param {object} params
 * @param {string} prefix
 * @returns {string}
 */
// const objectToQueryString = (params = {}, prefix = '') => {
//   if (isObjectEmpty(params)) {
//     return '';
//   }

//   return (Object.keys(params)
//     .map((key) => {
//       const value = params[key];
//       const fullKey = prefix ? `${prefix}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

//       if (value === null || value === undefined) {
//         return fullKey;
//       }

//       if (typeof value === 'object' && !Array.isArray(value)) {
//         return objectToQueryString(value, fullKey);
//       }

//       if (Array.isArray(value)) {
//         if (value.length === 0) {
//           return `${fullKey}[]`;
//         }

//         return value.map((item) => `${fullKey}[]=${encodeURIComponent(item)}`).join('&');
//       }

//       return `${fullKey}=${encodeURIComponent(value)}`;
//     })
//     .join('&')
//   );
// };

const objectToQueryString = (params = {}) => {
  if (isObjectEmpty(params)) {
    return '';
  }
  return JSON.stringify(params);
};

export {
  generateRandomString,
  replaceLetters,
  addCommas,
  getCleanString,
  addSlashToPath,
  convertStringToNumber,
  limitNumberDigits,
  convertStringToFloat,
  slugify,
  formatDateToString,
  formatDDMMYYYYToString,
  truncateString,
  isDateAfterNow,
  getItemValue,
  FAMILY_STATUS,
  GENDER,
  AGENT_TYPE,
  queryStringToObject,
  objectToQueryString,
};
