import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

const LocationProvider = (props) => {
  const { children } = props;

  return <AnimatePresence>{children}</AnimatePresence>;
};

LocationProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LocationProvider;
