import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Paper,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { IconMail, IconMessage, IconPhone } from '@tabler/icons-react';
import { useState } from 'react';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import prepareTranslate from '@/helpers/dictionary';
import { isEmail, isPhone } from '@/helpers/is';
import messageMutations from '@/api/mutitations/message';
import styles from './style.module.css';

const LABEL_MAX = 200;

const Messages = ({
  id, type, email, phone,
}) => {
  const t = prepareTranslate();
  const [opened, { open, close }] = useDisclosure(false);
  const { useCreateMessage } = messageMutations;
  const createMessageMutation = useCreateMessage();

  const [selectedOption, setSelectedOption] = useState(null);
  const [characterCount, setCharacterCount] = useState(0);
  const [isSendDisabled, setIsSendDisabled] = useState(false);

  const form = useForm({
    initialValues: {
      message: '',
      subject: '',
    },
    validate: {
      subject: (val) => {
        if (selectedOption === 'email' && !val.trim()) {
          return t('validation.required');
        }
        return null;
      },
    },
  });

  const handleOptionSelect = (option) => {
    setSelectedOption(selectedOption === option ? null : option);
    form.setFieldValue('subject', '');
    form.setFieldValue('message', '');
    setCharacterCount(0);
  };

  const handleMessageChange = (event) => {
    const text = event.currentTarget.value;
    form.setFieldValue('message', text);
    if (selectedOption === 'phone') {
      setCharacterCount(text.trim().length);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      setIsSendDisabled(true);
      open();

      const subject = selectedOption === 'email' ? values.subject : 'sms';

      await createMessageMutation.mutateAsync({
        entity: type,
        entityId: id,
        variant: [selectedOption === 'phone' ? 'sms' : selectedOption],
        subject,
        body: values.message,
      });

      notifications.show({
        title: t('components.messages.notification.title'),
        message: t('components.messages.notification.message'),
      });

      form.reset();
      setSelectedOption(null);
      setCharacterCount(0);
    } catch (error) {
      notifications.show({
        title: t('components.messages.notification.errortitle'),
        message: t('components.messages.notification.error'),
      });
    } finally {
      close();
      setIsSendDisabled(false);
    }
  };

  const isFormValid = () => {
    if (selectedOption === 'email') {
      return form.values.subject.trim() !== '' && form.values.message.trim() !== '';
    }
    return form.values.message.trim() !== '';
  };

  if ((!isPhone(phone) && !isEmail(email)) || !id) {
    return (
      <Paper withBorder p='lg' radius='md' shadow='md'>
        <Text fz='md' fw={500} mb='xs'>
          {t('components.messages.empty.title')}
        </Text>
        <Text c='dimmed' fz='xs'>
          {t('components.messages.empty.body')}
        </Text>
      </Paper>
    );
  }

  const messageOption = (optionType, value, icon) => (
    <UnstyledButton
      onClick={() => handleOptionSelect(optionType)}
      className={styles.button}
    >
      <Group className={styles.line}>
        <div className={styles.check}>
          <Checkbox
            checked={selectedOption === optionType}
            onChange={() => {}}
            tabIndex='-1'
          />
        </div>
        <div className={styles.body}>
          <Text c='dimmed' size='xs' lh={1} mb={5}>
            {t(`components.messages.headers.${optionType}`)}
          </Text>
          <Text fw={500} size='sm' lh={1} className={styles.ltr}>
            {value}
          </Text>
        </div>
        <div className={styles.avatar}>
          <ThemeIcon variant='light' radius='md'>
            {icon}
          </ThemeIcon>
        </div>
      </Group>
    </UnstyledButton>
  );

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={opened} overlayBlur={2} />

      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <Text size='sm'>{t('components.messages.headers.variant')}</Text>
        <Group mb='xl'>
          {isPhone(phone) && messageOption('phone', phone, <IconPhone style={{ width: '70%', height: '70%' }} />)}
          {isEmail(email) && messageOption('email', email, <IconMail style={{ width: '70%', height: '70%' }} />)}
        </Group>

        {selectedOption === 'email' && (
          <TextInput
            mb='xl'
            label={t('components.messages.form.subject')}
            {...form.getInputProps('subject')}
          />
        )}

        <Textarea
          mb='xl'
          autosize
          label={t('components.messages.form.message')}
          description={selectedOption === 'phone' ? `${characterCount}/${LABEL_MAX} תווים` : undefined}
          minRows={3}
          maxRows={8}
          {...form.getInputProps('message')}
          onChange={handleMessageChange}
        />

        <Button
          type='submit'
          leftSection={<IconMessage />}
          disabled={
            isSendDisabled
            || !selectedOption
            || !isFormValid()
          }
        >
          {t('components.messages.form.action')}
        </Button>
      </form>
    </div>
  );
};

Messages.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default Messages;
