import { REQUIREMENTS, MINIMUM_PASSWORD_LENGTH } from './consts';

/**
 * calculate string strength factor by requirements rules
 * @param {string} password
 * @returns {number}
 */
const getStrength = (password) => {
  let multiplier = password.length >= MINIMUM_PASSWORD_LENGTH ? 0 : 1;

  REQUIREMENTS.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (REQUIREMENTS.length + 1)) * multiplier, 0);
};

export {
  getStrength,
};
