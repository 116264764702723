import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import {
  Alert,
  Button,
  Group,
  Paper,
  Text,
  TextInput,
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '@/routes';
import authApi from '@/api/services/auth';

const NotSent = (props) => {
  const { onSent = () => {} } = props;
  const t = prepareTranslate();
  const navigate = useNavigate();
  const [notExists, setNotExists] = useState(false);
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: isEmail(t('pages.forgot.form.validation.email')),
    },
  });

  const onAlertClose = () => {
    setNotExists(false);
  };

  const onFormSubmit = async (values) => {
    const { email } = values;
    const response = await authApi.forgotPassword({ email });
    if (response.success) {
      setNotExists(false);
      onSent(true);
    } else {
      setNotExists(true);
    }
  };

  const handleButtonCancelClick = () => {
    navigate(routes.login.path);
  };

  return (
    <>
      <Text
        c="dimmed"
        size="sm"
        ta="center"
        mt={5}
        dangerouslySetInnerHTML={{ __html: t('pages.forgot.subtitle') }}
      />

      <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <TextInput
              autoComplete="off"
              label={t('pages.forgot.form.email')}
              required
              key={form.key('email')}
              {...form.getInputProps('email')}
            />

            <Group justify="space-between" mt="lg">
              <Button type="submit">{t('pages.forgot.form.actions.submit')}</Button>
              <Button type="button" variant="transparent" onClick={handleButtonCancelClick}>
                {t('pages.forgot.form.actions.back')}
              </Button>
            </Group>

          </Paper>
      </form>
      {notExists && (
        <Alert
          variant="light"
          color="red"
          withCloseButton
          mt="md"
          onClose={onAlertClose}
        >
          <span dangerouslySetInnerHTML={{ __html: t('pages.forgot.alert.content') }} />
        </Alert>
      )}

    </>
  );
};

NotSent.propTypes = {
  onSent: PropTypes.func,
};

export default NotSent;
