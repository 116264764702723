import routes from '@/routes';
import {
  IconReport,
  IconUsersGroup,
  IconHome2,
  IconSettings,
  IconUsers,
  IconFolders,
} from '@tabler/icons-react';

const links = [
  { icon: IconHome2, label: routes.main.name, to: routes.main.path },
  { icon: IconUsersGroup, label: routes.agents.name, to: routes.agents.path },
  { icon: IconReport, label: routes.reports.name, to: routes.reports.path },
  { icon: IconUsers, label: routes.users.name, to: routes.users.path },
  { icon: IconFolders, label: routes.uploads.name, to: routes.uploads.path },
];

const footer = [
  {
    icon: IconSettings,
    label: routes.settings.name,
    to: routes.settings.path,
  },
];

export {
  links,
  footer,
};
