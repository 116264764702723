export default {
  title: 'צפייה במשתמש {1}',
  subtitle: 'עודכן לאחרונה בתאריך {1}',
  action: 'ערוך משתמש',
  status: {
    active: 'פעיל',
    archive: 'ארכיון',
  },
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    histories: 'היסטוריה',
    messages: 'הודעות',
  },
  labels: {
    firstname: 'שם פרטי',
    lastname: 'שם משפחה',
    role: 'תפקיד',
    email: 'דואר אלקטרוני',
    phone: 'טלפון',
    lastLoginAttempt: 'התחבר לאחרונה',
    loginAttempts: 'מספר נסיונות התחברות',
    passwordLastChanged: 'סיסמא שונתה בתאריך',
    block: 'חסום',
  },
  headers: {
    details: 'פרטים',
    contact: 'פרטי קשר',
    info: 'מידע',
  },
};
