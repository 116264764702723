import { useQuery } from '@tanstack/react-query';
import { getAgentById } from '../requests/agent';

const KEY = 'agent';

const agentQuery = {
  useGetAgentById: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY, data],
    queryFn: () => getAgentById(data, headers),
    enabled: !!data.id,
    cacheTime: 0,
    staleTime: 0,
  }),
};
export default agentQuery;
