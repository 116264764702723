import { CHART_TYPES } from '@/helpers/enums';
import { Select } from '@mantine/core';
import PropTypes from 'prop-types';

const ChartType = (props) => {
  const {
    onChartTypeSelect = () => {},
  } = props;

  const handleSelectChange = (value) => {
    onChartTypeSelect(value);
  };

  // eslint-disable-next-line no-unused-vars
  const selectOptions = Object.entries(CHART_TYPES).map(([_, typeValue]) => typeValue);

  return (
    <div>
      {
        (
          <Select
            defaultValue={CHART_TYPES.bar}
            data={selectOptions}
            onChange={handleSelectChange}
          />
        )
      }
    </div>
  );
};

ChartType.propTypes = {
  onChartTypeSelect: PropTypes.func,
};

export default ChartType;
