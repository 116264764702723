import { useQuery } from '@tanstack/react-query';
import { getUploadsById, getAllUploads } from '../requests/upload';

const KEY = 'upload';

const uploadQuery = {
  useGetFilesById: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY, data],
    queryFn: () => getUploadsById(data, headers),
    enabled: true,
  }),
  useGetFiles: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY, data],
    queryFn: () => getAllUploads(data, headers),
    enabled: true,
  }),
};
export default uploadQuery;
