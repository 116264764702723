export default {
  title: 'איפוס סיסמא',
  subtitle: `
    הזן את כתובת דואר אלקטרוני המשוייך לחשבון שלך במערכת
    <br/>
    ואנחנו נשלח לך קישור לאיפוס הסיסמא
  `,
  sent: {
    content: `
      בדוק בחשבון הדואר שלך אם קיבלת דואר עם קישור לאיפוס הסיסמא.
      אם הדואר לא מופיע תוך כמה דקות, בדוק בתיבת הספאם.
    `,
  },
  alert: {
    content: `
    הכתובת שהוזנה אינה משוייכת לחשבון במערכת.
    <br/>
    בדוק שהכתובת שהזנת הינה נכונה.
    `,
  },
  form: {
    email: 'דואר אלקטרוני',
    action: 'שלח קישור לאיפוס הסיסמא',
    validation: {
      email: 'חובה להזין כתובת תקינה',
    },
    actions: {
      submit: 'שלח מייל לאיפוס סיסמא',
      back: 'בחזרה לעמוד כניסה',
    },
  },
};
