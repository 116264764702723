export default {
  title: 'צפייה בסוכן {1}',
  subtitle: 'עודכן לאחרונה בתאריך {1}',
  action: 'ערוך סוכן',
  menu: {
    details: 'פרטים',
    contracts: 'הסכמים {1}',
    documents: 'מסמכים {1}',
    clients: 'מספרי תיק/יישות {1}',
  },
  status: {
    active: 'פעיל',
    archive: 'ארכיון',
  },
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    histories: 'היסטוריה',
  },
  labels: {
    fullname: 'שם מלא',
    uid: 'תעודת זהות',
    familyStatus: 'מצב משפחתי',
    dob: 'תאריך לידה',
    gender: 'מין',
    spouse: 'שם בן / בת זוג',
    numberOfChildren: 'מספר ילדים',
    employeeType: 'סוג סוכן',
    email: 'דואר אלקטרוני',
    phone: 'טלפון',
    cellphone: 'נייד',
    extracellphone: 'נייד נוסף',
    website: 'אתר אנטרנט',
    homeaddress: 'כתובת מלאה',
    homephone: 'טלפון',
    officeaddress: 'כתובת מלאה',
    officephone: 'טלפון',
    policyend: 'תאריך תום פוליסה אחריות מקצועית',
    licenseend: 'תאריך תום רשיון',
    tax: 'תאריך ניכוי מס וניהול ספרים',
    professionalPolicy: 'תאריך פוליסה מקצועית',
    entityNumber: 'מספר תיק/יישות',
    clientName: 'שם תיק/יישות',
    hp: 'ח.פ',
    commision: {
      agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה',
      agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה',
      agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה',
      agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה',
      agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה',
      agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה',
      agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה',
      agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה',
    },
  },
  headers: {
    private: 'פרטים אישיים',
    contact: 'פרטי קשר',
    home: 'בית',
    office: 'משרד',
    history: 'היסטוריה',
    comment: 'הערות',
    contracts: 'הסכמים',
    extra: 'מידע נוסף',
    family: 'משפחה',
    dates: 'תאריכים',
    documents: 'מסמכים',
    entityNumbers: 'מספרי תיק/יישות',
  },
};
