import prepareTranslate from '@/helpers/dictionary';
import agentMutations from '@/api/mutitations/agent';
import { useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  Button,
  Grid,
  NumberInput,
  Select,
  TextInput,
  Text,
  Card,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import routes from '@/routes';
import { routeWithParams } from '@/helpers/route';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import PageHead from '@/components/PageHead';
import PageTemplate from '@/components/PageTemplate';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import Section from '@/components/Section';
import uploadMutations from '@/api/mutitations/upload';
import { MonthPickerInput } from '@mantine/dates';
import {
  AGENT_STATUS,
  FAMILY_STATUS,
  GENDER,
  AGENT_TYPE,
  EMPLOYEE_TYPE,
} from '@/helpers/enums';
import validations from '@/helpers/validation';
import { useState } from 'react';
import Files from '@/components/Files';

const Agentcreate = () => {
  const t = prepareTranslate();
  const navigate = useNavigate();
  const { useUpload } = uploadMutations;
  const { mutateAsync: uploadMutate } = useUpload();
  const [loading, { open, close }] = useDisclosure(false);
  const [files, setFiles] = useState([]);

  const { useCreateAgent } = agentMutations;
  const { mutateAsync } = useCreateAgent();

  // TODO REMOVE FROM HERE AND FRON THE EDIT PAGE
  const handleDelete = (fileName) => {
    const fillterArray = files.filter((item) => (
      item.name !== fileName && item.fileName !== fileName
    ));
    setFiles(fillterArray);
  };

  const handleReject = () => {
    open();
    notifications.show({
      title: t('pages.agentedit.notification.files.title'),
      message: t('pages.agentedit.notification.files.message'),
    });
    close();
  };

  // END OF TODO

  const form = useForm({
    initialValues: {
      agreements: [],
      personalDetails: {
        childrens: [],
      },
      entityNumbers: [],
    },
    validate: {
      status: (value) => validations.required(value),
      agentType: (value) => validations.required(value),
      employeeType: (value) => validations.required(value),
      personalDetails: {
        firstName: (value) => validations.required(value),
        lastName: (value) => validations.required(value),
        uid: (value) => validations.required(value) || validations.uid(value),
        gender: (value) => validations.required(value) || validations.required(value),
        dob: (value) => validations.date(value) || validations.required(value),
        email: (value) => value && validations.email(value),
        phone: (value) => value && validations.phone(value),
        extraPhone: (value) => value && validations.phone(value),
        homeZipCode: (value) => value && validations.number(Number(value)),
        homePhone: (value) => value && validations.phone(value),
        homePostalCode: (value) => value && validations.number(Number(value)),
        officeZipCode: (value) => value && validations.number(Number(value)),
        officePostalCode: (value) => value && validations.number(Number(value)),
        childrens: {
          childrenName: (value) => validations.required(value),
        },
      },
      agreements: {
        agentNumber: (value) => validations.required(value),
        from: (value) => validations.required(value),
        companyId: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
        // agencyFeeMonthlyDeposit: (value) => validations.required(value),
        // agencyFeeMonthlyAccumulation: (value) => validations.required(value),
        // agentFeeMonthlyAccumulation: (value) => validations.required(value),
        // agentFeeMonthlyDeposit: (value) => validations.required(value),
        // agentFeeAnnualAccumulation: (value) => validations.required(value),
        // agentFeeAnnualDeposit: (value) => validations.required(value),
        // agencyFeeAnnualDeposit: (value) => validations.required(value),
        // agencyFeeAnnualAccumulation: (value) => validations.required(value),
      },
      entityNumbers: {
        clientName: (value) => validations.required(value),
        entityNumber: (value) => validations.required(value),
      },
    },
  });

  const { data: companies } = companyQuery.useGetAll();
  const { data: products } = productQuery.useGetAll();

  const companiesOptions = companies?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const productsOptions = products?.map((product) => ({
    label: product.hebrew,
    value: product._id,
  }));

  // eslint-disable-next-line consistent-return
  const handleAgentCreateClick = async (agentValues) => {
    try {
      open();

      const newAgent = await mutateAsync(agentValues);

      if (!newAgent) {
        notifications.show({
          title: t('pages.agentcreate.notification.failure.title'),
          message: t('pages.agentcreate.notification.failure.message'),
        });
        close();
        return null;
      }
      const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
      const userCookie = cookies.find((cookie) => cookie.startsWith('userInfo=')) || '';
      const userCookieValue = { entity: 'agent', entityId: newAgent.id, ...JSON.parse(decodeURIComponent(userCookie.replace('userInfo=', ''))) };

      if (files.length > 0) {
        try {
          const formData = new FormData();

          const fillterdFiles = files.filter((file) => !file._id);

          fillterdFiles.forEach((file) => {
            formData.append('files', file, file?.name);
          });

          formData.append('user', JSON.stringify(userCookieValue));
          await uploadMutate(formData);
        } catch (error) {
          notifications.show({
            title: t('pages.agentedit.notification.failure.title'),
            message: t('pages.agentedit.notification.failure.message'),
          });
          close();
        }
      }

      notifications.show({
        title: t('pages.agentcreate.notification.success.title'),
        message: t('pages.agentcreate.notification.success.message'),
      });

      const { id } = newAgent;
      navigate(routeWithParams(routes.agentView.path, { id }));

      close();
    } catch (error) {
      notifications.show({
        title: t('pages.agentcreate.notification.failure.title'),
        message: t('pages.agentcreate.notification.failure.message'),
      });
      close();
    }
  };

  const handleAgentCancelClick = () => {
    navigate(routes.agents.path);
  };

  const menuGroups = [
    {
      links: [
        {
          id: 'details',
          title: t('pages.agentcreate.menu.details'),
        },
        {
          id: 'contracts',
          title: t('pages.agentcreate.menu.contracts'),
        },
        {
          id: 'clients',
          title: t('pages.agentcreate.menu.clients'),
        },
      ],
    },
  ];

  const onError = () => {
    open();
    notifications.show({
      title: t('pages.agentcreate.notification.validtion.title'),
      message: t('pages.agentcreate.notification.validtion.message'),
    });
    close();
  };

  const actions = [
    <Button
      key='primary'
      loading={loading}
      leftSection={<IconCheck />}
      onClick={form.onSubmit(handleAgentCreateClick, onError)}
    >
      {t('global.save')}
    </Button>,
    <Button key='cancel' variant='outline' onClick={handleAgentCancelClick}>
      {t('global.cancel')}
    </Button>,
  ];

  return (
    <>
      <PageHead title={t('pages.agentcreate.title')} />

      <PageTemplate
        menuGroups={menuGroups}
        actions={actions}
        actionsTitle={t('pages.agentcreate.side.actions')}
      >
        <form>
          <PageTemplate.Panel id='details'>
            <Section title={t('pages.agentcreate.headers.private')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentcreate.form.status')}
                    data={AGENT_STATUS}
                    required
                    key={form.key('status')}
                    {...form.getInputProps('status')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentcreate.form.agentType')}
                    data={AGENT_TYPE}
                    required
                    key={form.key('agentType')}
                    {...form.getInputProps('agentType')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentcreate.form.employeeType')}
                    data={EMPLOYEE_TYPE}
                    required
                    key={form.key('employeeType')}
                    {...form.getInputProps('employeeType')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.uid')}
                    required
                    maxLength={9}
                    key={form.key('personalDetails.uid')}
                    {...form.getInputProps('personalDetails.uid')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.firstname')}
                    required
                    maxLength={20}
                    key={form.key('personalDetails.firstName')}
                    {...form.getInputProps('personalDetails.firstName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.lastname')}
                    required
                    maxLength={20}
                    key={form.key('personalDetails.lastName')}
                    {...form.getInputProps('personalDetails.lastName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    clearable
                    autoComplete='off'
                    maxLength={10}
                    required
                    label={t('pages.agentcreate.form.dob')}
                    key={form.key('personalDetails.dob')}
                    {...form.getInputProps('personalDetails.dob')}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue('personalDetails.dob', v);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentcreate.form.gender')}
                    data={GENDER}
                    required
                    key={form.key('personalDetails.gender')}
                    {...form.getInputProps('personalDetails.gender')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentcreate.headers.contact')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.email')}
                    maxLength={30}
                    key={form.key('personalDetails.email')}
                    {...form.getInputProps('personalDetails.email')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.cellphone')}
                    maxLength={10}
                    key={form.key('personalDetails.phone')}
                    {...form.getInputProps('personalDetails.phone')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.extracellphone')}
                    maxLength={10}
                    key={form.key('personalDetails.extraPhone')}
                    {...form.getInputProps('personalDetails.extraPhone')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.website')}
                    maxLength={40}
                    key={form.key('personalDetails.website')}
                    {...form.getInputProps('personalDetails.website')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentcreate.headers.home')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.city')}
                    maxLength={20}
                    key={form.key('personalDetails.homeCity')}
                    {...form.getInputProps('personalDetails.homeCity')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.street')}
                    key={form.key('personalDetails.homeStreet')}
                    {...form.getInputProps('personalDetails.homeStreet')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.houseNumber')}
                    key={form.key('personalDetails.homeHouseNumber')}
                    maxLength={3}
                    {...form.getInputProps('personalDetails.homeHouseNumber')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.zipCode')}
                    key={form.key('personalDetails.homeZipCode')}
                    maxLength={7}
                    {...form.getInputProps('personalDetails.homeZipCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.postalCode')}
                    key={form.key('personalDetails.postalCode')}
                    maxLength={7}
                    {...form.getInputProps('personalDetails.postalCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.phone')}
                    key={form.key('personalDetails.homePhone')}
                    maxLength={9}
                    {...form.getInputProps('personalDetails.homePhone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentcreate.headers.office')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.city')}
                    key={form.key('personalDetails.officeCity')}
                    maxLength={20}
                    {...form.getInputProps('personalDetails.officeCity')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.street')}
                    key={form.key('personalDetails.officeStreet')}
                    maxLength={20}
                    {...form.getInputProps('personalDetails.officeStreet')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.houseNumber')}
                    key={form.key('personalDetails.officeHouseNumber')}
                    maxLength={3}
                    {...form.getInputProps('personalDetails.officeHouseNumber')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.zipCode')}
                    key={form.key('personalDetails.officeZipCode')}
                    maxLength={7}
                    {...form.getInputProps('personalDetails.officeZipCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.postalCode')}
                    key={form.key('personalDetails.officepostalCode')}
                    maxLength={7}
                    {...form.getInputProps('personalDetails.officepostalCode')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.phone')}
                    key={form.key('personalDetails.officePhone')}
                    maxLength={9}
                    {...form.getInputProps('personalDetails.officePhone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentcreate.headers.family')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Select
                    label={t('pages.agentcreate.form.familystatus')}
                    data={FAMILY_STATUS}
                    key={form.key('personalDetails.familyStatus')}
                    {...form.getInputProps('personalDetails.familyStatus')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentcreate.form.spouse')}
                    key={form.key('personalDetails.spouse')}
                    maxLength={20}
                    {...form.getInputProps('personalDetails.spouse')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                    {form.getValues().personalDetails.childrens.map((item, index) => (
                      <Grid align='center' key={index + 1}>
                      <Grid.Col span={{ base: 12, md: 3 }}>
                          <TextInput
                            autoComplete='off'
                            maxLength={25}
                            required
                            label={t('pages.agentcreate.form.fullName')}
                            key={form.key(`personalDetails.childrens.${index}.childrenName`)}
                            {...form.getInputProps(`personalDetails.childrens.${index}.childrenName`)}
                          />
                      </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 3 }}>
                          <TextInput
                            clearable
                            autoComplete='off'
                            maxLength={10}
                            label={t('pages.agentcreate.form.dob')}
                            key={form.key(`personalDetails.childrens.${index}.childrenDob`)}
                            {...form.getInputProps(`personalDetails.childrens.${index}.childrenDob`)}
                            onChange={(e) => {
                              const v = e.target.value
                                .replace(/^(\d\d)(\d)$/g, '$1/$2')
                                .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                                .replace(/[^\d/]/g, '');
                              form.setFieldValue(`personalDetails.childrens.${index}.childrenDob`, v);
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 1 }}>
                          <ActionIcon
                            mt={25}
                            onClick={() => {
                              form.removeListItem('personalDetails.childrens', index);
                            }}
                            color={'red'}
                            variant={'transparent'}
                          >
                            <IconTrash />
                          </ActionIcon>
                          </Grid.Col>
                  </Grid>
                    ))}
                  <Button
                    mt={15}
                    mb={15}
                    onClick={() => {
                      form.insertListItem('personalDetails.childrens', {
                        childrenName: '',
                        childrenDob: null,
                      });
                    }}
                  >
                    {t('pages.agentcreate.form.addChildren')}
                  </Button>
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.agentcreate.headers.dates')}>
              <Grid gutter='lg'>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    clearable
                    autoComplete='off'
                    maxLength={10}
                    label={t('pages.agentedit.form.policyend')}
                    key={form.key('personalDetails.policyEndDate')}
                    {...form.getInputProps('personalDetails.policyEndDate')}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue('personalDetails.policyEndDate', v);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <TextInput
                    clearable
                    autoComplete='off'
                    maxLength={10}
                    label={t('pages.agentedit.form.tax')}
                    key={form.key('personalDetails.taxDeductionsDate')}
                    {...form.getInputProps('personalDetails.taxDeductionsDate')}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue('personalDetails.taxDeductionsDate', v);
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Section>
          </PageTemplate.Panel>
        </form>

        <PageTemplate.Panel id='contracts'>
          <Section title={t('pages.agentcreate.headers.contracts')}>
            {form.getValues().agreements.map((item, index) => (
              <Card withBorder mt={10} shadow='sm' radius='md' key={index + 1}>
                <Card.Section mb={10} withBorder inheritPadding py='xs'>
                  <Grid align='center'>
                    <Grid.Col span={{ base: 12, md: 11 }}>
                      <Text fw={700}>{`פרטי הסכם מספר ${index + 1}:`}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 1 }}>
                      <ActionIcon
                        mt={15}
                        mb={15}
                        onClick={() => {
                          form.removeListItem('agreements', index);
                        }}
                        color={'red'}
                        variant={'transparent'}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                </Card.Section>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <Select
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.company')}
                      required
                      data={companiesOptions}
                      key={form.key(`agreements.${index}.companyId`)}
                      maxLength={20}
                      {...form.getInputProps(`agreements.${index}.companyId`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <TextInput
                      autoComplete='off'
                      required
                      label={t('pages.agentcreate.form.agreement.agent')}
                      key={form.key(`agreements.${index}.agentNumber`)}
                      maxLength={7}
                      {...form.getInputProps(`agreements.${index}.agentNumber`)}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 6 }}>
                    <MonthPickerInput
                      required
                      clearable
                      maxDate={new Date()}
                      label={t('pages.agentcreate.form.agreement.from')}
                      key={form.key(`agreements.${index}.from`)}
                      {...form.getInputProps(`agreements.${index}.from`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 6 }}>
                    <MonthPickerInput
                      clearable
                      label={t('pages.agentcreate.form.agreement.till')}
                      key={form.key(`agreements.${index}.till`)}
                      {...form.getInputProps(`agreements.${index}.till`)}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <Select
                      autoComplete='off'
                      required
                      label={t('pages.agentcreate.form.agreement.branch')}
                      data={productsOptions}
                      key={form.key(`agreements.${index}.branchId`)}
                      maxLength={20}
                      {...form.getInputProps(`agreements.${index}.branchId`)}
                    />
                  </Grid.Col>
                </Grid>
                <Grid gutter='lg'>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agencyFeeMonthlyDeposit')}
                      key={form.key(`agreements.${index}.agencyFeeMonthlyDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeMonthlyDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agentFeeMonthlyAccumulation')}
                      key={form.key(`agreements.${index}.agentFeeMonthlyAccumulation`)}
                      maxLength={5}
                      min={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeMonthlyAccumulation`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agencyFeeMonthlyAccumulation')}
                      key={form.key(`agreements.${index}.agencyFeeMonthlyAccumulation`)}
                      maxLength={5}
                      min={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeMonthlyAccumulation`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agentFeeMonthlyDeposit')}
                      key={form.key(`agreements.${index}.agentFeeMonthlyDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeMonthlyDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agencyFeeAnnualDeposit')}
                      key={form.key(`agreements.${index}.agencyFeeAnnualDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeAnnualDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agentFeeAnnualDeposit')}
                      key={form.key(`agreements.${index}.agentFeeAnnualDeposit`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeAnnualDeposit`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agencyFeeAnnualAccumulation')}
                      key={form.key(`agreements.${index}.agencyFeeAnnualAccumulation`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agencyFeeAnnualAccumulation`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <NumberInput
                      autoComplete='off'
                      label={t('pages.agentcreate.form.agreement.commision.agentFeeAnnualAccumulation')}
                      key={form.key(`agreements.${index}.agentFeeAnnualAccumulation`)}
                      maxLength={5}
                      min={0}
                      defaultValue={0}
                      {...form.getInputProps(`agreements.${index}.agentFeeAnnualAccumulation`)}
                    />
                  </Grid.Col>
                </Grid>
              </Card>
            ))}
            <Button
              mt={15}
              mb={15}
              onClick={() => {
                form.insertListItem('agreements', {
                  agentNumber: '',
                  from: null,
                  till: null,
                  companyId: null,
                  branchId: null,
                  agencyFeeMonthlyDeposit: '',
                  agencyFeeMonthlyAccumulation: '',
                  agentFeeMonthlyAccumulation: '',
                  agentFeeMonthlyDeposit: '',
                  agentFeeAnnualAccumulation: '',
                  agentFeeAnnualDeposit: '',
                  agencyFeeAnnualDeposit: '',
                  agencyFeeAnnualAccumulation: '',
                });
              }}
            >
              {t('pages.agentcreate.form.agreement.addAgreement')}
            </Button>
          </Section>
        </PageTemplate.Panel>
        <PageTemplate.Panel id='clients'>
          <Section title={t('pages.agentedit.headers.clients')}>
            {form.getValues().entityNumbers.map((item, index) => (
              <Grid align='center' key={index + 1}>
                <Grid.Col span={{ base: 2, md: 6, lg: 3 }}>
                  <TextInput
                    autoComplete='off'
                    maxLength={25}
                    required
                    label={t('pages.agentcreate.form.entityNumbers.hp')}
                    key={form.key(`entityNumbers.${index}.hp`)}
                    {...form.getInputProps(`entityNumbers.${index}.hp`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 2, md: 6, lg: 3 }}>
                  <TextInput
                    autoComplete='off'
                    maxLength={25}
                    required
                    label={t('pages.agentcreate.form.entityNumbers.clientName')}
                    key={form.key(`entityNumbers.${index}.clientName`)}
                    {...form.getInputProps(`entityNumbers.${index}.clientName`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 2, md: 6, lg: 3 }}>
                  <TextInput
                    autoComplete='off'
                    label={t('pages.agentedit.form.entityNumbers.entityNumber')}
                    key={form.key(`entityNumbers.${index}.entityNumber`)}
                    required
                    maxLength={7}
                    {...form.getInputProps(`entityNumbers.${index}.entityNumber`)}
                  />
                </Grid.Col>
                <Grid.Col flex span={{ base: 1, md: 1 }}>
                  <ActionIcon
                    mt={25}
                    onClick={() => {
                      form.removeListItem('entityNumbers', index);
                    }}
                    color={'red'}
                    variant={'transparent'}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            ))}
            <Button
              mt={15}
              mb={15}
              onClick={() => {
                form.insertListItem('entityNumbers', {
                  entityNumber: '',
                  clientName: '',
                  hp: '',
                });
              }}
            >
              {t('pages.agentcreate.form.entityNumbers.addNewEntityNumber')}
            </Button>
          </Section>
        </PageTemplate.Panel>

        <PageTemplate.Panel id='documents'>
          <Section title={t('pages.agentedit.headers.documents')}>
            <Files
              setFiles={setFiles}
              files={files}
              handleReject={handleReject}
              handleDelete={handleDelete}
            />
          </Section>
        </PageTemplate.Panel>
      </PageTemplate>
    </>
  );
};

export default Agentcreate;
