const MINIMUM_PASSWORD_LENGTH = 4;

const REQUIREMENTS = [
  { re: /[0-9]/ }, // numbers
  { re: /[a-z]/ }, // lower case letters
  { re: /[A-Z]/ }, // upper case letters
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/ }, // special chars
];

export {
  MINIMUM_PASSWORD_LENGTH,
  REQUIREMENTS,
};
