import { formatDateToString } from '@/helpers/string';
import { Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

/**
 * transform to time ago text
 * @param {date} d
 * @returns {string}
 */
const timeagoTransform = (d = '') => {
  const formattedDate = formatDateToString(d, true);
  const timeAgo = dayjs(d).fromNow();
  const background = timeAgo.includes('לפני')
    ? 'var(--mantine-color-red-light)'
    : '';

  return (
    <Tooltip label={formattedDate}>
      <span style={{ background }}>{timeAgo}</span>
    </Tooltip>
  );
};

export default timeagoTransform;
