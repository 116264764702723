import { isArray, isNumber, isObject } from '@/helpers/is';

const FILTER_PREFIX = 'filter_';
const CHECKBOX_COLUMN_WIDTH = '50px';
const MAX_ROWS_IN_CALL = 100;

/**
 * get range object data. for example: { start: 1, end: 100 }
 * @param {number} val
 * @returns {object} { start:, end: }
 */
const getRangeDataByNumber = (val = 1) => {
  let index = 1;
  while (val > index * MAX_ROWS_IN_CALL) {
    index += 1;
  }

  const end = index * MAX_ROWS_IN_CALL;
  const start = end - (MAX_ROWS_IN_CALL - 1);

  return [start, end];
};

/**
 * Clean query keys from prefix
 * @param {object} d data
 * @returns {object}
 */
const getCleanQuery = (d = {}) => {
  const cleanQuery = {};
  Object.keys(d).forEach((key) => {
    const cleanKey = String(key).replace(FILTER_PREFIX, '');
    cleanQuery[cleanKey] = d[key];
  });

  return cleanQuery;
};

/**
 * Clean query keys from prefix
 * @param {object} d data
 * @returns {object}
 */
const getDirtyQuery = (d = {}) => {
  const dirtyQuery = {};
  Object.keys(d).forEach((key) => {
    const dirtyKey = `${FILTER_PREFIX}${String(key)}`;
    dirtyQuery[dirtyKey] = d[key];
  });

  return dirtyQuery;
};

/**
 * calculate total pages
 * @param {number} d total items count
 * @param {number} perPage items per page
 * @returns {number}
 */
const calculateTotalPages = (d = 0, perPage = 10) => Math.ceil(d / perPage);

/**
 * get row start & end index, and total number of rows.
 * helper function for pagination text
 * @param {number} p page number
 * @param {number} perPage rows per page
 * @param {number} total total number of rows
 * @returns {array} [start, end, total]
 */
const getPageCountDetails = (p = 1, perPage = 10, total = 0) => {
  const [start, end] = getRowsIndexes(p, perPage);
  const pageEnd = (end > total) ? total : end;
  return [start, pageEnd, total];
};

/**
 * get rows start and end index according to page
 * @param {number} p page number
 * @param {perPage} p rows per page
 * @returns {array} example: for page 2 the result is [11, 20]
 */
const getRowsIndexes = (p = 1, perPage = 10) => {
  const startIndex = ((p - 1) * perPage) + 1;
  const endIndex = p * perPage;

  return [startIndex, endIndex];
};

/**
 * get all elements needed to show on table
 * @param {number} start
 * @param {number} end
 * @param {array} d values
 * @returns {array}
 */
const getRowsData = (start, end, d = []) => {
  if (!isNumber(start) || !isNumber(end)) {
    // eslint-disable-next-line no-console
    console.error('One of the variables (start or end) is not a number. cant get rows data');
    return [];
  }

  const startIndex = (start - 1 >= 0) ? start - 1 : 0;
  return d.slice(startIndex, end);
};

/**
 * validate table data and log error if needed
 * @param {array} data
 * @param {number} count
 * @returns {boolean}
 */
const validateData = (data, count) => {
  if (!isArray(data)) {
    // eslint-disable-next-line no-console
    console.error('Call to "getData" function did not return "data" property as array', data);
    return false;
  }

  if (!isNumber(count)) {
    // eslint-disable-next-line no-console
    console.error('Call to "getData" function did not return "count" property as number', count);
    return false;
  }

  if (data.some((e) => (e.id === undefined && e._id === undefined))) {
    // eslint-disable-next-line no-console
    console.error('table component is missing [id] and [_id] property in one of the data prop array items');
    return false;
  }

  if (data.some((e) => (!isObject(e)))) {
    // eslint-disable-next-line no-console
    console.error('array from "getData" function contain an element that is not object');
    return false;
  }

  return true;
};

export {
  FILTER_PREFIX,
  CHECKBOX_COLUMN_WIDTH,
  getRangeDataByNumber,
  getCleanQuery,
  getDirtyQuery,
  calculateTotalPages,
  getRowsIndexes,
  getRowsData,
  validateData,
  getPageCountDetails,
};
