import he from '@/dictionaries/he';
import { isDateObject } from './is';
import { formatDateToString } from './string';

const DEFAULT_LOCALE = 'he';
const dictionaries = { he };
const getDictionary = (locale = DEFAULT_LOCALE) => dictionaries[locale];

/**
 * get string from dictionary object
 * @param {Array} dictionary
 * @param {string} source
 * @returns {string}
 */
const getString = (dictionary, source) => {
  const keyArr = source.split('.');
  let obj = {};

  keyArr.forEach((key) => {
    if (obj !== undefined && typeof obj !== 'string') {
      obj = (!Object.keys(obj).length) ? dictionary[key] : obj[key];
    }
  });

  const output = (typeof obj === 'string') ? obj : '';
  return output;
};

/**
 * prepare dictionay to work and expose function (t) to find text in dictionay
 * @param {string} locale default to hebrew
 * @returns {string}
 */
const prepareTranslate = (locale = DEFAULT_LOCALE) => {
  const dictionary = getDictionary(locale);

  const t = (source, params) => {
    let output = getString(dictionary, source);

    if (params === undefined) {
      return output;
    }

    for (let i = 0; i < params.length; i += 1) {
      const e = params[i];
      const param = (isDateObject(e)) ? formatDateToString(e, true) : e;
      output = output.replace(new RegExp(`\\{${i + 1}\\}`, 'g'), param);
    }

    return output;
  };

  return t;
};

export default prepareTranslate;
