import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_SMS = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'sms sent',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'sms failed to send',
      success: false,
    },
    status: 401,
  },
};

const RESULT_EMAIL = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'email sent',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'email failed to send',
      success: false,
    },
    status: 401,
  },
};

const smsHandler = async () => {
  const { response, status } = getResponseData(RESULT_SMS);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const emailHandler = async () => {
  const { response, status } = getResponseData(RESULT_EMAIL);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  smsHandler,
  emailHandler,
};
