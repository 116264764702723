import prepareTranslate from '@/helpers/dictionary';
import {
  isDateddmmyyyy,
  isEmail,
  isEmpty,
  isIdValid,
  isNumber,
  isPhone,
} from './is';

const t = prepareTranslate();

const validations = {
  required: (value) => ((isEmpty(value)) ? t('validations.required') : null),
  email: (value) => ((!isEmail(value)) ? t('validations.email') : null),
  number: (value) => ((!isNumber(value)) ? t('validations.number') : null),
  phone: (value) => ((!isPhone(value)) ? t('validations.phone') : null),
  uid: (value) => ((isIdValid(value)) ? null : t('validations.uid')),
  date: (value) => ((isDateddmmyyyy(value)) ? null : t('validations.date')),
  agent: (pickedAgent, isAllAgents) => {
    if (isAllAgents) {
      return null;
    }
    return pickedAgent ? null : t('validations.autocomplete');
  },
  company: (pickedCompany, isAllACompanies) => {
    if (isAllACompanies) {
      return null;
    }
    return isEmpty(pickedCompany) ? t('validations.required') : null;
  },
  product: (pickedProduct, isAllProducts) => {
    if (isAllProducts) {
      return null;
    }
    return isEmpty(pickedProduct) ? t('validations.required') : null;
  },
};

export default validations;
