import routes from '@/routes';
import prepareTranslate from './dictionary';

const t = prepareTranslate();

// TODO: change text to dictionary
const MESSAGE_TYPES = Object.freeze([
  { value: routes.agentView.path, label: 'סוכן' },
  { value: routes.userView.path, label: 'משתמש' },
]);

const ROLE_LIST = Object.freeze([
  { value: 'superadmin', label: 'superadmin' },
  { value: 'administrator', label: 'administrator' },
]);

const USER_STATUS = Object.freeze([
  { value: 'active', label: t('pages.useredit.status.active') },
  { value: 'archived', label: t('pages.useredit.status.archive') },
]);

const AGENT_STATUS = Object.freeze([
  { value: 'active', label: t('pages.agentedit.status.active') },
  { value: 'archive', label: t('pages.agentedit.status.archive') },
]);

const YES_NO = Object.freeze([
  { value: 'yes', label: t('global.yesNo.yes') },
  { value: 'no', label: t('global.yesNo.no') },
]);

const AGENT_TYPE = Object.freeze([
  { value: 'CTO', label: t('pages.agentedit.agentType.CTO') },
  { value: 'houseAgent', label: t('pages.agentedit.agentType.houseAgent') },
  { value: 'integratedAgent', label: t('pages.agentedit.agentType.integratedAgent') },
  { value: 'foreignAgent', label: t('pages.agentedit.agentType.foreignAgent') },
]);

const EMPLOYEE_TYPE = Object.freeze([
  { value: 'employee', label: t('pages.agentedit.employeeType.employee') },
  { value: 'selfEmployee', label: t('pages.agentedit.employeeType.selfEmployee') },
]);

const GENDER = Object.freeze([
  { value: 'male', label: t('pages.agentcreate.form.male') },
  { value: 'female', label: t('pages.agentcreate.form.female') },
]);

const FAMILY_STATUS = Object.freeze([
  { value: 'single', label: t('global.family.single') },
  { value: 'married', label: t('global.family.married') },
  { value: 'divorced', label: t('global.family.divorced') },
  { value: 'widowed', label: t('global.family.widowed') },
]);

const COLORS = Object.freeze([
  'red',
  'grape',
  'violet',
  'indigo',
  'cyan',
  'teal',
  'lime',
  'yellow',
]);

const FORM_ERRORS = Object.freeze({
  required: 'שדה חובה',
  fileTitleInvalid: 'שם הקובץ שנבחר אינו תואם את שמות הקבצים הנדרשים',
});

const FILE_ERRORS = Object.freeze({
  'file-invalid-type': 'סוג המסמך לא תואם את הסוג הנדרש',
  'file-too-large': 'המסמך גדול מדי',
});

const DIRECTIONS = Object.freeze({
  asc: 'asc',
  desc: 'desc',
});

const CHART_TYPES = Object.freeze({
  line: 'Line',
  bar: 'Bar',
});

const SSE_TYPES = Object.freeze({
  process: 'process',
});

export {
  MESSAGE_TYPES,
  AGENT_STATUS,
  FAMILY_STATUS,
  FORM_ERRORS,
  FILE_ERRORS,
  ROLE_LIST,
  AGENT_TYPE,
  GENDER,
  USER_STATUS,
  DIRECTIONS,
  COLORS,
  EMPLOYEE_TYPE,
  YES_NO,
  CHART_TYPES,
  SSE_TYPES,
};
