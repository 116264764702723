import { useMutation } from '@tanstack/react-query';
import { findById } from '../requests/product';

const KEY = 'product';

const useFindById = () => useMutation({
  mutationKey: [KEY],
  mutationFn: (data) => findById(data),
});

const productMutation = {
  useFindById,
};

export default productMutation;
