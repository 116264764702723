import vars from '@/config/vars';

export default {
  // eslint-disable-next-line consistent-return
  start: () => {
    if (vars.console) {
      return null;
    }

    const noop = () => {};
    console.log = noop; // eslint-disable-line
    console.info = noop; // eslint-disable-line
    console.warn = noop; // eslint-disable-line
    console.error = noop; // eslint-disable-line
    console.debug = noop; // eslint-disable-line
    console.count = noop; // eslint-disable-line
    console.assert = noop; // eslint-disable-line
    console.dir = noop; // eslint-disable-line
    console.dirxml = noop; // eslint-disable-line
    console.table = noop; // eslint-disable-line
    console.trace = noop; // eslint-disable-line
  },
};
