export default {
  empty: {
    title: 'שליחת הודעות',
    body: 'לא קיימים פרטי טלפון ודואר אלקטרוני ולכן לא ניתן לשלוח הודעה באמצעות הממשק',
  },
  headers: {
    variant: 'בחירת סוג ההודעה',
    email: 'שליחת הודעה בדואר אלקטרוני',
    phone: 'שליחת הודעה בטלפון',
  },
  form: {
    message: 'תוכן ההודעה:',
    action: 'שלח הודעה',
    subject: 'כותרת המייל:',
  },
  notification: {
    title: 'ההודעה נשלחה בהצלחה',
    message: 'הפרטים עודכנו',
    errortitle: 'שגיאה',
    error: 'לא בוצעה שליחת הודעה, אנא בדוק את הפרטים שהוזנו',
  },
  validations: {
    required: 'יש להזין טקסט',
    invalidCharacters: 'הוזנו תווים לא חוקיים',
  },
};
