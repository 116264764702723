import PropTypes from 'prop-types';
import { Button, Menu } from '@mantine/core';
import { IconDownload, IconFileTypePdf, IconFileTypeXls } from '@tabler/icons-react';
import prepareTranslate from '@/helpers/dictionary';

const TYPES = {
  pdf: {
    title: 'Pdf',
    icon: <IconFileTypePdf />,
  },
  excel: {
    title: 'Excel',
    icon: <IconFileTypeXls />,
  },
};

const Export = (props) => {
  const {
    options = ['pdf', 'excel'],
    onExportSubmit = () => {},
  } = props;

  const t = prepareTranslate();

  const clickOnFile = (e) => {
    const { currentTarget } = e;
    const { option } = currentTarget.dataset;

    onExportSubmit(option);
  };

  const items = options.map((option) => {
    const item = TYPES[option];

    if (!item) {
      return null;
    }

    const {
      title,
      icon,
    } = item;

    return (
      <Menu.Item
        key={option}
        data-option={option}
        onClick={clickOnFile}
        leftSection={icon}
      >
        {title}
      </Menu.Item>
    );
  });

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button
          color="gray"
          size="xs"
          variant="outline"
          leftSection={<IconDownload stroke="1" />}
        >
          {t('components.export.action')}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{t('components.export.label')}</Menu.Label>
        {items}
      </Menu.Dropdown>
    </Menu>
  );
};

Export.propTypes = {
  options: PropTypes.array,
  onExportSubmit: PropTypes.func,
};

export default Export;
