/**
 * check if variable is empty object
 * @param {mixed} obj
 * @returns {boolean}
 */
const isObjectEmpty = (obj = {}) => {
  try {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } catch (error) {
    return false;
  }
};

/**
 * check if variable is empty object
 * @param {mixed} obj
 * @returns {boolean}
 */
const isIdValid = (id) => {
  let idTrim = String(id).trim();
  if (idTrim.length > 9 || isNaN(idTrim)) return false; // eslint-disable-line
  idTrim = idTrim.length < 9 ? ('00000000' + idTrim).slice(-9) : idTrim; // eslint-disable-line
  return Array.from(idTrim, Number).reduce((counter, digit, i) => {
    const step = digit * ((i % 2) + 1);
    return counter + (step > 9 ? step - 9 : step);
  }) % 10 === 0;
};

/**
 * check if variable is array
 * @param {mixed} val
 * @returns {boolean}
 */
const isArray = (val) => Array.isArray(val);

/**
 * check if variable is string
 * @param {mixed} val
 * @returns {boolean}
 */
const isString = (val) => (typeof val === 'string' || val instanceof String);

/**
 * check if variable is boolean
 * @param {mixed} val
 * @returns {boolean}
 */
const isBoolean = (val) => (typeof val === 'boolean');

/**
 * check if variable is object
 * @param {mixed} val
 * @returns {boolean}
 */
const isObject = (val) => Object.prototype.toString.call(val) === '[object Object]';

/**
 * check if variable is phone
 * @param {mixed} val
 * @returns {boolean}
 */
const isPhone = (val) => /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/gm.test(val);

/**
 * check if variable is email
 * @param {mixed} val
 * @returns {boolean}
 */
const isEmail = (val) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(val);

/**
 * check if variable is email
 * @param {mixed} val
 * @returns {boolean}
 */
const isPassword = (val) => val.length >= 8 && /[A-Z]/.test(val) && /[0-9]/.test(val) && /[!@#$%^&*(),.?":{}|<>]/.test(val);

/**
 * check if variable is date object
 * @param {mixed} val
 * @returns {boolean}
 */
const isDateObject = (val) => (val instanceof Date);

/**
 * check if variable is date object
 * @param {mixed} val
 * @returns {boolean}
 */
const isDateddmmyyyy = (val) => (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(?!00)\d{4}$/).test(val);

/**
 * check if variable is empty
 * @param {mixed} val
 * @returns {boolean}
 */
const isEmpty = (val) => {
  if (isString(val) && String(val).length) { return false; }
  if (isBoolean(val) && val) { return false; }
  if (isArray(val) && val.length) { return false; }
  // if (!isObjectEmpty(val)) { return false; }
  if (val && String(val).length > 0) { return false; }

  return true;
};

/**
 * check if variable is function
 * @param {mixed} val
 * @returns {boolean}
 */
const isFunction = (val) => (Object.prototype.toString.call(val) === '[object Function]' || Object.prototype.toString.call(val) === '[object AsyncFunction]');

/**
 * check if variable is number
 * @param {mixed} val
 * @returns {boolean}
 */
const isNumber = (val) => typeof val === 'number' && Number.isFinite(val);

/**
 * check if variable is valid string date
 * @param {mixed} val
 * @returns {boolean}
 */
// eslint-disable-next-line no-restricted-globals
const isDateString = (val) => isString(val) && !isNaN(new Date(val));

export {
  isObjectEmpty,
  isArray,
  isString,
  isBoolean,
  isObject,
  isPhone,
  isEmail,
  isPassword,
  isDateObject,
  isDateddmmyyyy,
  isEmpty,
  isFunction,
  isIdValid,
  isNumber,
  isDateString,
};
