import { Outlet } from 'react-router-dom';
import styles from './style.module.css';

const EmptyLayout = () => (
  <div className={styles.layout}>
    <Outlet />
  </div>
);

export default EmptyLayout;
