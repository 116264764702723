import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_GET_ALL = {
  default: {
    response: {
      createdAt: 1720348393535,
      error: {
        code: 0,
        error: '',
      },
      object: 'getall',
      success: true,
      data: [
        {
          fileTypes: [],
          _id: '6609110becb461e59d78a4b5',
          name: 'Menora',
          hebrew: 'מנורה',
        },
        {
          fileTypes: [],
          _id: '65fc141fc676340ed68694f6',
          name: 'Altshuler Shaham',
          hebrew: 'אלטשולר שחמ',
        },
        {
          fileTypes: [],
          _id: '66045d53e376171abbb826a3',
          name: 'Harel',
          hebrew: 'הראל',
        },
        {
          _id: '66045dcfe376171abbb826a7',
          name: 'Phoenix',
          fileTypes: [
            'agents',
            'agencies',
          ],
          hebrew: 'פניקס',
        },
        {
          _id: '66045d75e376171abbb826a5',
          name: 'Meitav',
          fileTypes: [
            'נפרעי פנסיה',
          ],
          hebrew: 'מיטב',
        },
        {
          fileTypes: [],
          _id: '666fe2a48b5154bf71df852e',
          name: 'Hachshara',
          hebrew: 'הכשרה',
        },
      ],
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720349117033,
      error: {
        collection: 'companies',
        code: 501,
        error: 'No documnets were found',
        message: 'אירעה שגיאה',
      },
      object: 'getall',
      success: false,
    },
    status: 500,
  },
};

const getAllHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ALL);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  getAllHandler,
};
