import axiosRequest from '.';

const importData = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/import',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
  })
);

const exportTableData = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/export/table',
    method: 'POST',
  })
);

const exportChartData = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/export/chart',
    method: 'POST',
  })
);

export {
  importData,
  exportTableData,
  exportChartData,
};
