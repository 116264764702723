import PropTypes from 'prop-types';
import { Group, Title } from '@mantine/core';
import styles from './style.module.css';

const SectionActions = (props) => {
  const {
    title,
    actions = [],
  } = props;

  const content = (actions.length)
    ? actions.map((action) => action)
    : null;

  return (
    <section className={styles.side}>
      {title && <header><Title order={4}>{title}</Title></header>}
      {content && <Group>{content}</Group>}
    </section>
  );
};

SectionActions.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
};

export default SectionActions;
