export default {
  labels: {
    options: 'אפשרויות',
    actions: 'פעולות',
  },
  items: {
    users: 'ניהול משתמשים',
    messages: 'הודעות',
    logout: 'התנתק מהמערכת',
  },
};
