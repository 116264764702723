import prepareTranslate from '@/helpers/dictionary';
import PageHead from '@/components/PageHead';
import routes from '@/routes';
import useAccessToken from '@/hooks/useAccessToken';
import PageTemplate from '@/components/PageTemplate';
import { IconClipboardData } from '@tabler/icons-react';
import Section from '@/components/Section';
import { MonthPickerInput } from '@mantine/dates';
import { objectToQueryString } from '@/helpers/string';
import { useForm } from '@mantine/form';
import validations from '@/helpers/validation';
import { useState } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { routeWithParams } from '@/helpers/route';
import exportRuleQuery from '@/api/queries/exportRule';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import agentMutations from '@/api/mutitations/agent';
import { notifications } from '@mantine/notifications';
import { createArraysFromRange } from '@/helpers/dates';
import {
  Badge,
  Button,
  Grid,
  Group,
  MultiSelect,
  Paper,
  Select,
  Stack,
  Text,
  Loader,
  Autocomplete,
  Switch,
  Container,
} from '@mantine/core';
import { isArray } from '@/helpers/is';

const Reports = () => {
  useAccessToken();

  const { data: exportRuleData } = exportRuleQuery.useGetAll();
  const { data: companyData } = companyQuery.useGetAll();
  const { data: productData } = productQuery.useGetAll();
  const { mutateAsync: agentMutate } = agentMutations.useGetAgents();
  const navigate = useNavigate();

  const t = prepareTranslate();
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [data, setData] = useState([]);
  const [expectedColumns, setExpectedColumns] = useState([]);
  const [lastReport, setLastReport] = useState('');
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [pickedAgent, setPickedAgent] = useState();
  const [disabled, setDisabled] = useState({
    agent: false,
    company: false,
    product: false,
  });
  const [isAll, setIsAll] = useState({
    agent: false,
    company: false,
    product: false,
  });

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      report: null,
      company: [],
      product: [],
      period: [],
    },
    validate: {
      report: (value) => validations.required(value),
      agent: () => validations.agent(pickedAgent, isAll.agent),
      company: (value) => validations.company(value, isAll.company),
      product: (value) => validations.product(value, isAll.product),
      period: (value) => validations.required(value),
    },
  });

  /*
   * TODO: activate favorites when finish developing
  const tabs = [
    {
      title: t('pages.reports.side.favorites'),
      content: <Favorites entity="report" />,
      isDefault: true,
    },
  ];
  */

  const handleFormSubmit = () => {
    const { hasErrors, errors } = form.validate();

    if (hasErrors) {
      const firstErrorPath = Object.keys(errors)[0];
      form.getInputNode(firstErrorPath)?.focus();
      return;
    }

    let path;
    const values = form.getValues();
    const companyArray = isArray(values.company) ? values.company : [values.company];

    const [yearArray, monthArray] = createArraysFromRange(...values.period);
    const queryData = {
      month: monthArray,
      year: yearArray,
      company: companyArray,
      product: values.product,
    };

    if (!isAll.agent) {
      queryData.agent = [pickedAgent];
    } else {
      queryData.agent = [];
    }

    const query = objectToQueryString(queryData);
    const reportId = values.report;

    if (queryData.agent.length > 1) {
      path = routes.reportList.path;
    } else {
      path = routes.reportsView.path;
    }

    navigate(routeWithParams(path, { id: reportId, query }));
  };

  const handleOptionSubmit = (val) => {
    setPickedAgent(val);
  };

  const handleReportSelect = (value) => {
    if (value === lastReport) {
      setExpectedColumns([]);
      setLastReport('');
    } else {
      setExpectedColumns(exportRuleData.find((exportRule) => exportRule._id === value).columns);
      setLastReport(value);
    }
  };

  const handleAgentsSwitchChange = (e) => {
    const { target = {} } = e;
    const { checked = false } = target;

    setIsAll({
      ...isAll,
      agent: checked,
    });

    setDisabled({
      ...isAll,
      agent: checked,
    });

    if (checked) {
      setLoading(false);
    }
  };

  const handleCompaniesSwitchChange = (e) => {
    const { target = {} } = e;
    const { checked = false } = target;

    setIsAll({
      ...isAll,
      company: checked,
    });

    setDisabled({
      ...isAll,
      company: checked,
    });

    if (checked) {
      form.setValues({ company: [] });
    }
  };

  const handleProductsSwitchChange = (e) => {
    const { target = {} } = e;
    const { checked = false } = target;

    setIsAll({
      ...isAll,
      product: checked,
    });

    setDisabled({
      ...isAll,
      product: checked,
    });

    if (checked) {
      form.setValues({ product: [] });
    }
  };

  const handleAutocompleteChange = (val) => {
    if (pickedAgent) {
      setAutoCompleteValue('');
      setData([]);
      setPickedAgent(null);
    } else {
      const hasAgent = data.find((agent) => agent.label === val);
      setAutoCompleteValue(val);

      if (!hasAgent) {
        autoCompleteQuery(val);
      } else {
        setData([]);
      }
    }
  };

  const autoCompleteQuery = useDebouncedCallback(async (val) => {
    setLoading(true);
    setEmpty(false);

    if (val === '') {
      setData([]);
    } else {
      const start = 1;
      const end = 100;
      const query = { 'personalDetails.fullName': val };

      try {
        const { data: agentData } = await agentMutate({ query, start, end });

        if (agentData.length === 0) {
          setData([]);
          setEmpty(true);
        } else {
          setData(agentData.map((agent) => ({ label: `${agent.personalDetails.fullName} (${agent.personalDetails.uid})`, value: agent._id })));
        }
      } catch {
        notifications.show({
          title: t('pages.reports.notification.error.title'),
          message: t('pages.reports.notification.error.message'),
          color: 'red',
        });
      }
    }

    setLoading(false);
  }, 500);

  const actions = [
    <Button key="primary" leftSection={<IconClipboardData />} onClick={handleFormSubmit}>{t('pages.reports.action')}</Button>,
  ];

  const exportRuleSelect = exportRuleData && exportRuleData
    .map((exportRule) => ({ label: exportRule.ruleName, value: exportRule._id }));
  const companySelect = companyData && companyData
    .map((company) => ({ label: company.hebrew, value: company._id }));
  const productSelect = productData && productData
    .map((product) => ({ label: product.hebrew, value: product._id }));
  const displayExpectedColumns = expectedColumns.length !== 0;

  return (
    <>
      <PageHead
        title={routes.reports.name}
        subtitle={t('pages.reports.subtitle')}
      />

      <PageTemplate
        actions={actions}
        tabsTitle={t('pages.reports.side.tabs')}
      >
        <PageTemplate.Panel id="form">
          <form>
            <Section title={t('pages.reports.headers.report')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12 }}>
                  <Select
                    label={t('pages.reports.form.report')}
                    data={exportRuleSelect}
                    required
                    onOptionSubmit={(option) => handleReportSelect(option)}
                    key={form.key('report')}
                    {...form.getInputProps('report')}
                  />
                </Grid.Col>
                {(displayExpectedColumns) && ( // TODO: show this only if we have report columns
                  <Grid.Col span={{ base: 12 }}>
                    <Paper shadow="xs" p="md">
                      <Stack gap="xs">
                        <Text fz="xs" fw={700} c="dimmed">{t('pages.reports.info.title')}</Text>
                        <Group gap="xs">
                          {
                            expectedColumns.map(({ title }, index) => <Badge key={`expectedColumn${index}`} variant="light"color="blue">{ title }</Badge>)
                          }
                        </Group>
                      </Stack>
                    </Paper>
                  </Grid.Col>
                )}
              </Grid>
            </Section>

            <Section title={t('pages.reports.headers.properties')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Stack>
                    <Switch
                      label={t('pages.reports.labels.companies')}
                      size="xs"
                      onChange={handleCompaniesSwitchChange}
                    />
                    <Select
                       label={t('pages.reports.form.company')}
                       data={companySelect}
                       disabled={disabled.company}
                       required
                       key={form.key('company')}
                       {...form.getInputProps('company')}
                    />
                  </Stack>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Stack>
                    <Switch
                      label={t('pages.reports.labels.products')}
                      size="xs"
                      onChange={handleProductsSwitchChange}
                    />
                    <MultiSelect
                      key={form.key('product')}
                      label={t('pages.reports.form.product')}
                      placeholder={t('pages.reports.placeHolders.products')}
                      data={productSelect}
                      disabled={disabled.product}
                      required
                      maxValues={3}
                      {...form.getInputProps('product')}
                    />
                  </Stack>
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <MonthPickerInput
                    type="range"
                    label={t('pages.reports.form.daterange')}
                    required
                    key={form.key('period')}
                    {...form.getInputProps('period')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section
              title={t('pages.reports.headers.agent')}
              suffix={<Switch
                label={t('pages.reports.labels.agents')}
                size="xs"
                onChange={handleAgentsSwitchChange}
              />}
            >
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Autocomplete
                    label={t('pages.reports.form.agent')}
                    rightSection={loading ? <Loader size="1rem" /> : null}
                    disabled={disabled.agent}
                    data={data}
                    required={!isAll.agent}
                    limit="10"
                    key={form.key('agent')}
                    {...form.getInputProps('agent')}
                    value={autoCompleteValue}
                    onChange={handleAutocompleteChange}
                    onOptionSubmit={handleOptionSubmit}
                  />
                  {
                    empty && (
                      <Container fluid mt='xs' bg="var(--mantine-color-blue-light)">
                        <Text>{t('pages.reports.noAgents')}</Text>
                      </Container>
                    )
                  }
                </Grid.Col>
              </Grid>
            </Section>
          </form>
        </PageTemplate.Panel>
      </PageTemplate>
    </>
  );
};

export default Reports;
