import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHead from '@/components/PageHead';
import prepareTranslate from '@/helpers/dictionary';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import {
  Badge,
  Button,
  Loader,
  Table,
} from '@mantine/core';
import Comments from '@/components/Comments';
import Files from '@/components/Files';
import routes from '@/routes';
import { IconEdit } from '@tabler/icons-react';
import { routeWithParams } from '@/helpers/route';
import AgentAgreements from '@/components/AgentAgreements';
import PageTemplate from '@/components/PageTemplate';
import SectionView from '@/components/SectionView';
import Section from '@/components/Section';
import agentMutations from '@/api/mutitations/agent';
import uploadMutations from '@/api/mutitations/upload';
import {
  FAMILY_STATUS,
  formatDateToString,
  GENDER,
  AGENT_TYPE,
  formatDDMMYYYYToString,
} from '@/helpers/string';

const Agentview = () => {
  const [agent, setAgent] = useState({
    personalDetails: {
      dob: null,
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [loading, { open, close }] = useDisclosure(false);
  const [files, setFiles] = useState([]);

  const isActive = true;

  const t = prepareTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const { useGetOne } = agentMutations;
  const { useUploadsById, useDownload } = uploadMutations;
  const getOne = useGetOne();
  const uploadsById = useUploadsById();
  const downloadById = useDownload();

  const statusTitle = isActive
    ? t('global.status.active')
    : t('global.status.inactive');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await getOne.mutateAsync({ id });
        const fileList = await uploadsById.mutateAsync({ id });
        setAgent(result);
        setFiles(fileList);
      } catch (error) {
        navigate(routes.agents.path);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!agent) {
    return <Loader />;
  }

  const handleDownload = async (fileId) => {
    try {
      open();
      notifications.show({
        title: t('pages.agentedit.notification.download.title'),
        message: t('pages.agentedit.notification.download.message'),
      });
      const response = await downloadById.mutateAsync({ fileId });
      const { file, contentType, fileName } = response;

      // Decode base64 string to binary
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      // Trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      close();
    } catch (error) {
      open();
      notifications.show({
        title: t('pages.agentedit.notification.downloadFaild.title'),
        message: t('pages.agentedit.notification.downloadFaild.message'),
      });
      close();
    }
  };

  // TODO: move to agentview functions
  const menuGroups = [
    {
      links: [
        {
          id: 'details',
          title: t('pages.agentview.menu.details'),
        },
        {
          id: 'contracts',
          title: t('pages.agentview.menu.contracts', [
            agent.agreements ? `(${agent.agreements.length})` : '',
          ]),
        },
        {
          id: 'documents',
          title: t('pages.agentview.menu.documents', [
            files ? `(${files.length})` : '',
          ]),
        },
        {
          id: 'clients',
          title: t('pages.agentview.menu.clients', [
            agent.entityNumbers ? `(${agent.entityNumbers.length})` : '',
          ]),
        },
      ],
    },
  ];

  // TODO: move to agentview functions
  const tabs = [
    {
      title: t('pages.agentview.side.comments'),
      content: <Comments type='agent' id={id} showForm />,
      isDefault: true,
    },
  ];

  // TODO: move to agentview functions
  const detailsLines = [
    {
      title: t('pages.agentview.labels.fullname'),
      value: agent.personalDetails.fullName,
    },
    {
      title: t('pages.agentview.labels.uid'),
      value: agent.personalDetails.uid,
    },
    {
      title: t('pages.agentview.labels.gender'),
      value: GENDER[agent.personalDetails.gender] || '',
    },
    {
      title: t('pages.agentview.labels.dob'),
      value: agent.personalDetails.dob || null,
    },
  ];

  // TODO: move to agentview functions
  const familyLines = [
    {
      title: t('pages.agentview.labels.familyStatus'),
      value: FAMILY_STATUS[agent.personalDetails.familyStatus] || '',
    },
    {
      title: t('pages.agentview.labels.numberOfChildren'),
      value: agent.personalDetails.childrens?.length || '',
    },
    {
      title: t('pages.agentview.labels.spouse'),
      value: agent.personalDetails.spouse,
    },
  ];

  // TODO: move to agentview functions
  const contactLines = [
    {
      title: t('pages.agentview.labels.email'),
      value: agent.personalDetails.email,
    },
    {
      title: t('pages.agentview.labels.cellphone'),
      value: agent.personalDetails.phone,
    },
    {
      title: t('pages.agentview.labels.extracellphone'),
      value: agent.personalDetails.extraPhone,
    },
    {
      title: t('pages.agentview.labels.website'),
      value: agent.personalDetails.website,
    },
  ];
  // TODO: move to agentview functions
  const homeLines = [
    {
      title: t('pages.agentview.labels.homeaddress'),
      value: agent.personalDetails.homeFullAddress,
    },
    {
      title: t('pages.agentview.labels.homephone'),
      value: agent.personalDetails.homePhone,
    },
  ];

  // TODO: move to agentview functions
  const officeLines = [
    {
      title: t('pages.agentview.labels.officeaddress'),
      value: agent.personalDetails.officeFullAddress,
    },
    {
      title: t('pages.agentview.labels.officephone'),
      value: agent.personalDetails.officePhone,
    },
  ];

  // TODO: move to agentview functions
  const datesLines = [
    {
      title: t('pages.agentview.labels.policyend'),
      value: (
        <span>{formatDDMMYYYYToString(agent.personalDetails.policyEndDate)}</span>
      ),
    },
    {
      title: t('pages.agentview.labels.tax'),
      value: (
          <span>
          {formatDDMMYYYYToString(agent.personalDetails.taxDeductionsDate)}
          </span>
      ),
    },
  ];

  const handleUpdateClick = () => {
    navigate(routeWithParams(routes.agentEdit.path, { id }));
  };

  return (
    <>
      <PageHead
        title={
          <>
            {t('pages.agentview.title', [agent.personalDetails.fullName || ''])}
            <Badge color='blue'>{statusTitle}</Badge>
            {agent.agentType && <Badge color='blue'>{AGENT_TYPE[agent.agentType]}</Badge>}
          </>
        }
        subtitle={t('pages.agentview.subtitle', [
          formatDateToString(agent.updatedAt),
        ])}
        actions={
          <Button onClick={handleUpdateClick} leftSection={<IconEdit />}>
            {t('pages.agentview.action')}
          </Button>
        }
      />

      <PageTemplate
        menuGroups={menuGroups}
        tabs={tabs}
        tabsTitle={t('pages.agentview.side.tabs')}
      >
        <PageTemplate.Panel id='details'>
          <SectionView
            title={t('pages.agentview.headers.private')}
            lines={detailsLines}
          />
          <SectionView
            title={t('pages.agentview.headers.family')}
            lines={familyLines}
          />
          <SectionView
            title={t('pages.agentview.headers.contact')}
            lines={contactLines}
          />
          <SectionView
            title={t('pages.agentview.headers.home')}
            lines={homeLines}
          />
          <SectionView
            title={t('pages.agentview.headers.office')}
            lines={officeLines}
          />
          <SectionView
            title={t('pages.agentview.headers.dates')}
            lines={datesLines}
          />
        </PageTemplate.Panel>

        <PageTemplate.Panel id='contracts'>
          <Section title={t('pages.agentview.headers.contracts')}>
            <AgentAgreements data={agent.agreements} id={id} />
          </Section>
        </PageTemplate.Panel>

        <PageTemplate.Panel id='documents'>
          <Section title={t('pages.agentview.headers.documents')}>
            <Files
            type='agent'
            id={id}
            showForm={false}
            showActions={false}
            files={files}
            handleDownload={handleDownload}
            />
          </Section>
        </PageTemplate.Panel>

        <PageTemplate.Panel id='clients'>
          <Section title={t('pages.agentview.headers.entityNumbers')}>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{t('pages.agentview.labels.hp')}</Table.Th>
                  <Table.Th>{t('pages.agentview.labels.clientName')}</Table.Th>
                  <Table.Th>{t('pages.agentview.labels.entityNumber')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {agent.entityNumbers?.map((item, index) => (
                  <Table.Tr key={index + 1}>
                    <Table.Td key={index + 1}>{item.hp}</Table.Td>
                    <Table.Td key={index + 1}>{item.clientName}</Table.Td>
                    <Table.Td key={index + 1}>{item.entityNumber}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Section>
        </PageTemplate.Panel>
      </PageTemplate>
    </>
  );
};

export default Agentview;
