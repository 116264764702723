import clsx from 'clsx';
import { Container } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const PageHead = (props) => {
  const {
    title,
    subtitle,
    actions,
    sticky = true,
  } = props;

  const classes = clsx(sticky && styles.sticky);

  return (
    <Container mb="xl" fluid className={classes}>
      <div className={styles.pagehead}>
          <header className={styles.info}>
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
          </header>

          {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </Container>
  );
};

PageHead.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.string,
  actions: PropTypes.any,
  sticky: PropTypes.bool,
};

export default PageHead;
