import { useMutation } from '@tanstack/react-query';
import {
  upload,
  getUploadsById,
  download,
  getAllUploads,
  deleteUpload,
} from '../requests/upload';

const KEY = 'upload';

const useUpload = () => useMutation({
  mutationKey: [KEY, 'upload'],
  mutationFn: (data) => upload(data),
});

const useUploadsById = () => useMutation({
  mutationKey: [KEY, 'getOne'],
  mutationFn: (data) => getUploadsById(data),
});

const useAllUploads = () => useMutation({
  mutationKey: [KEY, 'getOne'],
  mutationFn: (data) => getAllUploads(data),
});

const useDownload = () => useMutation({
  mutationKey: [KEY, 'download'],
  mutationFn: (data) => download(data),
});

const useDeleteUpload = () => useMutation({
  mutationKey: [KEY, 'download'],
  mutationFn: (data) => deleteUpload(data),
});

const uploadMutations = {
  useUpload,
  useUploadsById,
  useDownload,
  useAllUploads,
  useDeleteUpload,
};

export default uploadMutations;
