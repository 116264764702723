import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

const restrictTags = Extension.create({
  name: 'restrictTags',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('restrictTags'),
        props: {
          handleKeyDown: (view, event) => {
            if (event.key === '<' || event.key === '>') {
              return true; // Prevent the default behavior
            }
            return false;
          },
          handlePaste: (view, event) => {
            const text = event.clipboardData.getData('text/plain');
            const sanitizedText = text.replace(/[<>]/g, '');
            view.dispatch(view.state.tr.insertText(sanitizedText));
            return true;
          },
        },
      }),
    ];
  },
});

export default restrictTags;
