import PropTypes from 'prop-types';
import { IconTrash, IconDownload } from '@tabler/icons-react';
import {
  Dropzone,
  MIME_TYPES,
} from '@mantine/dropzone';
import {
  ActionIcon,
  Group,
  Table,
  Text,
} from '@mantine/core';
import prepareTranslate from '@/helpers/dictionary';
import { formatDateToString, truncateString } from '@/helpers/string';
import styles from './style.module.css';
import UserIcon from '../UserIcon';

// TODO: remove eslint disable line

const MAX_FILE_SIZE = 5000000;

const Files = (props) => {
  const {
    handleDelete = () => { },
    handleReject = () => { },
    handleDownload = () => { },
    showForm = true,
    showActions = true,
    files,
    setFiles,
  } = props;

  const t = prepareTranslate();

  const handleOnAccept = (uploadFiles) => {  // eslint-disable-line
    setFiles([...files, ...uploadFiles]);
  };

  const handleOnReject = () => {  // eslint-disable-line
    handleReject();
  };

  const rows = files?.map((item, index) => {
    const {
      _id,
      fileName,
      fileType = '',
      createdAt,
      uploadedBy,
      name,
    } = item;

    const formattedFilename = truncateString(fileName || name, 20, 20);
    const today = new Date();

    return (
      <Table.Tr key={_id || index + 1}>
        <Table.Td>{formattedFilename}</Table.Td>
        <Table.Td>{fileType}</Table.Td>
        <Table.Td>{formatDateToString(createdAt) || formatDateToString(today)}</Table.Td>
        <Table.Td>
          <UserIcon
            userId={uploadedBy}
            isMenuActive={true}
            showOnlyDetails={true}
          />
        </Table.Td>
        <Table.Td>
          {showActions && (
            <ActionIcon
              mb={10}
              ml={10}
              color={'red'}
              variant={'transparent'}
              onClick={() => {
                handleDelete(fileName || name);
              }}
            >
              <IconTrash />
            </ActionIcon>
          )}
          {_id && (<ActionIcon
            mb={10}
            variant={'transparent'}
            onClick={() => {
              handleDownload(_id);
            }}
          >
            <IconDownload />
          </ActionIcon>
          )}
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <div className={styles.wrapper}>
      {showForm && (
        <div className={styles.dropzone}>
          <Dropzone
            loading={false}
            onDrop={handleOnAccept}
            onReject={handleOnReject}
            maxSize={MAX_FILE_SIZE}
            className={styles.dropzone}
            accept={[
              MIME_TYPES.png,
              MIME_TYPES.jpeg,
              MIME_TYPES.svg,
              MIME_TYPES.gif,
              MIME_TYPES.pdf,
              MIME_TYPES.pptx,
              MIME_TYPES.ppt,
              MIME_TYPES.xlsx,
              MIME_TYPES.xls,
              MIME_TYPES.docx,
              MIME_TYPES.doc,
              'image/tiff',
            ]}
          >
            <Group>
              <Text size="lg" inline>{t('components.files.title')}</Text>
              <Text size='sm' c='dimmed' inline mt={7}>{t('components.files.subtitle')}</Text>
            </Group>
          </Dropzone>
        </div>
      )}

      <Table verticalSpacing="lg">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>שם קובץ</Table.Th>
            <Table.Th>סוג</Table.Th>
            <Table.Th>תאריך העלאה</Table.Th>
            <Table.Th>הועלה על ידי</Table.Th>
            {showActions && (
              <Table.Th></Table.Th>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
};

Files.propTypes = {
  type: PropTypes.oneOf(['agent', 'agency', 'user']),
  id: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  handleDelete: PropTypes.func,
  handleReject: PropTypes.func,
  handleDownload: PropTypes.func,
  showForm: PropTypes.bool,
  showActions: PropTypes.bool,
};

export default Files;
