import PropTypes from 'prop-types';
import { Title } from '@mantine/core';
import styles from './style.module.css';
import ViewLine from '../ViewLine';

/*
lines example:
[
  {
    title: 'גירסא',
    value: '1.3',
    transforms: ['number']
  },
  {
    title: 'שם',
    value: 'ישראל ישראלי',
  }
]
*/

const SectionView = (props) => {
  const {
    title,
    lines = [],
  } = props;

  const content = (lines.length)
    ? lines.map((line) => <ViewLine
        key={line.title}
        title={line.title}
        content={line.value}
        transforms={line.transforms}
      />)
    : null;

  return (
    <section className={styles.section}>
      {title && <header><Title order={4}>{title}</Title></header>}
      {content}
    </section>
  );
};

SectionView.propTypes = {
  title: PropTypes.string,
  lines: PropTypes.array,
};

export default SectionView;
