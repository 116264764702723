export default {
  actions: {
    create: 'יצירת',
    update: 'עדכון פרטי',
    delete: 'מחיקת',
  },
  types: {
    agent: 'סוכן',
    agency: 'סוכנות',
    user: 'משתמש',
    import: 'טעינת נתונים',
  },
};
