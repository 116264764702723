import prepareTranslate from '@/helpers/dictionary';
import routes from '@/routes';
import { Button, Paper, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const Sent = () => {
  const t = prepareTranslate();
  const navigate = useNavigate();

  const onSentActionClick = () => {
    navigate(routes.login.path);
  };

  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <Text>{t('pages.forgot.sent.content')}</Text>
      <Button type="button" onClick={onSentActionClick} fullWidth mt="xl">{t('pages.forgot.form.actions.back')}</Button>
    </Paper>
  );
};

export default Sent;
