import { isPhone } from '@/helpers/is';

const PHONE_PREFIX = '972';

/**
 * transform to phone link
 * @param {string} d
 * @returns {string}
 */
const phoneTransform = (d = '') => {
  if (!isPhone(d)) {
    return null;
  }

  const phone = String(d).startsWith(PHONE_PREFIX) ? d : `${PHONE_PREFIX}${d}`;

  return <a href={`tel:${phone}`}>{d}</a>;
};

export default phoneTransform;
