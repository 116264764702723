import prepareTranslate from '@/helpers/dictionary';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  Button,
  Checkbox,
  Popover,
  Text,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { IconGripVertical, IconSettings } from '@tabler/icons-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Columns = (props) => {
  const {
    columns = [],
    // eslint-disable-next-line no-unused-vars
    onColumns = () => {}, // TODO
  } = props;

  const t = prepareTranslate();
  const [state, handlers] = useListState(columns);

  const items = state.map((item, index) => (
    <Draggable key={item.accessor} index={index} draggableId={item.accessor}>
      {(provided, snapshot) => (
        <div
          className={clsx(styles.item, { [styles.itemDragging]: snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={styles.dragHandle}>
            <IconGripVertical style={{ width: '18px', height: '18px' }} stroke={1.5} />
          </div>
          <div className={styles.line}>
            <Text>{item.header}</Text>
            <div className={styles.checkbox}>
              <Checkbox
                data-t={item.accessor}
                checked={item.isVisible === undefined || item.isVisible}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  ));

  return (
    <Popover shadow="md">
      <Popover.Target>
      <Button
          color="gray"
          size="xs"
          variant="outline"
          leftSection={<IconSettings stroke="1" />}
        >
          {t('components.columns.action')}
        </Button>
      </Popover.Target>
      <Popover.Dropdown w={300}>
        <Text c="dimmed" size="sm" mb="sm">{t('components.columns.label')}</Text>
        <DragDropContext
          onDragEnd={
            ({ destination, source }) => handlers.reorder(
              { from: source.index, to: destination?.index || 0 },
            )
          }
        >
          <Droppable droppableId="dnd-list" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Popover.Dropdown>
    </Popover>
  );
};

Columns.propTypes = {
  columns: PropTypes.array,
  onColumns: PropTypes.func,
};

export default Columns;
