import { useQuery } from '@tanstack/react-query';
import { getMessagesById, getOne, getCommentsById } from '../requests/user';

const userQuery = {
  useGetOne: (data = {}, headers = {}) => useQuery({
    queryKey: ['user', data],
    queryFn: () => getOne(data, headers),
  }),

  useGetMessagesById: (data = {}, headers = {}) => useQuery({
    queryKey: ['message', data],
    queryFn: () => getMessagesById(data, headers),
  }),

  useGetCommentsById: (data = {}, headers = {}) => useQuery({
    queryKey: ['comment', data],
    queryFn: () => getCommentsById(data, headers),
  }),

};
export default userQuery;
