import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import routes from '@/routes';
import LayoutDefault from '@/layouts/Default';
import LayoutEmpty from '@/layouts/Empty';
import Main from '@/pages/Main';
import Settings from '@/pages/Settings';
import Messages from '@/pages/Messages';
import Users from '@/pages/Users';
import Userview from '@/pages/Userview';
import UserCreate from '@/pages/UserCreate';
import UserEdit from '@/pages/UserEdit';
import Reports from '@/pages/Reports';
import Login from '@/pages/Login';
import Error from '@/pages/Error';
import Forgot from '@/pages/ForgotPassword';
import Reset from '@/pages/ResetPassword';
import Agents from '@/pages/Agents';
import Agentedit from '@/pages/Agentedit';
import Agentview from '@/pages/Agentview';
import Agentcreate from '@/pages/Agentcreate';
import ReportsList from '@/pages/ReportsList';
import ReportsView from '@/pages/ReportsView';
import Uploads from '@/pages/Uploads';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<Error />}>
      <Route element={<LayoutDefault />}>
        <Route path={routes.main.path} element={<Main />} />
        <Route path={routes.settings.path} element={<Settings />} />
        <Route path={routes.messages.path} element={<Messages />} />
        <Route path={routes.reports.path} element={<Reports />} />
        <Route path={routes.users.path} element={<Users />} />
        <Route path={routes.userView.path} element={<Userview />} />
        <Route path={routes.agents.path} element={<Agents />} />
        <Route path={routes.agentEdit.path} element={<Agentedit />} />
        <Route path={routes.reportList.path} element={<ReportsList />} />
        <Route path={routes.reportsView.path} element={<ReportsView />} />
        <Route path={routes.agentView.path} element={<Agentview />} />
        <Route path={routes.agentCreate.path} element={<Agentcreate />} />
        <Route path={routes.userCreate.path} element={<UserCreate />} />
        <Route path={routes.userEdit.path} element={<UserEdit />} />
        <Route path={routes.uploads.path} element={<Uploads />} />
      </Route>
      <Route element={<LayoutEmpty />}>
        <Route path={routes.login.path} element={<Login />} />
        <Route path={routes.forgot.path} element={<Forgot />} />
        <Route path={routes.reset.path} element={<Reset />} />
      </Route>
    </Route>,
  ),
);

export default router;
