import axios from '@/api/request';
import { defineCancelApiObject } from '@/helpers/request';

const authApi = {
  login: async (data = {}, cancel = false) => {
    try {
      const response = await axios.request({
        url: '/v1/auth/login',
        method: 'POST',
        data,
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (err) {
      return false;
    }
  },

  logout: async (cancel = false) => {
    try {
      const response = await axios.request({
        url: '/v1/auth/logout',
        method: 'POST',
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });

      return response.data;
    } catch (err) {
      return false;
    }
  },

  forgotPassword: async (data = {}, cancel = false) => {
    try {
      const response = await axios.request({
        url: '/v1/auth/forgot',
        method: 'POST',
        data,
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (err) {
      return false;
    }
  },

  resetPassword: async (data = {}, cancel = false) => {
    try {
      const response = await axios.request({
        url: '/v1/auth/password_reset',
        method: 'POST',
        data,
        signal: cancel
          ? cancelApiObject[this.get.name].handleRequestCancellation().signal
          : undefined,
      });
      return response.data;
    } catch (err) {
      return false;
    }
  },
};

const cancelApiObject = defineCancelApiObject(authApi);
export default authApi;
