export default {
  title: 'עריכת משתמש {1}',
  menu: {
    details: 'פרטים',
    contracts: 'הסכמים',
    documents: 'מסמכים',
    clients: 'לקוחות',
  },
  status: {
    active: 'פעיל',
    archive: 'ארכיון',
  },
  side: {
    actions: 'פעולות',
  },
  headers: {
    private: 'פרטים',
    contact: 'פרטי קשר',
    password: 'סיסמא',
    action: 'פעולות',
  },
  form: {
    firstname: 'שם פרטי',
    lastname: 'שם משפחה',
    username: 'שם משתמש',
    password: 'סיסמא',
    confirmpassword: 'אימות סיסמא',
    role: 'תפקיד',
    status: 'סטטוס',
    email: 'דואר אלקטרוני',
    phone: 'טלפון',
  },
  validations: {
    firstname: 'שם פרטי הינו חובה',
    lastname: 'שם משפחה הינו חובה',
    username: 'שם משתמש הינו חובה',
    password: {
      required: 'הסיסמא צריכה להכיל 8 תווים, מספר, סימן ואות גדולה אחת לפחות',
      confirm: 'הסיסמאות אינן תואמות',
    },
    role: 'תפקיד הינו חובה',
    email: 'כתובת הדואר האלקטרוני איננה תקינה',
    phone: 'מספר טלפון נייד תקין הינו חובה',
  },
  notification: {
    title: 'הפרטים עודכנו בהצלחה',
    message: 'פרטי המשתמש עודכנו במערכת',
    errortitle: 'שגיאה',
    error: 'לא בוצעה עריכת משתמש אנא בדוק את הפרטים שהוזנו',
  },
};
