import { useQuery } from '@tanstack/react-query';
import { getAll, findById } from '../requests/exportRule';

const KEY = 'exportRule';

const exportRuleQuery = {
  useGetAll: (data = {}) => useQuery({
    queryKey: [KEY],
    queryFn: () => getAll(data),
  }),
  useFindById: (data = {}) => useQuery({
    queryKey: [KEY, data.id],
    queryFn: () => findById(data),
  }),
};

export default exportRuleQuery;
