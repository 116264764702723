import PropTypes from 'prop-types';
import {
  Badge,
  Group,
  Text,
} from '@mantine/core';
import {
  isArray,
} from '@/helpers/is';
import { getItemValue } from '@/helpers/string';
import Transforms from '@/components/Transforms';
import ColumnActions from '../ColumnActions';

const ColumnContent = (props) => {
  const {
    accessor,
    item = {}, // table row
    actions = [],
    transforms = [],
  } = props;

  const data = getItemValue(item, accessor);
  let content = null;

  if (isArray(actions) && actions.length) {
    content = <ColumnActions items={actions} row={item} />;
  } else if (isArray(data) && data.length) {
    content = <Group gap="xs" wrap="nowrap">
        <Text component="span">
          <Transforms source={data[0]} transforms={transforms} />
        </Text>
        {
          (data.length > 1)
            ? <Badge component="span" size="xs" variant="light" color="blue">{data.length - 1}+</Badge>
            : null
        }
      </Group>;
  } else {
    content = <Transforms source={data} transforms={transforms} />;
  }

  return content;
};

ColumnContent.propTypes = {
  accessor: PropTypes.string,
  item: PropTypes.object,
  actions: PropTypes.array,
  transforms: PropTypes.array,
};

export default ColumnContent;
