export default {
  title: 'איפוס סיסמא',
  subtitle: 'על הסיסמא החדשה להכיל {1} תווים לפחות, סימן, מספר ואות גדולה',
  message: `
            כעת לאחר שהסיסמא שונתה בהצלחה,<br/>
            באפשרותך לעבור למסך הכניסה ולהתחבר למערכת
          `,
  labels: {
    password: 'סיסמא',
    confirm: 'סיסמא לאימות',
  },
  validations: {
    password: 'הסיסמא קצרה מדי',
    confirm: 'הסיסמאות אינן תואמות',
  },
  actions: {
    submit: 'איפוס סיסמא',
    back: 'עבור למסך כניסה',
  },
  alert: {
    content: `
    הקישור איננו תקין.
    <br/>
    אנא חזור לדף ההתחברות ונסה שנית.`,
  },
};
