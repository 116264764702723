export default {
  notification: {
    title: 'נוצרו הערות בעיבוד הנתונים',
    message: 'נרשמו הערות לגבי סוכנים בקובץ {1}',
    click: 'לחצו כאן על מנת לראות אותן',
  },
  popUp: {
    info: 'לא נמצאו הסכמים עבור מספר הסוכן {1} בחברה {2}, עבור הענף {3}, בחודש {4}\\{5}',
    message: 'שימו לב! יש לעדכן את המידע הרלוונטי לסוכנים ולהזין את הקובץ שנית במערכת על מנת לראות את המידע בדוחות',
  },
};
