/**
 * get resposne data according to scenario query
 * @param {object} res
 * @returns {object} { response, status }
 */
const getResponseData = (res = {}) => {
  const scenarioName = new URLSearchParams(window.location.search).get('scenario') || 'default';
  const responseObject = res[scenarioName] || scenarioName.default;
  const { response, status } = responseObject || {};

  return { response, status };
};

export {
  getResponseData,
};
