export default {
  labels: {
    agentnumber: 'מספר סוכן',
    date: {
      start: 'מתאריך',
      end: 'עד תאריך',
    },
    branch: 'ענפים',
    commission: {
      agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה',
      agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה',
      agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה',
      agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה',
      agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה',
      agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה',
      agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה',
      agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה',
    },
  },
};
