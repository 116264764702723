export default {
  title: 'גרור את הקבצים, או לחץ כאן לבחור אותם',
  subtitle: 'ניתן להעלות קבצים בגודל של עד',
  action: 'העלה קבצים',
  success: 'כל הקבצים נשלחו בהצלחה',
  error: 'חלה שגיאה בנסיון לשלוח את הקבצים',
  disabled: 'אין באפשרותך להעלות את חלק א של טופס 161',
  total: {
    files: '{1} קבצים',
    amount: 'סך הכל',
  },
};
