import { isEmail, isNumber } from '@/helpers/is';
import { truncateString } from '@/helpers/string';

/**
 * transform to email address link
 * @param {string} d
 * @param {object} params { firstCharCount: number, endCharCount: number }
 * @returns {string}
 */
const emailTransform = (d = '', params = {}) => {
  const {
    firstCharCount = 10,
    endCharCount = 10,
  } = params;

  if (!isNumber(firstCharCount) || !isNumber(endCharCount)) {
    return '';
  }

  return isEmail(d)
    ? <a href={`mailto:${d}`}>{truncateString(d, firstCharCount, endCharCount)}</a>
    : null;
};

export default emailTransform;
