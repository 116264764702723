import { useQuery } from '@tanstack/react-query';
import { getByCompanyId } from '../requests/importRule';

const KEY = 'importRule';

const importRuleQuery = {
  useGetByCompanyId: (data = {}) => useQuery({
    queryKey: [KEY, data.companyId],
    queryFn: () => getByCompanyId(data),
    enabled: !!data.companyId,
  }),
};

export default importRuleQuery;
