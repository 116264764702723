/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import PageHead from '@/components/PageHead';
import queryClient from '@/config/query';
import PageTemplate from '@/components/PageTemplate';
import Section from '@/components/Section';
import prepareTranslate from '@/helpers/dictionary';
import { ROLE_LIST, USER_STATUS } from '@/helpers/enums';
import { isEmail, isPassword, isPhone } from '@/helpers/is';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import {
  Button,
  Grid,
  Group,
  PasswordInput,
  Progress,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import userQuery from '@/api/queries/user';
import userMutations from '@/api/mutitations/user';
import { getStrength } from '../ResetPassword/funcs';

const UserEdit = () => {
  const t = prepareTranslate();
  const navigate = useNavigate();
  const [loader, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { id } = useParams();

  if (!id) {
    navigate(routes.users.path);
  }
  const { useUpdateUser } = userMutations;
  const { mutateAsync } = useUpdateUser();

  const { data: userData } = userQuery.useGetOne({ id });

  const STATUS = Object.fromEntries(
    USER_STATUS.map((status) => [status.value, status.label]),
  );
  const userIsActive = USER_STATUS[0].value;
  const userIsArchived = USER_STATUS[1].value;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (!userData) {
          navigate(routes.users.path);
        } else {
          form.setValues({
            firstName: userData.user?.firstName,
            lastName: userData.user?.lastName,
            userName: userData.user?.userName,
            role: userData.user?.role,
            email: userData.user?.email,
            phone: userData.user?.phone,
            isArchived: userData.user?.isArchived ? userIsArchived : userIsActive,
          });
          setLoading(false);
          setData(userData.user);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching user data:', error);
      }
    };
    if (userData) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, navigate]);

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      password: '',
      confirmPassword: '',
      role: 'administrator',
      isArchived: 'active',
      email: '',
      phone: '',
    },
    validate: {
      firstName: (value) => (value ? null : t('pages.useredit.validations.firstname')),
      lastName: (value) => (value ? null : t('pages.useredit.validations.lastname')),
      userName: (value) => (value ? null : t('pages.useredit.validations.username')),
      password: (value) => (!value || isPassword(value) ? null : t('pages.useredit.validations.password.required')),
      confirmPassword: (value, values) => (value !== values.password ? t('pages.useredit.validations.password.confirm') : null),
      role: (value) => (value ? null : t('pages.useredit.validations.role')),
      email: (value) => (isEmail(value) ? null : t('pages.useredit.validations.email')),
      phone: (value) => (isPhone(value) ? null : t('pages.useredit.validations.phone')),
    },
  });

  const handleUserEditClick = async () => {
    try {
      open();
      const {
        confirmPassword,
        password,
        isArchived,
        ...userValues
      } = form.values;

      const initialValues = {
        firstName: userData.user?.firstName,
        lastName: userData.user?.lastName,
        userName: userData.user?.userName,
        role: userData.user?.role,
        email: userData.user?.email,
        phone: userData.user?.phone,
        isArchived: userData.user?.isArchived ? userIsArchived : userIsActive,
      };
      const updatedValues = {};
      updatedValues.id = userData.user._id;

      if (isArchived === userIsArchived) {
        updatedValues.isArchived = true;
      } else {
        updatedValues.isArchived = false;
      }

      if (password) {
        updatedValues.password = password;
      }
      Object.keys(userValues).forEach((key) => {
        if (userValues[key] !== initialValues[key]) {
          updatedValues[key] = userValues[key];
        }
      });

      const updatedUser = await mutateAsync(updatedValues);

      if (!updatedUser) {
        notifications.show({
          title: t('pages.useredit.notification.errortitle'),
          message: t('pages.useredit.notification.error'),
        });
        close();
        return;
      }

      notifications.show({
        title: t('pages.useredit.notification.title'),
        message: t('pages.useredit.notification.message'),
      });
      queryClient.resetQueries({ id, exact: true });
      navigate(routeWithParams(routes.users.path));
      close();
    } catch (error) {
      notifications.show({
        title: t('pages.useredit.notification.errortitle'),
        message: t('pages.useredit.notification.error'),
      });
      close();
    }
  };

  if (loading) {
    return null;
  }

  const strength = getStrength(form.values.password);

  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        key={index}
        styles={{ section: { transitionDuration: '0ms' } }}
        value={form.values.password > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        size="4"
      />
    ));

  const fullname = `${data.firstName} ${data.lastName}`;

  const actions = [
    <Button key="primary" loading={loader} leftSection={<IconCheck />} onClick={form.onSubmit(handleUserEditClick)}>
      {t('global.save')}
    </Button>,
    <Button key="cancel" variant="outline" onClick={() => navigate(routes.users.path)}>
      {t('global.cancel')}
    </Button>,
  ];

  return (
    <>
      <PageHead title={t('pages.useredit.title', [fullname])} />
      <PageTemplate actions={actions} actionsTitle={t('pages.useredit.side.actions')}>
        <form>
          <PageTemplate.Panel>
            <Section title={t('pages.useredit.headers.private')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.useredit.form.firstname')}
                    required
                    {...form.getInputProps('firstName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.useredit.form.lastname')}
                    required
                    {...form.getInputProps('lastName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.useredit.form.username')}
                    required
                    {...form.getInputProps('userName')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <Select
                    label={t('pages.useredit.form.role')}
                    data={ROLE_LIST}
                    required
                    {...form.getInputProps('role')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <Select
                    label={t('pages.useredit.form.status')}
                    data={USER_STATUS}
                    required
                    {...form.getInputProps('isArchived')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.useredit.headers.password')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 6 }}>
                  <PasswordInput
                    autoComplete="off"
                    label={t('pages.useredit.form.password')}
                    required={false}
                    {...form.getInputProps('password')}
                  />
                  <Group gap={5} grow mt="xs" mb="md">
                    {bars}
                  </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 6 }}>
                  <PasswordInput
                    autoComplete="off"
                    label={t('pages.useredit.form.confirmpassword')}
                    required={false}
                    {...form.getInputProps('confirmPassword')}
                  />
                </Grid.Col>
              </Grid>
            </Section>

            <Section title={t('pages.useredit.headers.contact')}>
              <Grid gutter="lg">
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.useredit.form.email')}
                    required
                    {...form.getInputProps('email')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12 }}>
                  <TextInput
                    autoComplete="off"
                    label={t('pages.useredit.form.phone')}
                    required
                    {...form.getInputProps('phone')}
                  />
                </Grid.Col>
              </Grid>
            </Section>
          </PageTemplate.Panel>
        </form>
      </PageTemplate>
    </>
  );
};

export default UserEdit;
