import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import {
  ActionIcon,
  Button,
  Group,
  Menu,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import styles from './style.module.css';

const ColumnActions = (props) => {
  const {
    items = [],
    row = {},
  } = props;

  const t = prepareTranslate();
  const id = row.id || row._id;

  const primaryItems = items.filter((e) => e.isPrimary);
  const secondaryItems = items.filter((e) => !e.isPrimary);

  const primaryComponents = primaryItems.map((primaryItem) => {
    const {
      title = '',
      action = () => {},
      icon,
      showLabel,
    } = primaryItem;

    return (showLabel)
      ? <Button
          key={title}
          color="blue"
          variant="light"
          leftSection={icon}
          size="xs"
          onClick={() => { action(id, row); }}
          className={styles.rowaction}
        >
          <Text size="xs">{title}</Text>
        </Button>
      : <Tooltip label={title} key={title}>
        <ActionIcon
          color="gray"
          key={title}
          variant='outline'
          onClick={() => { action(id, row); }}
        >
          {icon}
        </ActionIcon>
      </Tooltip>;
  });

  let secondaryComponents = null;

  if (secondaryItems.length) {
    secondaryComponents = (
      <Menu>
        <Menu.Target>
          <ActionIcon variant="light" color="blue">
            <IconDots stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>{t('components.table.secondary')}</Menu.Label>
          {
            secondaryItems.map((secondaryItem) => {
              const {
                title = '',
                action = () => {},
                icon,
              } = secondaryItem;

              return (
                <Menu.Item
                  key={title}
                  leftSection={icon}
                  onClick={() => { action(id, row); }}
                  className={styles.rowaction}
                >
                  {title}
                </Menu.Item>
              );
            })
          }
        </Menu.Dropdown>
      </Menu>
    );
  }

  return (
    <Group wrap="nowrap">
      {primaryComponents}
      {secondaryComponents}
    </Group>
  );
};

ColumnActions.propTypes = {
  items: PropTypes.array,
  row: PropTypes.object,
};

export default ColumnActions;
