import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { IconCalendar, IconHash } from '@tabler/icons-react';
import { formatDateToString, isDateAfterNow } from '@/helpers/string';
import prepareTranslate from '@/helpers/dictionary';
import styles from './style.module.css';

// TODO: remove eslint disable line

const AgentAgreements = (props) => {
  const {
    data = [],
  } = props;

  const t = prepareTranslate();

  const items = data.map((item) => {
    const {
      id,
      agentNumber,
      companyId,
      from,
      logo,
      till,
      agencyFeeMonthlyDeposit,
      agentFeeMonthlyAccumulation,
      agencyFeeMonthlyAccumulation,
      agentFeeMonthlyDeposit,
      agentFeeAnnualAccumulation,
      agencyFeeAnnualAccumulation,
      agentFeeAnnualDeposit,
      agencyFeeAnnualDeposit,
    } = item;

    const badge = (till && !isDateAfterNow(till))
      ? <Badge variant="light" color="red">{t('global.valid.expire')}</Badge>
      : <Badge variant="light" color="blue">{t('global.valid.inspire')}</Badge>;

    return (
      <Paper key={id} withBorder p='md'>
        <Group justify='space-between'>
          <div>
            <Group gap='xs'>
              <Avatar size={48} src={logo} radius={10} />
              <Text fw={700}>
                {companyId?.hebrew} ({id}#)
              </Text>
            </Group>
          </div>

          {badge}
        </Group>

        <div className={styles.list}>
          {agentNumber && (
            <div className={styles.listitem}>
              <IconHash height={12} />
              <Text size='xs' c='dimmed'>
                {t('components.agentAgreements.labels.agentnumber')}
              </Text>
              <Text size='xs'>{agentNumber}</Text>
            </div>
          )}

          {from && (
            <div className={styles.listitem}>
              <IconCalendar height={12} />
              <Text size='xs' c='dimmed'>
                {t('components.agentAgreements.labels.date.start')}
              </Text>
              <Text size='xs'>{formatDateToString(from)}</Text>
            </div>
          )}

          {till && (
            <div className={styles.listitem}>
              <IconCalendar height={12} />
              <Text size='xs' c='dimmed'>
                {t('components.agentAgreements.labels.date.end')}
              </Text>
              <Text size='xs'>{formatDateToString(till)}</Text>
            </div>
          )}
        </div>
        <div className={styles.commissions}>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agencyFeeMonthlyDeposit')}
            </Text>
            <Text size='md' fw={700}>
              {agencyFeeMonthlyDeposit}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agentFeeMonthlyAccumulation')}
            </Text>
            <Text size='md' fw={700}>
              {agentFeeMonthlyAccumulation}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agencyFeeMonthlyAccumulation')}
            </Text>
            <Text size='md' fw={700}>
              {agencyFeeMonthlyAccumulation}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agentFeeMonthlyDeposit')}
            </Text>
            <Text size='md' fw={700}>
              {agentFeeMonthlyDeposit}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agencyFeeAnnualAccumulation')}
            </Text>
            <Text size='md' fw={700}>
              {agencyFeeAnnualAccumulation}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agentFeeAnnualAccumulation')}
            </Text>
            <Text size='md' fw={700}>
              {agentFeeAnnualAccumulation}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agencyFeeAnnualDeposit')}
            </Text>
            <Text size='md' fw={700}>
              {agencyFeeAnnualDeposit}%
            </Text>
          </div>
          <div className={styles.commission}>
            <Text size='lg'>
              {t('components.agentAgreements.labels.commission.agentFeeAnnualDeposit')}
            </Text>
            <Text size='md' fw={700}>
              {agentFeeAnnualDeposit}%
            </Text>
          </div>
        </div>
      </Paper>
    );
  });
  return (
    <Stack>
      {items.length ? (
        items
      ) : (
        <Text fw='bold'>{t('components.rows.empty.title')}</Text>
      )}
    </Stack>
  );
};

AgentAgreements.propTypes = {
  data: PropTypes.array,
};

export default AgentAgreements;
