import axiosRequest from '.';

const getAll = async (data) => (
  axiosRequest({
    url: '/v1/exportRule/findAll',
    method: 'POST',
    data,
  })
);

const findById = async (data) => (
  axiosRequest({
    url: '/v1/exportRule/findById',
    method: 'POST',
    data,
  })
);

export {
  getAll,
  findById,
};
