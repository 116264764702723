/**
 * add parameters to path
 * @param {string} route
 * @param {object} params
 * @returns path with parameters
 */
const routeWithParams = (route = '', params = {}) => {
  let output = route;

  Object.keys(params).forEach((key) => {
    output = output.replace(`:${key}`, params[key]);
  });

  return output;
};

export {
  routeWithParams,
};
