import axios from 'axios';
import axiosConfig from '@/config/axios';

const axiosInstance = axios.create(axiosConfig);

// Custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // Logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error); // eslint-disable-line no-console
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  undefined,
  (error) => errorHandler(error),
);

export default axiosInstance;
