import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import MenuGroups from '../MenuGroups';
import styles from './style.module.css';
import SectionExtra from '../SectionExtra';
import Panel from './Panel';
import SectionActions from '../SectionActions';

/*
menuGroups example:
[
    {
      links: [
        {
          id: 'details',
          title: 'פרטים',
          isDefault: true,
        },
        {
          id: 'contracts',
          title: 'הסכמים',
        },
      ],
    },
  ]
*/

/*
tabs example:
[
  {
    title: 'comments',
    content: <Comments type="agent" id={id} showForm />,
    isDefault: true,
  },
  {
    title: 'agent histories',
    content: <Histories type="agent" id={id} />,
  }
]
*/

/*
actions example:
[
  <Button key="update">{t('global.update')}</Button>,
  <Button key="cancel" variant="outline">{t('global.cancel')}</Button>,
]
*/

/**
 * get default active name according to data
 * @param {array} data menuGroups
 * @returns {string}
 */
const getMenuGroupsDefaultActive = (data) => {
  let defaultActive = null;
  if (data.length) {
    let defaultPanel = null;

    data.forEach((menu) => {
      const { links = [] } = menu;
      const item = links.find((link) => link.isDefault);

      if (!defaultPanel && item) {
        defaultPanel = item;
      }
    });

    defaultActive = (defaultPanel) ? defaultPanel.id : data[0]?.links[0]?.id;
  }

  return defaultActive;
};

const PageTemplate = (props) => {
  const {
    onPanelClick = () => {},
    menuGroups = [],
    side,
    actionsTitle,
    tabsTitle,
    actions = [],
    tabs = [],
    children,
  } = props;

  const isShowSide = (tabs.length > 0 || side || actions.length > 0);
  const defaultActive = getMenuGroupsDefaultActive(menuGroups);
  const [panel, setPanel] = useState(defaultActive);

  const onMenuGroupsPanelClick = (id) => {
    setPanel(id);
    onPanelClick(id);
  };

  // TODO: find "react" way to show / hide panels
  useEffect(() => {
    const allPanels = document.querySelectorAll('[data-panel]');

    if (menuGroups.length) {
      const element = document.querySelector(`[data-panel=${panel}]`);
      allPanels.forEach((e) => { e.style.display = 'none'; });

      if (element) {
        element.style.display = 'block';
      }
    } else {
      allPanels.forEach((e) => { e.style.display = 'block'; });
    }
  }, [panel, menuGroups]);

  return (
    <div className={styles.wrapper}>
      {(menuGroups && menuGroups.length > 0) && (
        <div className={styles.menu}>
          <MenuGroups
            data={menuGroups}
            defaultActive={defaultActive}
            onClick={onMenuGroupsPanelClick}
          />
        </div>
      )}

      <div className={styles.content} data-active={panel}>
        {children}
      </div>

      {isShowSide && (
        <div className={styles.side}>
          {(actions.length > 0) && <SectionActions actions={actions} title={actionsTitle} />}
          {(tabs.length > 0) && <SectionExtra tabs={tabs} title={tabsTitle} />}
          {side}
        </div>
      )}
    </div>
  );
};

PageTemplate.Panel = Panel;

PageTemplate.propTypes = {
  onPanelClick: PropTypes.func,
  menuGroups: PropTypes.array,
  side: PropTypes.any,
  views: PropTypes.array,
  actionsTitle: PropTypes.string,
  actions: PropTypes.array,
  tabsTitle: PropTypes.string,
  tabs: PropTypes.array,
  comments: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.any,
};

export default PageTemplate;
