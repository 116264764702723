import { isNumber } from '@/helpers/is';

/**
 * transform to email address link
 * @param {string} d
 * @param {object} params { firstCharCount: number, endCharCount: number }
 * @returns {string}
 */
const NumberFormat = (d = '', params = {}) => {
  const {
    precision = 2,
  } = params;

  let output = Number(d);

  if (!isNumber(output)) {
    return null;
  }

  if (Number.isInteger(output)) {
    output = output.toString();
  } else {
    output = output.toFixed(precision);
  }

  return output.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default NumberFormat;
