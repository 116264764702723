import prepareTranslate from '@/helpers/dictionary';
import { isBoolean } from '@/helpers/is';
import { Badge } from '@mantine/core';

/**
 * transform to badge according to true or false
 * @param {boolean} d
 * @param {object} params { yes: { title: color: }, no: { title: color: }}
 * @returns {react}
 */
const yesnoTransform = (d, params = {}) => {
  const t = prepareTranslate();

  if (!isBoolean(d)) { return ''; }
  const {
    yes = {},
    no = {},
  } = params;

  if (!Object.keys(yes).includes('title')) yes.title = t('global.yes');
  if (!Object.keys(no).includes('title')) no.title = t('global.no');

  let { title } = yes;
  let { color } = yes;

  if (!d) {
    title = no.title;
    color = no.color;
  }

  return <Badge variant="light" color={color || 'gray'}>{title}</Badge>;
};

export default yesnoTransform;
