import PropTypes from 'prop-types';
import { Grid, Text } from '@mantine/core';
import { isDateObject } from '@/helpers/is';
import { formatDateToString } from '@/helpers/string';
import styles from './style.module.css';
import Transforms from '../Transforms';

const ViewLine = (props) => {
  const {
    title,
    content,
    transforms = [],
    children,
  } = props;

  const body = (children) || <Transforms source={content} transforms={transforms} />;

  const formattedBody = (isDateObject(body)) ? formatDateToString(body) : body;

  return (
    <div className={styles.line}>
      <Grid gutter="sm">
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Text size="md" fw={700}>{title}</Text>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 8 }}>
          {formattedBody}
        </Grid.Col>
      </Grid>
    </div>
  );
};

ViewLine.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  children: PropTypes.any,
  transforms: PropTypes.array,
};

export default ViewLine;
