/* eslint-disable no-console */
const queryKeys = [
  { title: 'year', required: true },
  { title: 'month', required: true },
  { title: 'companyId', required: true },
  { title: 'productId', required: true },
  { title: 'identifiers', required: true },
  { title: 'query' },
];

const queryRules = {
  year: (data) => validateYear(data),
  month: (data) => validateMonth(data),
  companyId: (data) => validateCompanyId(data),
  productId: (data) => validateProductId(data),
  query: (data) => validateQuery(data),
  identifiers: (data) => validateIdentifiers(data),
};

const validateQueries = (data) => {
  let queryData = {
    // Initialize query as an empty object because its not required
  };

  const validQuerys = queryKeys.every((queryKey) => {
    const { title, required } = queryKey;
    let queryValue;

    Object.entries(data).forEach(([key, value]) => {
      if (title === key) {
        queryValue = value;
      }
    });

    if (!queryValue) {
      if (required) {
        console.error(`The query parameter ${title} is missing`);
        return false;
      }
      return true;
    }

    const value = queryRules[title](queryValue);

    if (value) {
      queryData[title] = value;
    } else {
      console.error(`The query parameter ${title} is Invalid`);
      return false;
    }

    return true;
  });

  if (validQuerys) {
    queryData = {
      additionalData: {
        companyId: queryData.companyId,
        productId: queryData.productId,
      },
      ...queryData,
    };
    delete queryData.companyId;
    delete queryData.productId;
    return queryData;
  }

  return false;
};

const validateCompanyId = (data) => JSON.parse(data);

const validateProductId = (data) => JSON.parse(data);

const validateIdentifiers = (data) => JSON.parse(data);

const validateQuery = (data) => JSON.parse(data);

const validateYear = (data) => {
  let returnData = JSON.parse(data);

  returnData = returnData.map((year) => {
    if (year.length > 4) {
      console.error('Year must be 4 digits length or greater');
      return false;
    }

    if (typeof Number(year) !== 'number') {
      console.error('Year is not a number');
      return false;
    }

    return year;
  });

  return returnData;
};

const validateMonth = (data) => {
  let returnData = JSON.parse(data);

  returnData = returnData.map((month) => {
    if (month.includes[0]) {
      console.error('Month contains the number 0');
      return false;
    }

    if (typeof Number(month) !== 'number') {
      console.error('Month is not a number');
      return false;
    }

    return month;
  });

  return returnData;
};

export default validateQueries;
