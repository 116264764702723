/**
 * transform to add prefix and suffix
 * @param {date} d
 * @param {object} params
 * @returns {string}
 */
const edgesTransform = (d = '', params = {}) => {
  const {
    prefix,
    suffix,
  } = params;

  return (
    <>
      {prefix}
      {d}
      {suffix}
    </>
  );
};

export default edgesTransform;
