import PageHead from '@/components/PageHead';
import routes from '@/routes';
import { Container } from '@mantine/core';
import Table from '@/components/Table';
import { IconEye } from '@tabler/icons-react';
import { MESSAGE_TYPES } from '@/helpers/enums';
import { useNavigate } from 'react-router-dom';
import { routeWithParams } from '@/helpers/route';

// TODO: get this from database
const mockData = [
  {
    id: '333',
    date: '12/06/2024',
    title: 'משתמש נחסם לאחר מספר רב של נסיונות',
    type: 'משתמש',
  },
  {
    id: '444',
    date: '13/06/2024',
    title: 'חוזה של סוכן עומד להסתיים בקרוב',
    type: 'סוכן',
  },
];
// TODO END

const Messages = () => {
  const navigate = useNavigate();

  const handleClickOnView = (id, row) => {
    const element = MESSAGE_TYPES.find((message) => message.label === row.type);
    navigate(routeWithParams(element.value, { id }));
  };

  const mockColumns = [
    {
      header: 'תאריך',
      accessor: 'date',
      isVisible: true,
    },
    {
      header: 'הודעה',
      accessor: 'title',
      isVisible: true,
    },
    {
      header: 'סוג',
      accessor: 'type',
      isVisible: true,
    },
    {
      header: 'פעולות',
      filter: {
        enable: false,
      },
      actions: [
        {
          title: 'צפייה',
          action: handleClickOnView,
          icon: <IconEye />,
          showLabel: true,
          isPrimary: true,
        },
      ],
    },
  ];

  return (
    <>
      <PageHead
        title={routes.messages.name}
      />

      <Container fluid>
        <Table
          columns={mockColumns}
          data={mockData}
          loading={false}
          isAllowCheck={false}
          isAllowCheckAll={false}
          isExportActive={false}
          isColumnsActive={false}
          isFilterActive={false}
          totalPages={10}
        />
      </Container>
    </>
  );
};

export default Messages;
