import PropTypes from 'prop-types';
import { formatDateToString } from '@/helpers/string';
import { Paper, Text } from '@mantine/core';
import UserIcon from '../UserIcon';
import styles from './style.module.css';

const Comment = (props) => {
  const {
    userId,
    createdAt,
    firstName,
    lastName,
    children,
  } = props;

  const fullname = `${firstName} ${lastName}`;
  const date = formatDateToString(createdAt);

  return (
    <div className={styles.comment}>
      <UserIcon userId={userId} isMenuActive={false} />
      <div className={styles.content}>
        <div className={styles.info}>
          <Text size="sm" fw={500}>{fullname}</Text>
          <Text c="dimmed" size="xs">{date}</Text>
        </div>

        <Paper withBorder p="sm" mt="xs">
          {children}
        </Paper>
      </div>
    </div>
  );
};

Comment.propTypes = {
  userId: PropTypes.string,
  createdAt: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  children: PropTypes.any,
};

export default Comment;
