import { useEffect, useState } from 'react';
import { BarChart, LineChart } from '@mantine/charts';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Container, LoadingOverlay } from '@mantine/core';
import { CHART_TYPES } from '@/helpers/enums';
import ErrorFallback from '../ErrorFallback';
import generateMockChartData from './mockData/mockChartData';
import mockSeries from './mockData/mockSeries';
import {
  attachColorsToSeries,
  formatData,
  prepareToolTipData,
  validateData,
} from './LineChart.functions';
import NoData from './noData';
import ChartType from './ChartType';

const chartOptions = ['Bar', 'Line'];

const ChartComponent = (props) => {
  const {
    getData = () => {},
    getDataQuery = {},
    dataKey = 'date',
    h = 200,
    curveType = 'linear',
    isChartTypeActive = true,
  } = props;

  const mockChartData = generateMockChartData();

  const [data, setData] = useState(mockChartData); // data from back end
  const [formattedData, setFormattedData] = useState([]); // data without components to match chart
  const [series, setSeries] = useState([]);
  const [primaryValue, setPrimaryValue] = useState();
  const [loading, setLoading] = useState(true);
  const [chartType, setChartType] = useState(chartOptions);

  const onChartTypeSelect = (value) => {
    setChartType(value);
  };

  /**
   * Call server with missing data, and fill "data", "formattedData" and "series"  state
   */
  const fillDataFromServer = async () => {
    setLoading(true);
    const newRows = await getData(getDataQuery);
    setLoading(false);

    const {
      data: newData,
      series: newSeries,
      primaryValue: newPrimaryValue,
    } = newRows;

    if (!validateData(newData, dataKey, series, newPrimaryValue)) {
      // eslint-disable-next-line no-console
      console.error('getData is not valid');
      setData([]);
      return;
    }

    const coloredSeries = attachColorsToSeries(newSeries);
    const newformattedData = formatData(newData);

    setFormattedData(newformattedData);
    setData(newData);
    setSeries(coloredSeries);
    setPrimaryValue(newPrimaryValue);
  };

  useEffect(() => {
    fillDataFromServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataQuery]);

  const chartData = loading ? mockChartData : formattedData;
  const currentSeries = loading ? mockSeries : series;
  const emptyData = (!loading && data.length === 0);

  return (
    <Container pos='relative'>
      <LoadingOverlay
        visible={loading}
      />
      <Container mb='lg' mr={0} p={0} w='10rem'>
        {
          (
            isChartTypeActive && <ChartType onChartTypeSelect={onChartTypeSelect} />
          )
        }
      </Container>
      {
        // eslint-disable-next-line no-nested-ternary
        emptyData ? (
          <NoData />
        ) : (
          chartType === CHART_TYPES.line ? (
            <LineChart
              h={h}
              data={chartData}
              dataKey={dataKey}
              curveType={curveType}
              series={currentSeries}
              withXAxis={!loading}
              withYAxis={!loading}
              withLegend={!loading}
              withTooltip={!loading}
              tooltipProps={{
                content: ({ label, payload }) => (
                  prepareToolTipData(data, label, payload, primaryValue)
                ),
              }}
            />
          ) : (
            <BarChart
              h={h}
              data={chartData}
              dataKey={dataKey}
              series={currentSeries}
              withLegend={!loading}
              tooltipProps={{
                content: ({ label, payload }) => (
                  prepareToolTipData(data, label, payload, primaryValue)
                ),
              }}
            />
          )
        )
      }
    </Container>
  );
};

ChartComponent.propTypes = {
  getData: PropTypes.func,
  getDataQuery: PropTypes.object,
  dataKey: PropTypes.string,
  h: PropTypes.number,
  curveType: PropTypes.string,
  isChartTypeActive: PropTypes.bool,
};

export default withErrorBoundary(ChartComponent, {
  FallbackComponent: ErrorFallback,
});
