import { COLORS } from '@/helpers/enums';
import { isArray, isObject, isString } from '@/helpers/is';
import ToolTip from './ToolTip/index';

/**
 * validate chart data and log error if needed
 * @param {array} data
 * @param {number} dataKey
 * @param {array} series
 * @returns {boolean}
 */
const validateData = (data, dataKey, series, primaryValue) => {
  if (!isArray(data)) {
    // eslint-disable-next-line no-console
    console.error('Call to "getData" function did not return "data" property as array', data);
    return false;
  }

  if (!isArray(series)) {
    // eslint-disable-next-line no-console
    console.error('Call to "getData" function did not return "count" property as number', series);
    return false;
  }

  if (data.some((dataItem) => dataItem[dataKey] === undefined)) {
    // eslint-disable-next-line no-console
    console.error('Chart component is missing dataKey property in one of the data prop array items');
    return false;
  }

  if (data.some((e) => (!isObject(e)))) {
    // eslint-disable-next-line no-console
    console.error('Array from "getData" function contain an element that is not object');
    return false;
  }

  if (series.some((seriesItem) => seriesItem.name === undefined)) {
    // eslint-disable-next-line no-console
    console.error('Chart component is missing name property in one of the series prop array items');
    return false;
  }

  if (series.some((e) => (!isObject(e)))) {
    // eslint-disable-next-line no-console
    console.error('Array from "getData" function contain an element that is not object');
    return false;
  }

  if (!isString(primaryValue)) {
    // eslint-disable-next-line no-console
    console.error('The primaryValue from "getData" is not a string');
    return false;
  }

  return true;
};

/**
 * Format data from { value, components } to value to match line chart expectations
 * @param {array} data
 * @returns {array}
 */
const formatData = (data) => (data.map((dataObject) => {
  const objectCopy = { ...dataObject };
  Object.entries(dataObject).forEach(([objectKey, objectValue]) => {
    if (Object.prototype.hasOwnProperty.call(objectValue, 'value')) {
      objectCopy[objectKey] = objectValue.value;
    }
  });

  return objectCopy;
}));

/**
 * Make components avalible in ToolTip according to original request data
 * @param {array} data
 * @param {string} label
 * @param {array} payload
 * @returns {JSX element}
 */
const prepareToolTipData = (data, label, payload, primaryValue) => {
  const fullDataItem = data.find((item) => item.date === label);
  const payLoadCopy = [...payload];

  if (fullDataItem) {
    Object.entries(fullDataItem).forEach(([dataPropertyKey, dataObject]) => {
      if (Object.prototype.hasOwnProperty.call(dataObject, 'value')) {
        const releventComponents = dataObject.components;

        const relevantPayLoadItemIndex = payLoadCopy
          .findIndex(({ name: itemName }) => itemName === dataPropertyKey);

        if (relevantPayLoadItemIndex !== -1) {
          payLoadCopy[relevantPayLoadItemIndex] = {
            components: releventComponents,
            ...payload[relevantPayLoadItemIndex],
          };
        }
      }
    });
  }

  return <ToolTip label={label} payload={payLoadCopy} primaryValue={primaryValue}/>;
};

/**
 * Series returns from back end without colors. Atach colors to them randomaly
 * @param {array} data
 * @returns {array}
 */
const attachColorsToSeries = (data) => {
  const shuffledColors = [...COLORS]; // Copy the COLORS array
  shuffledColors.sort(() => Math.random() - 0.5); // Shuffle colors using random sorting
  const colors = shuffledColors.slice(0, data.length); // Get first N colors for N series

  return data.map((serie, index) => ({
    ...serie, // Spread existing properties from series object
    color: colors[index], // Assign random color based on index
  }));
};

export {
  validateData,
  formatData,
  prepareToolTipData,
  attachColorsToSeries,
};
