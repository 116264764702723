import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_LOGIN = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'login',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'login',
      success: false,
    },
    status: 401,
  },
};

const RESULT_LOGOUT = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'logout',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'logout',
      success: false,
    },
    status: 401,
  },
};

const RESULT_FORGOT_PASSWORD = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'forgotPassword',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'forgotPassword',
      success: false,
    },
    status: 500,
  },
};

const RESULT_RESET_PASSWORD = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'resetPassword',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'resetPassword',
      success: false,
    },
    status: 500,
  },
};

const loginHandler = async () => {
  const { response, status } = getResponseData(RESULT_LOGIN);
  await delay(1000);

  const userInfoCookie = encodeURIComponent('{"_id":"12123scsdv","email":"lilach@equity-ps.co.il","phone":"0521234567","role":"superadmin","firstName":"לילך","lastName":"קסטוריאני"}');

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: new Headers([
        ['Content-Type', 'application/json'],
        ['Set-Cookie', 'accessToken=access-token-mock; Path=/; SameSite=Strict'],
        ['Set-Cookie', `userInfo=${userInfoCookie}; Path=/; SameSite=Strict`],
      ]),
    },
  );
};

const logoutHandler = async () => {
  const { response, status } = getResponseData(RESULT_LOGOUT);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: new Headers([
        ['Content-Type', 'application/json'],
        ['Set-Cookie', 'accessToken=; Path=/; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:00 GMT'],
        ['Set-Cookie', 'userInfo=; Path=/; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:00 GMT'],
      ]),
    },
  );
};

const forgotPasswordHandler = async () => {
  const { response, status } = getResponseData(RESULT_FORGOT_PASSWORD);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
const resetPasswordHandler = async () => {
  const { response, status } = getResponseData(RESULT_RESET_PASSWORD);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export {
  loginHandler,
  logoutHandler,
  forgotPasswordHandler,
  resetPasswordHandler,
};
