import axios from '@/api/request';
import { defineCancelApiObject } from '@/helpers/request';

const agentApi = {
  getOne: async (data = {}, cancel = false) => {
    const response = await axios.request({
      url: '/v1/agent/agent',
      method: 'POST',
      data,
      signal: (cancel)
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getAll: async (data = {}, cancel = false) => {
    const response = await axios.request({
      url: '/v1/agent/agents',
      method: 'POST',
      data,
      signal: (cancel)
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  filter: async (data = {}, cancel = false) => {
    const response = await axios.request({
      url: '/v1/agent/filter',
      method: 'POST',
      data,
      signal: (cancel)
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  create: async (data = {}, cancel = false) => {
    const response = await axios.request({
      url: '/v1/agent/create',
      method: 'POST',
      data,
      signal: (cancel)
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  update: async (data = {}, cancel = false) => {
    const response = await axios.request({
      url: '/v1/agent/update',
      method: 'POST',
      data,
      signal: (cancel)
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  delete: async (data = [], cancel = false) => {
    const response = await axios.request({
      url: '/v1/agent/delete',
      method: 'POST',
      data,
      signal: (cancel)
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

};

const cancelApiObject = defineCancelApiObject(agentApi);

export default agentApi;
