export default {
  title: 'התחברות',
  subtitle: 'מערכת ניהול סוכנות אקוויטי',
  alert: {
    content: `
    שם המשתמש והסיסמא אינם משוייכים לחשבון במערכת.
    <br/>
    בדוק שהפרטים שהזנת הינם נכונים.
    `,
  },
  form: {
    email: 'דואר אלקטרוני',
    username: 'שם משתמש',
    password: 'סיסמא',
    remember: 'זכור אותי',
    forgot: 'שכחת סיסמא?',
    action: 'התחבר',
  },
};
