import { useMutation } from '@tanstack/react-query';
import { createMessage } from '../requests/user';

const KEY = 'comment';

const useCreateMessage = () => useMutation({
  mutationKey: [KEY, 'create'],
  mutationFn: (data) => createMessage(data),
});
const messageMutations = {
  useCreateMessage,
};

export default messageMutations;
