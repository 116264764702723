import axios from '@/api/request';

const axiosRequest = async (params) => {
  try {
    const response = await axios.request(params);
    return response.data.data;
  } catch (error) {
    const { status, data: responseData } = error.response;
    const { data, error: customError } = responseData;
    const responseError = { data, status, error: customError };
    throw responseError;
  }
};

export default axiosRequest;
