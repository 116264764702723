import { useNavigate } from 'react-router-dom';
import PageHead from '@/components/PageHead';
import Table from '@/components/Table';
import agentMutations from '@/api/mutitations/agent';
import { notifications } from '@mantine/notifications';
import prepareTranslate from '@/helpers/dictionary';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import { Button, Container } from '@mantine/core';
import agentApi from '@/api/services/agent';
import {
  IconEdit,
  IconEye,
  IconPlus,
  IconX,
} from '@tabler/icons-react';

const Agents = () => {
  const t = prepareTranslate();
  const navigate = useNavigate();

  const { useGetAgents } = agentMutations;
  const { mutateAsync } = useGetAgents();

  // eslint-disable-next-line consistent-return
  const getData = async (start, end, query) => {
    try {
      const agentsData = await mutateAsync({ start, end, query });
      return agentsData;
    } catch (error) {
      notifications.show({
        title: t('pages.agents.notification.errortitle'),
        message: t('pages.agents.notification.errormessage'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };

  const handleClickOnView = (id) => {
    navigate(routeWithParams(routes.agentView.path, { id }));
  };

  const handleCreateClick = () => {
    navigate(routes.agentCreate.path);
  };

  const handleClickOnEdit = (id) => {
    navigate(routeWithParams(routes.agentEdit.path, { id }));
  };

  const handleClickOnDelete = async (items = []) => {
    await agentApi.delete({ data: items });

    // TODO reload page in better way
    // refreshRows(items, 'delete');
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const handleExport = (option, queryObject) => {
    // eslint-disable-next-line no-console
    console.log({ option, queryObject });
  };

  const mockColumns = [
    {
      header: 'מספר סוכן',
      accessor: 'agentNumber',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'שם פרטי',
      accessor: 'personalDetails.firstName',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'שם משפחה',
      accessor: 'personalDetails.lastName',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'טלפון',
      accessor: 'personalDetails.phone',
      filter: {
        type: 'string',
      },
      isVisible: true,
      transforms: ['phone'],
    },
    {
      header: 'דואר אלקטרוני',
      accessor: 'personalDetails.email',
      filter: {
        type: 'string',
      },
      isVisible: true,
      transforms: ['email'],
    },
    {
      header: 'תום רשיון',
      accessor: 'personalDetails.dateLicenseEnd',
      filter: {
        type: 'date',
        enable: false,
      },
      isVisible: false,
    },
    {
      header: 'פעולות',
      actions: [
        {
          title: 'צפייה',
          action: handleClickOnView,
          icon: <IconEye />,
          showLabel: true,
          isPrimary: true,
        },
        {
          title: 'עריכה',
          action: handleClickOnEdit,
          icon: <IconEdit />,
          showLabel: true,
          isPrimary: true,
        },
        // {
        //   title: 'הפוך לפעיל/לא פעיל',
        //   action: handleClickOnEdit,
        //   icon: <IconEdit />,
        //   showLabel: false,
        //   isPrimary: false,
        // },
      ],
    },
  ];

  const mockSelectionActions = [
    {
      title: 'מחיקה',
      icon: <IconX stroke='1' />,
      onClick: handleClickOnDelete,
    },
  ];

  return (
    <>
      <PageHead
        title={t('pages.agents.title')}
        actions={<Button leftSection={<IconPlus />} onClick={handleCreateClick}>{t('pages.agents.action')}</Button>}
      />

      <Container fluid>
        <Table
          columns={mockColumns}
          onExport={handleExport}
          getData={getData}
          selectionActions={mockSelectionActions}
          isColumnsActive={false}
          isExportActive={false}
        />
      </Container>
    </>
  );
};

export default Agents;
