import global from './global/lang.he';
import main from './pages/main/lang.he';
import login from './pages/login/lang.he';
import forgot from './pages/forgot/lang.he';
import reset from './pages/reset/lang.he';
import error from './pages/error/lang.he';
import agents from './pages/agents/lang.he';
import users from './pages/users/lang.he';
import userview from './pages/userView/lang.he';
import usercreate from './pages/userCreate/lang.he';
import useredit from './pages/userEdit/lang.he';
import settings from './pages/settings/lang.he';
import agentedit from './pages/agentEdit/lang.he';
import agentview from './pages/agentView/lang.he';
import uploads from './pages/uploads/lang.he';
import agentcreate from './pages/agentCreate/lang.he';
import reports from './pages/reports/lang.he';
import importLang from './components/import/lang.he';
import navbar from './components/navbar/lang.he';
import filter from './components/filter/lang.he';
import columns from './components/columns/lang.he';
import exportLang from './components/export/lang.he';
import comments from './components/comments/lang.he';
import history from './components/history/lang.he';
import rows from './components/rows/lang.he';
import files from './components/files/lang.he';
import usericon from './components/userIcon/lang.he';
import table from './components/table/lang.he';
import validations from './validations/lang.he';
import agentAgreements from './components/agentAgreements/lang.he';
import errorFallback from './components/errorFallback/lang.he';
import chart from './components/chart/lang.he';
import favorites from './components/favorites/lang.he';
import messages from './components/messages/lang.he';
import processNotification from './components/processNotification/lang.he';

export default {
  global,
  validations,

  components: {
    navbar,
    filter,
    export: exportLang,
    columns,
    comments,
    history,
    files,
    usericon,
    agentAgreements,
    import: importLang,
    table,
    errorFallback,
    rows,
    chart,
    favorites,
    messages,
    processNotification,
  },

  pages: {
    main,
    login,
    forgot,
    error,
    agents,
    agentedit,
    agentview,
    agentcreate,
    users,
    userview,
    usercreate,
    useredit,
    reset,
    settings,
    reports,
    uploads,
  },
};
