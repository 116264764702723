import PropTypes from 'prop-types';
import { Title } from '@mantine/core';
import { useState } from 'react';
import styles from './style.module.css';

const MenuGroups = (props) => {
  const {
    data = [],
    onClick = () => {},
    defaultActive,
  } = props;

  const [activeLink, setActiveLink] = useState(defaultActive);

  const onLinkClick = (e) => {
    e.preventDefault();
    const { target } = e;
    const { id } = target.dataset;
    setActiveLink(id);
    onClick(id);
  };

  return (
    <>
      {data.map((item, index) => (
        <div className={styles.menugroup} key={index}>
          {item.title && <Title order={5}>{item.title}</Title>}
          {(item.links).map((link) => (
            <a
              key={link.id}
              href="#"
              className={styles.link}
              data-id={link.id}
              data-active={activeLink === link.id || undefined}
              onClick={onLinkClick}
            >
              {link.title}
            </a>
          ))}
        </div>
      ))}
    </>
  );
};

MenuGroups.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  defaultActive: PropTypes.string,
};

export default MenuGroups;
