import { useMutation } from '@tanstack/react-query';
import { findById } from '../requests/company';

const KEY = 'company';

const useFindById = () => useMutation({
  mutationKey: [KEY],
  mutationFn: (data) => findById(data),
});

const companyMutation = {
  useFindById,
};

export default companyMutation;
