const vars = {
  env: import.meta.env.MODE,
  console: (import.meta.env.VITE_APP_ENABLE_CONSOLE === 'true'),
  mocks: (import.meta.env.VITE_APP_ENABLE_MOCKS === 'true'),
  request: {
    baseURL: import.meta.env.VITE_APP_REQUEST_BASEURL,
    timeout: import.meta.env.VITE_APP_REQUEST_TIMEOUT,
  },
};

export default vars;
