export default {
  site: {
    name: 'אתר',
    path: 'https://example.com',
  },
  main: {
    name: 'דף ראשי',
    path: '/',
  },
  login: {
    name: 'התחברות',
    path: '/login',
  },
  reset: {
    name: 'איפוס סיסמא',
    path: '/password_reset/:uuid',
  },
  forgot: {
    name: 'שכחתי סיסמא',
    path: '/forgot',
  },
  agents: {
    name: 'סוכנים',
    path: '/agents',
  },
  agentEdit: {
    name: 'עריכת סוכן',
    path: '/agents/edit/:id',
  },
  agentCreate: {
    name: 'יצירת סוכן',
    path: '/agents/create',
  },
  agentView: {
    name: 'צפייה בסוכן',
    path: '/agents/view/:id',
  },
  settings: {
    name: 'הגדרות מערכת',
    path: '/settings',
  },
  reports: {
    name: 'דוחות',
    path: '/reports',
  },
  reportList: {
    name: 'דוח סוכן',
    path: '/reports/list/:reportId',
  },
  reportsView: {
    name: 'דוח סוכן',
    path: '/reports/view/:id/:query',
  },
  users: {
    name: 'משתמשים',
    path: '/users',
  },
  userEdit: {
    name: 'עריכת משתמש',
    path: '/users/edit/:id',
  },
  userCreate: {
    name: 'יצירת משתמש',
    path: '/users/create',
  },
  userView: {
    name: 'צפייה במשתמש',
    path: '/users/view/:id',
  },
  messages: {
    name: 'הודעות',
    path: '/messages',
  },
  uploads: {
    name: 'קבצים',
    path: '/uploads',
  },
};
