import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_GET_ALL = {
  default: {
    response: {
      createdAt: 1721227697689,
      object: 'getall',
      success: true,
      data: [
        {
          _id: '6617d5b437d01fce576453cb',
          name: 'Pension',
          hebrew: 'פנסיה',
        },
        {
          _id: '666fe3838b5154bf71df8533',
          name: 'Savings policy',
          hebrew: 'פוליסת חסכון',
        },
        {
          _id: '6617d5db37d01fce576453cc',
          name: 'Muzar',
          hebrew: 'מוצר',
        },
      ],
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720349117033,
      error: {
        collection: 'product',
        code: 500,
        error: '',
        message: 'אירעה שגיאה',
      },
      object: 'getall',
      success: false,
    },
    status: 500,
  },
};

const getAllProductsHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ALL);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  getAllProductsHandler,
};
