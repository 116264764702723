import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import prepareTranslate from '@/helpers/dictionary';
import styles from './style.module.css';

const ErrorFallback = (props) => {
  const {
    error = {},
    resetErrorBoundary,
  } = props;

  const {
    stack = '',
    message = '',
  } = error;

  const t = prepareTranslate();
  const [opened, { open, close }] = useDisclosure(false);

  const handleAgainClick = () => {
    resetErrorBoundary();
  };

  return (
    <>
      <Modal opened={opened} onClose={close} title={t('components.errorFallback.modal.title')}>
        <Group justify="flex-end">
          <Text ta="left" fw={700}>{message}</Text>
          <div className={styles.stack}>
            <pre>
              {stack}
            </pre>
          </div>
        </Group>
      </Modal>

      <Card withBorder radius="md" padding="xl" bg="var(--mantine-color-body)">
        <Stack align="center">
          <Text fz="md" fw={700} c="dimmed">
            {t('components.errorFallback.title')}
          </Text>
          <Group>
            <Button variant="light" onClick={handleAgainClick}>{t('components.errorFallback.try')}</Button>
            <Button variant="white" onClick={open}>{t('components.errorFallback.view')}</Button>
          </Group>
        </Stack>
      </Card>
    </>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.any,
};

export default ErrorFallback;
