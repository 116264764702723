import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip, UnstyledButton } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';

const NavbarLink = (props) => {
  const {
    icon: Icon,
    label,
    to,
    onClick,
  } = props;

  const navLinkClasses = ({ isActive }) => clsx(styles.link, isActive && styles.active);
  const handleButtonClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Tooltip label={label} position="left">
      {
        onClick
          ? <UnstyledButton
              className={styles.link}
              onClick={handleButtonClick}><Icon /></UnstyledButton>
          : <NavLink to={to} className={navLinkClasses}><Icon /></NavLink>
      }
    </Tooltip>
  );
};

NavbarLink.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavbarLink;
