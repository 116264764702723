export default {
  title: 'יצירת סוכן',
  more: 'צפה עוד',
  menu: {
    details: 'פרטים',
    contracts: 'הסכמים',
    documents: 'מסמכים',
    clients: 'מספרי תיק/יישות',
  },
  status: {
    active: 'פעיל',
    archive: 'ארכיון',
  },
  side: {
    actions: 'פעולות',
  },
  headers: {
    private: 'פרטים אישיים',
    contact: 'פרטי קשר',
    home: 'בית',
    office: 'משרד',
    status: 'סטטוס',
    action: 'פעולות',
    history: 'היסטוריה',
    comment: 'הערות',
    contracts: 'הסכמים',
    extra: 'מידע נוסף',
    family: 'משפחה',
    dates: 'תאריכים',
    documents: 'מסמכים',
    clients: 'ניהול מספרי תיק/יישות',
  },
  agentType: {
    CTO: 'מנכ"ל',
    houseAgent: 'סוכן בית',
    integratedAgent: 'סוכן משולב',
    foreignAgent: 'סוכן חוץ',
  },
  employeeType: {
    employee: 'שכיר',
    selfEmployee: 'עצמאי',
  },
  form: {
    firstname: 'שם פרטי',
    lastname: 'שם משפחה',
    fullName: 'שם מלא',
    agentType: 'תפקיד',
    uid: 'תעודת זהות',
    dob: 'תאריך לידה',
    email: 'דואר אלקטרוני',
    phone: 'טלפון',
    cellphone: 'נייד',
    extracellphone: 'נייד נוסף',
    website: 'אתר אנטרנט',
    gender: 'מין',
    male: 'זכר',
    female: 'נקבה',
    homeaddress: 'כתובת מלאה',
    city: 'עיר',
    employeeType: 'סוג סוכן',
    street: 'רחוב',
    houseNumber: 'מספר בית',
    zipCode: 'מיקוד',
    postalCode: 'ת.ד',
    homephone: 'טלפון',
    officeaddress: 'כתובת מלאה',
    officephone: 'טלפון',
    status: 'סטטוס',
    familystatus: 'מצב משפחתי',
    spouse: 'שם בן / בת זוג',
    numberOfChildren: 'מספר ילדים',
    haveChildrens: 'יש ילדים?',
    addChildren: 'הוספת ילד',
    policyend: 'תאריך תום פוליסה אחריות מקצועית',
    licenseend: 'תאריך תום רשיון',
    tax: 'תאריך ניכוי מס וניהול ספרים',
    professionalPolicy: 'תאריך פוליסה מקצועית',
    agreement: {
      from: 'מתאריך',
      till: 'עד תאריך',
      number: 'מספר הסכם',
      agent: 'מספר סוכן',
      company: 'יצרן',
      branch: 'ענף',
      branches: 'ענפים',
      addAgreement: 'הוספת הסכם',
      commision: {
        agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה',
        agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה',
        agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה',
        agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה',
        agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה',
        agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה',
        agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה',
        agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה',
      },
    },
    entityNumbers: {
      entityNumbers: 'מספרי תיק/יישות',
      entityNumber: 'מספר תיק/יישות',
      clientName: 'שם תיק/יישות',
      addNewEntityNumber: 'הוספת מספר תיק/יישות',
      hp: 'ח.פ',
    },
  },
  notification: {
    success: {
      title: 'הסוכן נוצר בהצלחה',
      message: 'פרטי הסוכן נוצרו במערכת',
    },
    failure: {
      title: 'סוכן לא נוצר',
      message: 'אירעה שגיאה בניסיון היצירה',
    },
    validtion: {
      title: 'סוכן לא נוצר',
      message: 'אחד מהפרטים שהוזנו איזו תקין',
    },
  },
};
