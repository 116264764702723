import { http } from 'msw';
import vars from '@/config/vars';
import {
  loginHandler,
  logoutHandler,
  forgotPasswordHandler,
  resetPasswordHandler,
} from './auth';
import {
  getOneHandler,
  createUserHandler,
  updateUserHandler,
  getUsersByQueryHandler,
} from './user';
import {
  getAllHandler,
} from './company';
import {
  getAllProductsHandler,
} from './product';
import {
  findByCompanyIdHandler,
} from './importRule';
import {
  importHandler,
  exportTableHandler,
  exportChartHandler,
} from './docs';
import {
  processHandler,
} from './process';
import {
  createAgentHandler,
  getAllAgentsHandler,
  editAgentHandler,
  getAgentHandler,
  updateAgentHandler,
} from './agent';
import { smsHandler } from './message';

const handlers = [
  http.post(`${vars.request.baseURL}/v1/auth/login`, loginHandler),
  http.post(`${vars.request.baseURL}/v1/auth/logout`, logoutHandler),
  http.post(`${vars.request.baseURL}/v1/user/create`, createUserHandler),
  http.post(`${vars.request.baseURL}/v1/user/get`, getUsersByQueryHandler),
  http.post(`${vars.request.baseURL}/v1/user/getone`, getOneHandler),
  http.post(`${vars.request.baseURL}/v1/user/update`, updateUserHandler),
  http.post(`${vars.request.baseURL}/v1/auth/forgot`, forgotPasswordHandler),
  http.post(`${vars.request.baseURL}/v1/company/getAll`, getAllHandler),
  http.post(`${vars.request.baseURL}/v1/product/getAll`, getAllProductsHandler),
  http.post(`${vars.request.baseURL}/v1/importRule/findByCompanyId`, findByCompanyIdHandler),
  http.post(`${vars.request.baseURL}/v1/docs/import`, importHandler),
  http.post(`${vars.request.baseURL}/v1/docs/export/table`, exportTableHandler),
  http.post(`${vars.request.baseURL}/v1/docs/export/chart`, exportChartHandler),
  http.get(`${vars.request.baseURL}/v1/event/process`, processHandler),
  http.post(`${vars.request.baseURL}/v1/agent/create`, createAgentHandler),
  http.post(`${vars.request.baseURL}/v1/agent/agents`, getAllAgentsHandler),
  http.post(`${vars.request.baseURL}/v1/agent/edit`, editAgentHandler),
  http.post(`${vars.request.baseURL}/v1/agent/agent`, getAgentHandler),
  http.post(`${vars.request.baseURL}/v1/agent/update`, updateAgentHandler),
  http.post(
    `${vars.request.baseURL}/v1/auth/reset_password`,
    resetPasswordHandler,
  ),
  http.post(`${vars.request.baseURL}/v1/message/create)`, smsHandler),
];

export default handlers;
