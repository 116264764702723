const generateMockChartData = () => {
  const data = [
    { date: 'Mar 22', Apples: 100 },
    { date: 'Mar 23', Apples: 60 },
    { date: 'Mar 24', Apples: 80 },
    { date: 'Mar 25', Apples: 65 },
    { date: 'Mar 26', Apples: 20 },
    { date: 'Mar 27', Apples: 40 },
    { date: 'Mar 28', Apples: 110 },
    { date: 'Mar 29', Apples: 80 },
  ];

  const shuffledArray = [...data]; // Create a copy of the original array
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1)); // Get random index
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
  }
  return shuffledArray;
};

export default generateMockChartData;
