import PropTypes from 'prop-types';
import { Paper } from '@mantine/core';
import styles from './style.module.css';

const Box = (props) => {
  const {
    title,
    subtitle,
    children,
  } = props;

  return (
    <Paper p="lg" radius="md" withBorder>
      <div className={styles.box}>
        <header>
          {title && <div className={styles.title}>{title}</div>}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </header>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </Paper>
  );
};

Box.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
};

export default Box;
