import { HttpResponse, delay } from 'msw';

const encoder = new TextEncoder();

const IMPORT = new ReadableStream({
  start(controller) {
    controller.enqueue(
      encoder.encode('event:open\ndata:connected successfuly\n\n'),
    );
  },
});

const processHandler = async () => {
  const response = IMPORT;
  await delay(1000);

  return new HttpResponse(
    response,
    {
      headers: {
        'Content-Type': 'text/event-stream',
      },
    },
  );
};

export {
  processHandler,
};
