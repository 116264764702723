import PropTypes from 'prop-types';
import { useDisclosure } from '@mantine/hooks';
import { Drawer } from '@mantine/core';
import logo from '@/assets/images/logo.png';
import { IconTablePlus } from '@tabler/icons-react';
import { useIsMutating } from '@tanstack/react-query';
import UserIcon from '../UserIcon';
import NavbarLink from '../NavbarLink';
import styles from './style.module.css';
import Import from '../Import';

const Navbar = (props) => {
  const {
    links = [],
    footer = [],
  } = props;

  // TODO: get user id
  const userId = 'user id of user';

  const isImportMutating = useIsMutating({ mutationKey: ['docs', 'import'] });
  const [opened, { open, close }] = useDisclosure(false);

  const handleImportClick = () => {
    open();
  };

  const navbarLinks = links.map((link) => <NavbarLink {...link} key={link.label} />);
  const navbarFooterLinks = footer.map((link) => <NavbarLink {...link} key={link.label} />);

  return (
    <>
      <Drawer opened={opened} onClose={close} closeOnClickOutside={!isImportMutating} withCloseButton={!isImportMutating} closeOnEscape={!isImportMutating} title="טעינת נתונים">
        <Import />
      </Drawer>
      <nav className={styles.navbar}>
        <div className={styles.logo}>
          <img src={logo} aria-label="logo" />
        </div>
        <div className={styles.inner}>
          <NavbarLink icon={IconTablePlus} label="טעינת נתונים" onClick={handleImportClick} />
          {navbarLinks}
        </div>

        <div className={styles.footer}>
          {navbarFooterLinks}
          <UserIcon userId={userId} variant="filled" />
        </div>
      </nav>
    </>
  );
};

Navbar.propTypes = {
  links: PropTypes.array,
  footer: PropTypes.array,
};

export default Navbar;
