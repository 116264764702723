import { useState } from 'react';
import prepareTranslate from '@/helpers/dictionary';
import {
  Container,
  Title,
} from '@mantine/core';
import logo from '@/assets/images/logo.png';
import Sent from './forgot.sent';
import NotSent from './forgot.notsent';
import styles from './style.module.css';

const Reset = () => {
  const t = prepareTranslate();
  const [sent, setSent] = useState(false);

  return (
    <Container size={500} py={40}>
      <div className={styles.logo}>
        <img src={logo} aria-label="logo" />
      </div>
      <Title ta="center">{t('pages.forgot.title')}</Title>
      {sent ? <Sent /> : <NotSent onSent={setSent} />}
    </Container>
  );
};

export default Reset;
