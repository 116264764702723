import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const IMPORT = {
  default: {
    response: {
      createdAt: 1720350581250,
      object: 'import',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720353251932,
      error: {
        code: 501,
        error: 'No documnets were found',
        message: 'אירעה שגיאה',
      },
      object: 'import',
      success: false,
      data: 'import_rules',
    },
    status: 500,
  },
};

const EXPORTTABLE = {
  default: {
    response: {
      createdAt: 1720354032895,
      object: 'export',
      success: true,
      data: {
        count: 1,
        data: {
          columns: [
            {
              isVisible: false,
              accessor: 'גודל תיק.value',
              header: 'portfolioSize',
              filter: {
                type: 'string',
              },
            },
            {
              isVisible: false,
              accessor: 'AgencyFee.value',
              header: 'עמלת סוכנות',
              filter: {
                type: 'string',
              },
            },
            {
              isVisible: false,
              accessor: 'undefined.value',
              header: 'פרמיה',
              filter: {
                type: 'string',
              },
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'uid',
              header: 'מספר משתמש',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: true,
              accessor: 'firstName',
              header: 'שם פרטי',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: true,
              accessor: 'lastName',
              header: 'שם משפחה',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'dob',
              header: 'תאריך לידה',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'gender',
              header: 'מין',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'phone',
              header: 'נייד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'email',
              header: 'דואר אלקטרוני',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'homeCity',
              header: 'עיר',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'homeStreet',
              header: 'רחוב',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'homeHouseNumber',
              header: 'מספר בית',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'homeZipCode',
              header: 'מיקוד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'homePhone',
              header: 'טלפון בית',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'officeCity',
              header: 'עיר משרד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'officeStreet',
              header: 'רחוב משרד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'officeHouseNumber',
              header: 'מספר בית משרד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'officeZipCode',
              header: 'מיקוד משרד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'officePhone',
              header: 'טלפון משרד',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'familyStatus',
              header: 'מצב אישי',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'policyEndDate',
              header: 'סוף תוקף פוליסה',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'licenseEndDate',
              header: 'סוף תוקף רשיון',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: false,
              accessor: 'taxDeductionsDate',
              header: 'תאריך ניקוי מס',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: true,
              accessor: 'product',
              header: 'מוצר',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: true,
              accessor: 'company',
              header: 'חברה',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: true,
              accessor: 'agentNumber',
              header: 'מספר סוכן',
            },
            {
              filter: {
                type: 'string',
              },
              isVisible: true,
              accessor: 'period',
              header: 'תקופה',
            },
          ],
          rows: [
            {
              id: '66828b7b2537f3839fa7dd0b',
              'גודל תיק': {
                value: 1049352.46,
                components: [
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 18,
                    columnSign: 'K',
                    value: 32444.91,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 19,
                    columnSign: 'K',
                    value: 406310.84,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 20,
                    columnSign: 'K',
                    value: 406310.84,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 21,
                    columnSign: 'K',
                    value: 204285.87,
                  },
                ],
              },
              agentNumber: '2-2535',
              period: '7/2024',
              product: 'פנסיה',
              company: 'מיטב',
              uid: '00018',
              firstName: 'אורן',
              lastName: 'לא למחוק',
              dob: '2024-06-01T21:00:00.000Z',
              gender: 'female',
              phone: '0546303000',
              email: 'ewtgf@ggggg.com',
              homeCity: 'רמת גן',
              homeStreet: 'בלה',
              homeHouseNumber: '5',
              homeZipCode: '5454545',
              homePhone: '0546303000',
              officeCity: 'יפו',
              officeStreet: 'יפת',
              officeHouseNumber: '4',
              officeZipCode: '4545454',
              officePhone: '0566606006',
              familyStatus: 'single',
              policyEndDate: '2024-06-10T21:00:00.000Z',
              licenseEndDate: '2024-06-08T21:00:00.000Z',
              taxDeductionsDate: '2024-06-18T21:00:00.000Z',
              month: 7,
              year: 2024,
              AgencyFee: {
                value: 45.96,
                components: [
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 18,
                    columnSign: 'T',
                    value: 0.82,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 19,
                    columnSign: 'T',
                    value: 10.28,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 20,
                    columnSign: 'T',
                    value: 29.68,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 21,
                    columnSign: 'T',
                    value: 5.18,
                  },
                ],
              },
              premia: {
                value: 3957,
                components: [
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 18,
                    columnSign: 'Z',
                    value: 0,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 19,
                    columnSign: 'Z',
                    value: 0,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 20,
                    columnSign: 'Z',
                    value: 3957,
                  },
                  {
                    fileTitle: 'מיטב מקור',
                    sheetTitle: 'עמלות מפורט',
                    row: 21,
                    columnSign: 'Z',
                    value: 0,
                  },
                ],
              },
            },
          ],
        },
      },
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720355007078,
      error: {
        code: 501,
        error: 'No documnets were found',
        message: 'אירעה שגיאה',
      },
      object: 'export',
      success: false,
      data: 'export_rules',
    },
    status: 500,
  },
};

const EXPORTCHART = {
  default: {
    response: {
      createdAt: 1721058257598,
      object: 'exportchart',
      success: true,
      data: {
        data: [
          {
            date: '7/2024',
            '(1644) מיטב פנסיה': {
              value: 2548167.25,
              components: [
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 3,
                  columnSign: 'K',
                  value: 54367.08,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 4,
                  columnSign: 'K',
                  value: 923456.01,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 5,
                  columnSign: 'K',
                  value: 1234543.01,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 6,
                  columnSign: 'K',
                  value: 3455.23,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 7,
                  columnSign: 'K',
                  value: 332345.92,
                },
              ],
              percentage: 0,
            },
          },
          {
            date: '8/2024',
            '(1644) מיטב פנסיה': {
              value: 11269551,
              components: [
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 3,
                  columnSign: 'K',
                  value: 4325,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 4,
                  columnSign: 'K',
                  value: 4156,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 5,
                  columnSign: 'K',
                  value: 5623421,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 6,
                  columnSign: 'K',
                  value: 14325,
                },
                {
                  fileTitle: 'מיטב מקור',
                  sheetTitle: 'עמלות מפורט',
                  row: 7,
                  columnSign: 'K',
                  value: 5623324,
                },
              ],
              percentage: 342.2610407538987,
            },
          },
        ],
        series: [
          {
            name: '(1644) מיטב פנסיה',
          },
        ],
      },
    },
  },

  error: {
    status: 500,
    message: 'ExportCharr is not defined',
    error: {
      code: 1,
      error: 'Unknown error',
      message: 'שגיאה לא ידועה',
    },
  },
};

const importHandler = async () => {
  const { response, status } = getResponseData(IMPORT);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const exportTableHandler = async () => {
  const { response, status } = getResponseData(EXPORTTABLE);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const exportChartHandler = async () => {
  const { response, status } = getResponseData(EXPORTCHART);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  importHandler,
  exportTableHandler,
  exportChartHandler,
};
