import prepareTranslate from '@/helpers/dictionary';
import { useDisclosure } from '@mantine/hooks';
import PropTypes from 'prop-types';
import {
  List,
  Modal,
  ScrollArea,
  Text,
  UnstyledButton,
} from '@mantine/core';

function ProcessNotification(props) {
  const { identifiers, ruleFile } = props;
  const t = prepareTranslate();
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        scrollAreaComponent={ScrollArea.Autosize}
        title={t('components.processNotification.popUp.message')}
        size='lg'
      >
        <List>
          {
            identifiers.map((currentIdentifier, index) => {
              const {
                company,
                product,
                identifierNumber,
                year,
                month,
              } = currentIdentifier;

              return (
                <>
                  <List.Item key={`identifierDetails ${index}`} size='sm' mb='xs'>
                    <Text size='sm'>
                      {
                        t('components.processNotification.popUp.info', [identifierNumber, company, product, year, month])
                      }
                    </Text>
                  </List.Item>
                </>
              );
            })
          }

        </List>
      </Modal>
      <Text size='sm'>
        {
          t('components.processNotification.notification.message', [ruleFile])
        }
      </Text>
      <UnstyledButton onClick={open}>
        <Text td="underline" c='blue' size='sm'>
          {
            t('components.processNotification.notification.click')
          }
        </Text>
      </UnstyledButton>
    </>
  );
}

ProcessNotification.propTypes = {
  identifiers: PropTypes.array,
  ruleFile: PropTypes.string,
};

export default ProcessNotification;
