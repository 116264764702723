import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import LocationProvider from '@/components/LocationProvider';
import router from '@/routes/AppRoutes';
import { DirectionProvider, MantineProvider } from '@mantine/core';
import { theme, dates, resolver } from '@/config/mantine';
import { DatesProvider } from '@mantine/dates';
import 'dayjs/locale/he';
import '@/assets/styles/global.css';
import '@/assets/styles/fonts.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/charts/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './config/query';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left'/>
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <DirectionProvider>
        <DatesProvider settings={dates}>
          <LocationProvider>
            <RouterProvider router={router} />
          </LocationProvider>
        </DatesProvider>
      </DirectionProvider>
    </MantineProvider>
  </QueryClientProvider>

);

export default App;
