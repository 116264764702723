import PropTypes from 'prop-types';
import { Button, TextInput } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import prepareTranslate from '@/helpers/dictionary';
import { useDisclosure } from '@mantine/hooks';
import userQuery from '@/api/queries/user';
import commentMutations from '@/api/mutitations/comment';
import { notifications } from '@mantine/notifications';
import DOMPurify from 'dompurify';
import UserIcon from '../UserIcon';
import styles from './style.module.css';
import Comment from '../Comment';
import restrictTags from '../../config/restrictTags';

// TODO: GET FROM USER CONTEXT
const writerId = '66794f14e749f5dc8f75549d';
const writerName = 'לילך';
const writerLastName = 'קסטוריאני';

const sanitizeHtml = (html) => DOMPurify.sanitize(html, {
  ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'u'],
  ALLOWED_ATTR: ['href', 'target'],
  FORBID_TAGS: ['script', 'iframe'],
  FORBID_ATTR: ['onerror', 'onload', 'onclick'],
});

const Comments = (props) => {
  const {
    type,
    id,
    showForm = false,
  } = props;

  const t = prepareTranslate();
  const [opened, { open, close }] = useDisclosure(false);
  const { data, refetch } = userQuery.useGetCommentsById({ id });
  const { useCreateComment } = commentMutations;
  const createCommentMutation = useCreateComment();

  const editor = useEditor({
    extensions: [StarterKit, Link, Underline, restrictTags],
  });

  const handleCommentCreateClick = async () => {
    if (!editor) return;

    const commentHtmlContent = editor.getHTML();

    if (commentHtmlContent.trim() === '') {
      notifications.show({
        title: t('pages.comment.notification.errortitle'),
        message: t('pages.comment.notification.emptycomment'),
      });
      return;
    }

    try {
      await createCommentMutation.mutateAsync({
        entity: type,
        entityId: id,
        authorId: writerId,
        authorFirstName: writerName,
        authorLastName: writerLastName,
        body: commentHtmlContent,
      });

      editor.commands.setContent('');
      notifications.show({
        title: t('components.comments.notification.title'),
        message: t('components.comments.notification.message'),
      });
      refetch();
      close();
    } catch (error) {
      notifications.show({
        title: t('components.comments.notification.errortitle'),
        message: t('components.comments.notification.error'),
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      {showForm && (
        <form>
          <div className={styles.form}>
            <UserIcon userId={id} isMenuActive={false} />
            <div className={styles.content}>
              {!opened && (
                <TextInput
                  placeholder={t('components.comments.addcomment')}
                  onClick={open}
                />
              )}
              {opened && (
                <>
                  <RichTextEditor editor={editor}>
                    <RichTextEditor.Toolbar>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                      </RichTextEditor.ControlsGroup>

                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                      </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content />
                  </RichTextEditor>
                  <div className={styles.actions}>
                    <Button onClick={handleCommentCreateClick} loading={createCommentMutation.isLoading}>{t('global.save')}</Button>
                    <Button variant='transparent' onClick={close}>
                      {t('global.cancel')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      )}

      <div className={styles.comments}>
        {data && data.map((comment) => (
          <Comment
            key={comment.id}
            firstName={comment.authorFirstName}
            lastName={comment.authorLastName}
            createdAt={new Date(comment.createdAt)}
          >
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment.body) }} />
          </Comment>
        ))}
      </div>
    </div>
  );
};

Comments.propTypes = {
  type: PropTypes.oneOf(['agent', 'agency', 'user']).isRequired,
  id: PropTypes.string.isRequired,
  showForm: PropTypes.bool,
};

export default Comments;
