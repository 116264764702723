import PropTypes from 'prop-types';
import { Tabs, Title } from '@mantine/core';

const SectionExtra = (props) => {
  const {
    title,
    tabs = [],
  } = props;

  if (!tabs.length) {
    return null;
  }

  const defaultTabObject = tabs.find((tab) => tab.isDefault);
  const defaultValue = defaultTabObject ? defaultTabObject.title : tabs[0].title;

  const tabsList = tabs.map((tab) => {
    const {
      title: panelTitle,
    } = tab;

    return (
      <Tabs.Tab key={panelTitle} value={panelTitle}>
        {panelTitle}
      </Tabs.Tab>
    );
  });

  const tabsPanels = tabs.map((tab) => {
    const {
      title: panelTitle,
      content,
    } = tab;

    return (
      <Tabs.Panel key={panelTitle} value={panelTitle} mt="lg">
        {content}
      </Tabs.Panel>
    );
  });

  return (
    <section>
      <header>
        <Title order={4}>{title}</Title>
      </header>

      <Tabs defaultValue={defaultValue}>
        <Tabs.List>
          {tabsList}
        </Tabs.List>

        {tabsPanels}
      </Tabs>
    </section>
  );
};

SectionExtra.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.array,
};

export default SectionExtra;
