import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_CREATE_USER = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'createUser',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'createUser',
      success: false,
    },
    status: 500,
  },
};

const RESULT_GET_USERS = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'getUsersByQuery',
      success: true,
      data: {
        count: 3,
        data: [
          {
            id: '111',
            fullname: 'אורן קסלר',
            phone: '0545653650',
            email: 'orenk@fintek.co.il',
            userName: 'orenk',
            role: 'administrator',
            firstName: 'אורן',
            lastName: 'קסלר',
            isBlocked: false,
            isArchived: false,
            loginAttempts: 3,
            updatedAt: '2024-07-03T08:48:39.893Z',
            lastLoginAttempt: '2024-05-04T09:12:39.893Z',
            createdAt: '2024-07-03T08:48:39.893Z',
          },
          {
            id: '222',
            email: 'yariva@fintek.co.il',
            userName: 'yariva',
            role: 'superadmin',
            firstName: 'יריב',
            lastName: 'ארביב',
            phone: '0545653650',
            isBlocked: false,
            isArchived: false,
            loginAttempts: 3,
            updatedAt: '2024-07-03T08:48:39.893Z',
            lastLoginAttempt: '2023-12-24T12:07:12.893Z',
            createdAt: '2024-07-03T08:48:39.893Z',
          },
          {
            id: '333',
            email: 'sapir@fintek.co.il',
            userName: 'sapir',
            role: 'administrator',
            firstName: 'ספיר',
            lastName: 'קלצקו',
            phone: '0545653650',
            isBlocked: false,
            isArchived: false,
            loginAttempts: 3,
            updatedAt: '2024-07-03T08:48:39.893Z',
            lastLoginAttempt: '2024-07-03T08:48:39.893Z',
            createdAt: '2024-07-03T08:48:39.893Z',
          },
          {
            id: '444',
            email: 'yarden@fintek.co.il',
            userName: 'yarden',
            role: 'administrator',
            firstName: 'ירדן',
            lastName: 'יוספזון',
            phone: '0545653650',
            isBlocked: true,
            isArchived: false,
            loginAttempts: 3,
            updatedAt: '2024-07-03T08:48:39.893Z',
            lastLoginAttempt: '2024-07-03T08:48:39.893Z',
            createdAt: '2024-07-03T08:48:39.893Z',
          },
          {
            id: '555',
            email: 'yair@fintek.co.il',
            userName: 'yair',
            role: 'administrator',
            firstName: 'יאיר',
            lastName: 'צבר',
            phone: '0545653650',
            isBlocked: false,
            isArchived: true,
            loginAttempts: 3,
            updatedAt: '2024-07-03T08:48:39.893Z',
            lastLoginAttempt: '2024-07-03T08:48:39.893Z',
            createdAt: '2024-07-03T08:48:39.893Z',
          },
        ],
      },
    },
    status: 200,
  },
  error: {
    response: {
      createdAt: 1701115461239,
      object: 'getUsersByQuery',
      success: false,
    },
    status: 500,
  },
};

const RESULT_USER_VIEW = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'getOne',
      success: true,
      data: {
        user: {
          id: '111',
          fullname: 'אורן קסלר',
          phone: '0545653650',
          email: 'orenk@fintek.co.il',
          userName: 'orenk',
          role: 'administrator',
          firstName: 'אורן',
          lastName: 'קסלר',
          isBlocked: false,
          isArchived: false,
          loginAttempts: 3,
          updatedAt: '2024-07-03T08:48:39.893Z',
          lastLoginAttempt: '2024-07-03T08:48:39.893Z',
          createdAt: '2024-07-03T08:48:39.893Z',
          passwordLastChanged: '2024-07-03T08:48:39.893Z',
        },
      },
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'getOne',
      success: false,
    },
    status: 500,
  },
};

const RESULT_UPDATE_USER = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'updateUser',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'updateUser',
      success: false,
    },
    status: 500,
  },
};

const createUserHandler = async () => {
  const { response, status } = getResponseData(RESULT_CREATE_USER);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getUsersByQueryHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_USERS);
  await delay(1000);
  // this response needs to return all users - to create this mock - ask yariv which details we need
  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getOneHandler = async () => {
  const { response, status } = getResponseData(RESULT_USER_VIEW);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const updateUserHandler = async () => {
  const { response, status } = getResponseData(RESULT_UPDATE_USER);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export {
  createUserHandler,
  getUsersByQueryHandler,
  getOneHandler,
  updateUserHandler,
};
