import axiosRequest from '.';

const getUsersByQuery = async ({ start, end, query }) => (
  axiosRequest({
    url: '/v1/user/get',
    method: 'POST',
    data: { start, end, query },
  })
);

const getOne = async (data = {}) => (
  axiosRequest({
    url: '/v1/user/getone',
    method: 'POST',
    data,
  })
);

const getMessagesById = async (data = {}) => (
  axiosRequest({
    url: '/v1/message/getlatest',
    method: 'POST',
    data,
  })
);

const createUser = async (data = {}) => (
  axiosRequest({
    url: '/v1/user/create',
    method: 'POST',
    data,
  })
);

const updateUser = async (data = {}) => (
  axiosRequest({
    url: '/v1/user/update',
    method: 'POST',
    data,
  })
);

const archiveUser = async (data = {}) => (
  axiosRequest({
    url: 'v1/user/archive',
    method: 'POST',
    data,
  })
);

const createComment = async (data = {}) => (
  axiosRequest({
    url: '/v1/comment/create',
    method: 'POST',
    data,
  })
);

const getCommentsById = async (data = {}) => (
  axiosRequest({
    url: '/v1/comment/getlatest',
    method: 'POST',
    data,
  })
);

const createMessage = async (data = {}) => (
  axiosRequest({
    url: '/v1/message/create',
    method: 'POST',
    data,
  })
);
export {
  getUsersByQuery,
  getOne,
  getMessagesById,
  getCommentsById,
  createUser,
  updateUser,
  archiveUser,
  createComment,
  createMessage,
};
