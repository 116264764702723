export default {
  title: 'שלום {1}',
  subtitle: 'זה מה שהכנו עבורך היום',
  link: {
    example: {
      title: 'עמוד דוגמא',
    },
    error: {
      title: 'עמוד שגיאה',
    },
  },
  button: {
    action: {
      start: 'לחץ כאן',
      text: 'מספר לחיצות {1}',
    },
  },
};
