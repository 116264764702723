import { QueryClient } from '@tanstack/react-query';

const staleTime = 1000 * 60 * 5; // 1000 * 60 * 60 * 24; // 24 hours in miliseconds
const cacheTime = 1000 * 60 * 5;

const options = {
  defaultOptions: {
    queries: {
      staleTime,
      cacheTime,
      retry: (failureCount, { status }) => {
        if (status === 401) {
          window.location.href = '/login';
          return false;
        }
        return failureCount < 3;
      },
    },
  },
};

const queryClient = new QueryClient(options);

export default queryClient;
